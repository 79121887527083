const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload) return state.propertyDetails || {};
    if ( action.payload.masterPage !== 'clients' ) return state.propertyDetails;

    if ( action.payload.classification !== 'propertyDetails') return state.propertyDetails || {};

    let s = {...state};

    if ( action.type === 'SET_CLIENT_PROPERTY_DETAILS' ) {
        s.propertyDetails = {...s.propertyDetails, ...action.payload.data};
    }

    return s.propertyDetails;
};
