const F = {

    reducePath: (object, path, fallback) => {

        if ( typeof fallback === 'undefined' ) {
            fallback = null;
        }

        if (path.indexOf('.') > -1) {
            let parts = path.split('.');
            let field = parts[0];
            parts.shift();
            parts = parts.join('.');
            return typeof object !== 'undefined' && object !== null ? F.reducePath(object[field], parts, fallback) : fallback;
        } else {
            return typeof object !== 'undefined' && object !== null ? object[path] || fallback : fallback;
        }

    },

    ucFirst: (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
};

export default F;