import Calculator from '../../../js/math/Calculator.js';
import axios from 'axios';

require('../../../js/utils/Messenger');

const initialState = {};

export default function (state = initialState, action) {

	if ( !action.payload ) return Calculator.init(state);
	if ( action.payload.masterPage !== 'clients' ) return state;

	if ( action.payload.classification !== 'uploadClientFiles') return state;

	let s = {...state};

	if ( action.type === 'UPLOAD_CLIENT_FILES' ) {

		let file = action.payload.data.files[0];
		let documentInfo = action.payload.data.documentInfo;
		const formData = new FormData();
		formData.append('file', file);
		formData.append('payload', JSON.stringify(documentInfo));

		let userId = localStorage.getItem('userId');
		let accessToken = localStorage.getItem('accessToken');
		let timezoneOffset = new Date().getTimezoneOffset();
		let headers = {userId, accessToken, timezoneOffset};

		axios.post(window.endpoint('demo', "/upload-document"), formData, {headers})
			.then(result => {
				if(typeof result.data !== 'undefined') {
					let documentId = result.data._id;
					let newFile = {
						name:  file.name,
						displayName: file.name,
						documentId: documentId,
						link: result.data.link
					};

					let category = action.payload.category;

					if (action.payload.type === 'list') {
						let section = action.payload.section;
						s[section][category].push(newFile);
					} else if (action.payload.type === 'insert') {
						let section = action.payload.section;
						let field = action.payload.field;
						let key = action.payload.key;
						let args = action.payload.args;
						s[section][category][key][field].push(newFile);
					}

					window.Messenger.broadcast('AsyncFileUploadResponse', {documentId: documentId});

				}
			});
	}

	return s.files || {};
};
