/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import store, { persistor } from "./app/store/store";
import App from './App';
// import { mockAxios, setupAxios } from "./_metronic";
import { setupAxios } from "./_metronic";
import "./index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
// IE 11 polyfills
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// Fonts
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = '/';

window.friendlyCase = function(string) {
    let result = string.replace( /([A-Z])/g, " $1" );
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    let knownWords = {
    }
    for ( let w in knownWords ) {
        if ( finalResult === w ) {
            return knownWords[w];
        }
    }
    return finalResult;
};

String.prototype.toFriendlyCase = function() {
    return window.friendlyCase(this);
};

Array.prototype.includesAll = function(values) {
    if ( values.length < 1 ) {
        return false;
    }
    if ( this.includes(values[0]) ) {
        if ( values.length === 1 ) return true;
        return this.includesAll(values.slice(1));
    } else {
        return false;
    }
};

Array.prototype.cherryPick = function(field) {
    let values = [];
    for ( let a = 0; a < this.length; a++ ) {
        values.push(this[a][field]);
    }
    return values;
};

String.prototype.matchSearch = function(keywords) {
    if ( keywords.length < 1 ) return true;
    let words = keywords.split(' ');
    for ( let i = 0; i < words.length; i++ ) {
        if ( this.toLowerCase().indexOf(words[i].toLowerCase()) !== -1 ) {
            return true;
        }
    }
    return false;
};

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
// /* const mock = */ mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios, store);

ReactDOM.render(
    <App
      store={store}
      persistor={persistor}
      basename={PUBLIC_URL}
    />,
  document.getElementById("root")
);
