/*

Messenger sends and receive messages via event listening. Use this to communicate between components in any JavaScript application, such as a React app.

Example:

1. Broadcast the message:

window.Messenger.broadcast('MessageToken', {value: 10});

2. Set up a handler
this.messageHandler (eventClass, token, data) => {
  console.log([eventClass, token, data]);
}

3. Listen for the message in class constructor r while mounting:
window.Messenger.receive("MessageToken", this.messageHandler, this);
// Will log: {value: 10}

*/

var isDebugMode = isDebugMode || false;
var Messenger;
(Messenger = function() {
}).prototype = {
    receive: function(type, method, scope, context) {
        if ( isDebugMode ) console.log("New receiver mounted", { type: type, method: method, scope: scope, context: context});
        var listeners, handlers;
        if (!(listeners = this.listeners)) {
            listeners = this.listeners = {};
        }
        if (!(handlers = listeners[type])){
            handlers = listeners[type] = [];
        }
        scope = (scope ? scope : window);
        handlers.push({
            method: method,
            scope: scope,
            context: (context ? context : scope)
        });
    },
    broadcast: function(type, data, context) {
        if ( isDebugMode ) console.log("New message broadcast", { type: type, data: data, context: context});
        var listeners, handlers, i, n, handler, scope;
        if (!(listeners = this.listeners)) {
            return;
        }
        if (!(handlers = listeners[type])){
            return;
        }
        for (i = 0, n = handlers.length; i < n; i++){
            handler = handlers[i];
            if (typeof(context)!=="undefined" && context !== handler.context) continue;
            if (handler.method.call(
                handler.scope, this, type, data
            )===false) {
                return false;
            }
        }
        return true;
    }
};

window.Messenger = new Messenger();