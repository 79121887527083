import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";

import general from './reducers/origination/general';
import financials from './reducers/origination/financials';
import monthlySplit from './reducers/origination/monthlySplit';
import propertyDetails from './reducers/origination/propertyDetails';
import qualifyingAmount from './reducers/origination/qualifyingAmount';
import splitDetails from './reducers/origination/splitDetails';
import surplus from './reducers/origination/surplus';
import totalCost from './reducers/origination/totalCost';
import totalQualifyingAmount from './reducers/origination/totalQualifyingAmount';
import preapprovalLetter from './reducers/origination/preapprovalLetter';
import uploadFiles from './reducers/origination/uploadFiles';
import pageView from './reducers/system/pageView';
import globalState from './reducers/system/globalState';

import personal from './reducers/clients/personal';
import nextOfKin from './reducers/clients/nextOfKin';
import address from './reducers/clients/address';
import marital from './reducers/clients/marital';
import clientFinancials from './reducers/clients/financials';
import clientPropertyDetails from './reducers/clients/propertyDetails';
import employment from './reducers/clients/employment';
import insurance from './reducers/clients/insurance';
import uploadClientFiles from './reducers/clients/uploadClientFiles';
// import documents from './reducers/clients/documents';
import consentForm from './reducers/clients/consentForm';

const globalStateReducer = (state = {}, action) => {
  return {
    globalState: globalState(state, action),
  };
};

const originationReducer = (state = {}, action) => {
  return {
    general: general(state, action),
    financials: financials(state, action),
    surplus: surplus(state, action),
    qualifyingAmount: qualifyingAmount(state, action),
    totalQualifyingAmount: totalQualifyingAmount(state, action),
    propertyDetails: propertyDetails(state, action),
    totalCost: totalCost(state, action),
    monthlySplit: monthlySplit(state, action),
    splitDetails: splitDetails(state, action),
    preapprovalLetter: preapprovalLetter(state, action),
    pageView: pageView(state, action),
    uploadFiles: uploadFiles(state, action),
  };
};

const clientsReducer = (state = {}, action) => {
  return {
    personal: personal(state, action) || {},
    nextOfKin: nextOfKin(state, action) || {},
    pageView: pageView(state, action) || {},
    address: address(state, action) || {},
    marital: marital(state, action) || {},
    propertyDetails: clientPropertyDetails(state, action) || {},
    financials: clientFinancials(state, action) || {},
    insurance: insurance(state, action) || {},
    employment: employment(state, action) || {},
    uploadClientFiles: uploadClientFiles(state, action),
    consentForm: consentForm(state, action) || {},
  };
};

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  origination: originationReducer,
  clients: clientsReducer,
  globalState: globalStateReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
