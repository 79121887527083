import A from '../../../js/math/Arithmetic';

const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload) return state.personal || {};
    if ( action.payload.masterPage !== 'clients' ) return state.personal;

    if ( action.payload.classification !== 'personal') return state.personal || {};

    let s = {...state};

    if ( action.type === 'SET_PERSONAL' ) {
        s.personal = {...s.personal, ...action.payload.data};

        let age = A.age(s.personal.dateOfBirth || null);
        if ( age ) {
            s.personal.yearsToRetirement = 60 - age;
        }
    }

    return s.personal;
};
