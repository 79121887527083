import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload ) return Calculator.init(state).preapprovalLetter;
    if ( action.payload.masterPage !== 'origination' ) return state.preapprovalLetter;

    if ( action.type === 'UPDATE_CALCULATIONS' ) {
        return Calculator.update(state).preapprovalLetter;
    }

    if ( action.payload.classification !== 'preapprovalLetter') return Calculator.init(state).preapprovalLetter;

    let s = {...state};
    s = Calculator.init(s);

    if ( action.type === 'SET_PREAPPROVAL_LETTER' ) {
        s.preapprovalLetter = {...s.preapprovalLetter, ...action.payload.data};
        s = Calculator.update(s);
    }

    if (action.payload.data.resetState) {
        s = Calculator.reset(s);
    }

    return s.preapprovalLetter;
};
