import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload ) return state.consentForm;
    if ( action.payload.masterPage !== 'clients' ) return state.consentForm;

    if ( action.payload.classification !== 'consentForm') return state.consentForm;

    let s = {...state};

    if ( action.type === 'SET_CONSENT_FORM' ) {
        s.consentForm = {...s.consentForm, ...action.payload.data};
    }

    return s.consentForm;
};
