import React, { Component } from 'react';
import {connect, Provider} from 'react-redux';

import {Col, Row, Container, ListGroup, ListGroupItem, ProgressBar} from 'react-bootstrap';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import Financials from './Financials';
import Surplus from './Surplus';
import QualifyingAmount from './QualifyingAmount';
import TotalQualifyingAmount from './TotalQualifyingAmount';
import PropertyDetails from './PropertyDetails';
import TotalCost from './TotalCost';
import MonthlySplit from "./MonthlySplit";
import SplitDetails from "./SplitDetails";
import { SideMenuItem } from "../../../js/ui/FieldTypes";
import { updateView } from '../../../js/actions';

import store from '../../../store/store';
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { PreapprovalLetterTemplate } from './OriginationTemplates';

class OriginationAside extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMode: 'general',
            clientId: null
        };

        this.state.pageProps = {
            client:
            {
                icon: "fas fa-chevron-left",
                value: 'client',
                label: "View Client",
            },
            general: {
                icon: "fa fa-dot-circle",
                    value: 'general',
                label: "General Options",
            },
            financials: {
                icon: "fa fa-chart-line",
                    value: 'financials',
                label: "Financials",
            },
            surplus: {
                icon: "flaticon2-pie-chart",
                    value: 'surplus',
                label: "Net Surplus"
            },
            qualifyingAmount: {
                icon: "fa fa-money-bill",
                    value: 'qualifyingAmount',
                label: "Qualifying Amount"
            },
            totalQualifyingAmount: {
                icon: "fa fa-money-bill-alt",
                    value: 'totalQualifyingAmount',
                    label: "Total Qualifying Amount"
            },
            propertyDetails: {
                icon: "fa fa-landmark",
                    value: 'propertyDetails',
                    label: "Property"
            },
            totalCost: {
                icon: "fa fa-coins",
                    value: 'totalCost',
                label: "Total Cost"
            },
            monthlySplit: {
                icon: "fa fa-chart-pie",
                    value: 'monthlySplit',
                label: "Monthly Split"
            },
            splitDetails: {
                icon: "fa fa-comment-dollar",
                value: 'splitDetails',
                label: "Split Summary"
            },
            preapprovalLetter: {
                icon: "fa fa-thumbs-up",
                value: 'preapprovalLetter',
                label: "Pre-approval Letter"
            }
        };

        window.Messenger.receive("SetClientId", this.handleSetClientId, this);

    }

    handleSetClientId(eventClass, token, data){
        this.setState({...this.state, clientId: data.clientId});
    }

    selectView(target, e){
        // e.preventDefault();
        if ( target === 'client' ) {
            return window.location.assign("/clients/" + this.state.clientId + "/edit");
        }
        this.setState({...this.state, viewMode: target});
        this.props.updateView({data: {currentView: target}, classification: 'pageView', masterPage: 'origination'});
    }

    getLetter() {
        return (
            <>
                <PreapprovalLetterTemplate
                    client={this.props.client}
                    general={this.props.general}
                    propertyDetails={this.props.propertyDetails}
                    qualifyingAmount={this.props.qualifyingAmount}
                    totalQualifyingAmount={this.props.totalQualifyingAmount}
                    totalCost={this.props.totalCost}
                    monthlySplit={this.props.monthlySplit}
                    surplus={this.props.surplus}
                />
            </>
        )
    }

    finishOrigination(){
        window.Messenger.broadcast('SetOriginationStatus', {finalStatus: 'completed'});
    }

    resetOrigination(){
        window.Messenger.broadcast('SetOriginationStatus', {finalStatus: 'reset'});
    }

    saveOrigination(){
        window.Messenger.broadcast('SetOriginationStatus', {finalStatus: 'save'});
    }

    render() {

        const perfectScrollbarOptions = {
            wheelPropagation: false,
            wheelSpeed: 2
        };

        const letter = this.getLetter();

        let pageList = [
            'client',
            'general',
            'financials',
            'surplus',
            'qualifyingAmount',
            'totalQualifyingAmount',
            'propertyDetails',
            'totalCost',
            'monthlySplit',
            'splitDetails',
            'preapprovalLetter'
        ];

        if ( this.props.general.loanPurpose === 'generalPreapproval' ) {
            pageList = [
                'client',
                'general',
                'financials',
                'surplus',
                'qualifyingAmount',
                'totalQualifyingAmount',
                'preapprovalLetter'
            ];
        }

        return (
            <Provider store={store}>
                {!this.props.pageView.isLoading &&
                <div className="App">
                    <Container>
                        <Row>
                            <Col md={4} className="text-center">
                                <a href="#!" className="origination-toolbar-controls save-button" onClick={() => this.saveOrigination()}>
                                    <i className="fa fa-check"></i><br />
                                    Save
                                </a>
                            </Col>
                            <Col md={4} className="text-center">
                                <a href="#!" className="origination-toolbar-controls finish-button" onClick={() => this.finishOrigination()}>
                                    <i className="fa fa-check-double"></i><br />
                                    Finish
                                </a>
                            </Col>
                            <Col md={4} className="text-center">
                                <a href="#!" className="origination-toolbar-controls reset-button" onClick={() => this.resetOrigination()}>
                                    <i className="fa fa-times"></i><br />
                                    Reset
                                </a>
                            </Col>
                        </Row>
                    </Container>
                    <br />
                    <Container>
                        {this.props.pageView.progress <= 30 &&
                        <>
                            <ProgressBar now={this.props.pageView.progress} variant="danger"  label={`${this.props.pageView.progress}%`} />
                        </>
                        }
                        {this.props.pageView.progress > 30 && this.props.pageView.progress <= 70 &&
                        <>
                            <ProgressBar now={this.props.pageView.progress} variant="warning"  label={`${this.props.pageView.progress}%`} />
                        </>
                        }
                        {this.props.pageView.progress > 70 && this.props.pageView.progress < 100 &&
                        <>
                            <ProgressBar now={this.props.pageView.progress} variant="info"  label={`${this.props.pageView.progress}%`} />
                        </>
                        }
                        {this.props.pageView.progress == 100 &&
                        <>
                            <ProgressBar now={this.props.pageView.progress} variant="success"  label={`${this.props.pageView.progress}%`} />
                        </>
                        }
                    </Container>
                    <ul className="kt-menu__nav ">

                        {pageList.map((page, key) => (
                            <SideMenuItem
                                key={key}
                                onClick={(e) => this.selectView(this.state.pageProps[page].value, e)}
                                target={this.state.pageProps[page].value}
                                pageView={this.props.pageView}
                                icon={this.state.pageProps[page].icon}
                                label={this.state.pageProps[page].label}
                            />
                        ))}

                    </ul>
                </div>
                }
                {this.props.pageView.isLoading &&
                <img src={"/media/misc/loading-menu.png"} style={{width: "100%"}} />
                }
            </Provider>
        );
    }
}

OriginationAside.propTypes = {
    updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    pageView: state.origination.pageView,
    general: state.origination.general,
    financials: state.origination.financials,
    surplus: state.origination.surplus,
    qualifyingAmount: state.origination.qualifyingAmount,
    totalQualifyingAmount: state.origination.totalQualifyingAmount,
    propertyDetails: state.origination.propertyDetails,
    monthlySplit: state.origination.monthlySplit,
    splitDetails: state.origination.splitDetails,
});

export default connect(mapStateToProps, { updateView })(OriginationAside);