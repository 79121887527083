const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload) return state.insurance || {};
    if ( action.payload.masterPage !== 'clients' ) return state.insurance;

    if ( action.payload.classification !== 'insurance') return state.insurance || {};

    let s = {...state};

    if ( action.type === 'SET_INSURANCE' ) {
        s.insurance = {...s.insurance, ...action.payload.data};
    }

    return s.insurance;
};
