import React, {} from 'react';
import {Container, FormControl, FormCheck, Row, Col, FormLabel} from "react-bootstrap";
import { RadioField, CurrencyField, DecimalText, CurrencyText, PercentageText, MoneyInputField, MoneyInfoField } from '../../../js/ui/FieldTypes';
import DateFnsUtils from '@date-io/date-fns';
import U from '../../../js/utils';
import axios from "axios";
import Lists from '../../../js/utils/Lists';
import AccessControl from "../../../js/utils/AccessControl";

export class PreapprovalLetterTemplate extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
            printView: false,
            client: {
                personal: {},
                marital: {},
                address: {},
            },
            paNumber: ''
        };

    }

    componentDidMount() {
        let clientId = this.props.clientId;
        if (clientId === 'undefined' || typeof clientId === 'undefined') {
            clientId = window.location.href.split('/')[4];
            this.setState({...this.state, clientId: clientId});
        }
        this.loadClient(clientId);
    }

    loadClient(clientId) {
        this.setState({...this.state, isLoading: true}, async function(){
            let formData = {clientId: clientId};
            await axios.post(window.endpoint('lms', '/get-origination'), formData)
                .then(result => {
                    if (typeof result.data !== 'undefined') {
                        let client = result.data.client || {};
                        let origination = result.data.origination || {};
                        let branchValue = client.personal.branch;
                        let branches = U.objectFromList(Lists.branches());
                        let branch = branches[branchValue];
                        let paNumber = 'FCHF10' + branch.code.toString() + '901';
                        this.setState({...this.state, paNumber: paNumber, client: {...this.state.client, client}, origination: origination});
                    }
                });
        });
    }

    getLetter() {
        let now = new Date();
        let currentDate = now.getDate() + "/" + (now.getMonth() + 1).toString() + "/" + now.getFullYear();
        return (
            <div id={"pre-approval-letter-container"}>

                <Container>
                    {!this.props.printView &&
                    <div className={" print-letter-btn"}>
                        <AccessControl required={"printPreApprovalLetter"} fallback={<><button className={"btn" +
                        " btn-sm btn-secondary"} disabled>
                            <i className={"fa fa-lock"}></i> &nbsp; Print Letter
                        </button></>}>
                            <button className={"btn btn-sm btn-secondary"} onClick={() => this.print()}>
                                <i className={"fa fa-print"}></i> &nbsp; Print Letter
                            </button>
                        </AccessControl>
                        <br /><br />
                    </div>
                    }
                    <Row>
                        <Col md={4} className={"text-blue"}>
                            No. 124 John Meinert Street<br />
                            Windhoek West, Windhoek<br />
                            P.O Box 4461<br />
                            Windhoek, Namibia
                        </Col>
                        <Col md={5}>
                            <i className={"fa fa-phone fc-blue"}></i> +264 61 446 700<br />
                            <i className={"fa fa-fax fc-blue"}></i> +264 61 401 353<br />
                            <i className={"fa fa-envelope fc-blue"}></i> info@firstcapitalnam.com<br />
                            <i className={"fa fa-globe fc-blue"}></i> www.firstcapitalnam.com
                        </Col>
                        <Col md={3} style={{"borderLeft": "solid 3px #00a1dd", "textAlign": "center"}}>
                            <img src="/media/logos/full-logo.png" className="preapproval-letter-logo" />
                        </Col>
                    </Row>
                </Container>
                <br /><br />
                <Container>
                    <Row>
                        <Col md={8}>
                            <table width="100%" className="table-condensed">c
                                <tbody>
                                <tr>
                                    <td width="40%">
                                        <strong>Borrower's Name:</strong>
                                    </td>
                                    <td>
                                        {this.props.client.personal.firstName}&nbsp;{this.props.client.personal.lastName}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>ID Number:</strong>
                                    </td>
                                    <td>
                                        {this.props.client.personal.idNumber}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Mobile Number:</strong>
                                    </td>
                                    <td>
                                        {this.props.client.personal.phoneMobile}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Postal Address:</strong>
                                    </td>
                                    <td>
                                        {this.props.client.address.postalAddress}<br />
                                        {this.props.client.address.region}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        &nbsp;
                                    </td>
                                    <td>
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Dear:</strong>
                                    </td>
                                    <td>
                                        {this.props.client.personal.title}&nbsp;{this.props.client.personal.firstName}&nbsp;{this.props.client.personal.lastName}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col md={4}>
                                <span className="pull-right">
                                    <strong>Date:</strong> &nbsp; {currentDate}
                                </span>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col md={12}>

                            <strong><u>RE: PRE-APPROVAL LETTER</u></strong>

                            <br />

                            <p>Based on the information you have provided to us and a preliminary review of your credit history, income, expenses, assets and liabilities. Company is pleased to inform you that your application for Home Loan is hereby pre-approved subject to the following terms;</p>

                            <br />

                            <table width="100%" className="table-condensed">
                                <tbody>
                                <tr>
                                    <td width="45%">
                                        <strong>Qualifying Amount:</strong>
                                    </td>
                                    <td>
                                        <CurrencyText
                                            value={this.props.totalQualifyingAmount.loanQualifyingAmount}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Recommended Purchase:</strong>
                                    </td>
                                    <td>
                                        <CurrencyText
                                            value={this.props.totalQualifyingAmount.recommendedAmount}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Floating Interest Rate::</strong>
                                    </td>
                                    <td>
                                        <PercentageText
                                            value={this.props.totalQualifyingAmount.interestRate}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Loan Term (years):</strong>
                                    </td>
                                    <td>
                                        <DecimalText
                                            value={this.props.totalQualifyingAmount.loanTerm}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Monthly Installment:</strong>
                                    </td>
                                    <td>
                                        <CurrencyText
                                            value={this.props.splitDetails.monthlyInstalmentOnTotalLoan}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Purpose:</strong>
                                    </td>
                                    <td>
                                        {U.convertKey('loanPurpose', this.props.general.loanPurpose)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Disbursement Date:</strong>
                                    </td>
                                    <td>
                                        Upon property registration
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Closing Costs:</strong>
                                    </td>
                                    <td>
                                        An estimated 1.% of the committed loan plus applicable fees e.g admin fees and valuation fees
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <br />

                            <h5>Conditions Precedent</h5>

                            <br />

                            <ol>
                                <li>1st Bond equal to 100% of Market value of property being financed is available subject to the employer issuing a 10% irrevocable guarantee in favor of FCHF, otherwise FCHF can only inance you a loan equivalent to 90% of the property market value, which should not exceed your qualifying amount.</li>
                                <li>Fire insurance (Mandatory).</li>
                                <li>Life Cover (Mandatory).</li>
                                <li>Pre-approval valid for Six(6) months from the date of issue.</li>
                                <li>Failure to secure a property within the pre-approval validity period, all documents will be void.</li>
                                <li>An amount equivalent to the applicant(s) contribution(s) will be reserved on Avril, for a period of six months. Any request for cancellation of the reservation should be done in writing and such cancellation will have an impact on the pre-approved amount.</li>
                                <li>Subsidy is only valid for 20 years. Any loan term above 20 years, the applicant will be required to pay the full monthly instalment after the subsidy has lapsed.</li>
                            </ol>

                            <br />

                            <h5>Other Conditions</h5>

                            <br />

                            <p>Actual loan disbursement is contingent upon verification of all information supplied by you, including, but not limited to, a satisfactory appraisal of the subject property, satisfactory title search and final underwriting decision.</p>

                            <br />

                            <p>
                                <strong>This Pre-approval should not be construed as a formal loan approval, but rather a preliminary determination that you qualify for a mortgage bond under our current lending standards and guidelines. Please note if the final approval is granted for your loan, the terms loan amount and conditions may be different to what is described here.</strong>
                            </p>

                            <br />

                            <p>
                                If you have any question or need assistance, please do not hesitate to contact our office.
                            </p>

                            <br />

                            <p>
                                <strong>____________________</strong>
                            </p>

                            <br />

                            <p>
                                <strong>
                                    Yours Faithfully
                                </strong>
                            </p>

                            <br /><br /><br />

                            <p>
                                <strong>
                                    Dr. Martin Mwinga<br />
                                    Fund Manager
                                </strong>
                            </p>

                            <div style={{position: 'absolute', marginTop: '25px', right: '5%'}}>
                                <p className="text-right">
                                    <strong>PA No:</strong> {this.state.paNumber}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }

    print() {
        // window.Messenger.broadcast('PrintViewLetter', {});

        let content = document.getElementById("pre-approval-letter-container").innerHTML;
        let a = window.open('', '', 'height=680, width=1020');
        a.document.write('<html>');
        a.document.write('<style>* {font-size: 98% !important;} .print-letter-btn {display: none !important;}' +
            ' .fc-blue {color: #5989ff;}</style>');
        a.document.write('<link' +
            ' href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"' +
            ' integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN"' +
            ' crossOrigin="anonymous">');
        a.document.write('<link href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"' +
            ' rel="stylesheet" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"' +
            ' crossOrigin="anonymous">');
        a.document.write('<body>');
        a.document.write(content);
        a.document.write('<div class="row" style="width: 98vw; margin:auto; background: #009cdb !important; padding:' +
            ' 15px">\n' +
            '                <div class="col-md-6" style="color: #FFFFFF !important; font-size: 75% !important;">\n' +
            '                    <strong>Board of Trustees:</strong> Mr. Roland Dishena (Chairman of the Board), Dr. Martin' +
            ' S Mwinga, Mr. Philip M Mwangala, Mr. Lawrence C Kavendjii, Mr. Gerson Kadhikwa, Mr. Vitalis M Ndalikokule, Mr. Kendandei Tjivikua.\n' +
            '                </div>\n' +
            '                <div class="col-md-4" style="color: #FFFFFF !important; padding-top: 10px">\n' +
            '                    <h1 style="font-weight: normal !important; font-size: 105% !important;">\n' +
            '                        Knowledge | Creativity | Solutions\n' +
            '                    </h1>\n' +
            '                </div>\n' +
            '            </div>');
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    render() {
        const letter = this.getLetter();
        return (
            <>
                {!this.state.printView &&
                <div className={""}>
                    {letter}
                </div>
                }
                {this.state.printView &&
                <div className={"print-view"}>
                    {letter}
                </div>
                }
            </>
        )

    }

}


