import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setMonthlySplit, updateCalculations, updateView } from '../../../js/actions';
import { CurrencyText, MoneyInfoField } from '../../../js/ui/FieldTypes';
import {InputGroup, FormControl, FormCheck, Row, Col, FormLabel, Form, Alert} from "react-bootstrap";

class MonthlySplit extends Component {
  
  constructor(props) {
    super(props);
    let fields = [

    ], state = { data: {}};

    for(let f = 0; f < fields.length; f++) {
    	state.data[fields[f]] = this.props.monthlySplit[fields[f]] || '';
    }

    this.state = state;
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.updateCalculations({data: {masterPage: 'origination'}});
  }

  onChange(e) {
    let newValues = {[e.target.name]: e.target.value};
    this.setState({...this.state, data: {...this.state.data, ...newValues}});
    this.props.setMonthlySplit({data: { [e.target.name]: e.target.value }, classification: 'monthlySplit', masterPage: 'origination'});
	  this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
  }

  render() {
    return (
	    <div>

			<div id="monthlySplit">

				<div className="field-group">

					<MoneyInfoField
						label="Monthly Instalment on Total Loan:"
						value={this.props.splitDetails.monthlyInstalmentOnTotalLoan}
						className="strong-summary"
					/>

				</div>

				{this.props.client.marital.typeOfApplication === 'individual' &&
				<>
					<Alert variant={"info"}>
						Monthly split details are only available for joint applications.
					</Alert>
				</>
				}

				{this.props.client.marital.typeOfApplication === 'joint' &&
				<>
					<h3>Basic Bond</h3>

					<div className="field-group">

						<MoneyInfoField
							label="Total:"
							value={this.props.monthlySplit.basicBondTotal}
							className="strong-summary"
						/>

						<MoneyInfoField
							label="Main Applicant:"
							value={this.props.monthlySplit.basicBond}
						/>

						<MoneyInfoField
							label="Co-pplicant:"
							value={this.props.monthlySplit.basicBondCoapplicant}
						/>

					</div>

					<h3>Excess Bond</h3>

					<div className="field-group">

						<MoneyInfoField
							label="Total:"
							value={this.props.monthlySplit.excessBondTotal}
							className="strong-summary"
						/>

						<MoneyInfoField
							label="Main Applicant:"
							value={this.props.monthlySplit.excessBond}
						/>

						<MoneyInfoField
							label="Co-pplicant:"
							value={this.props.monthlySplit.excessBondCoapplicant}
						/>

					</div>
				</>
				}

			</div>

		</div>
	)
  }
}

MonthlySplit.propTypes = {
  setMonthlySplit: PropTypes.func.isRequired,
  updateCalculations: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	monthlySplit: state.origination.monthlySplit,
    surplus: state.origination.surplus,
    splitDetails: state.origination.splitDetails,
});

export default connect(mapStateToProps, { setMonthlySplit, updateCalculations, updateView })(MonthlySplit);