import A from '../../../js/math/Arithmetic';

const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload) return state.nextOfKin || {};
    if ( action.payload.masterPage !== 'clients' ) return state.nextOfKin;

    if ( action.payload.classification !== 'nextOfKin') return state.nextOfKin || {};

    let s = {...state};

    if ( action.type === 'SET_NEXT_OF_KIN' ) {
        s.nextOfKin = {...s.nextOfKin, ...action.payload.data};

        let age = A.age(s.nextOfKin.dateOfBirth || null);
        if ( age ) {
            s.nextOfKin.yearsToRetirement = 60 - age;
        }
    }

    return s.nextOfKin;
};
