const initialState = {};

export default function (state = initialState, action) {

	let s = {...state};

	if ( action.type === 'SET_PERMISSIONS' ) {
		s.globalState = action.payload.data;
	}

	return s.globalState;
};
