import Lists from './Lists';

const U = {

    convertKey: (listId, key, list) => {
        let object = U.listFromKey(listId, list);
        return object[key] ?? key;
    },

    objectFromKey: (listId, key) => {
        let object = U.listFromKey(listId);
        return object ?? {};
    },

    listFromKey: (listId, list) => {
        let lists = {loanPurpose: {
                generalPreapproval: 'General Pre-approval',
                plotAndPlan: 'Plot and Plan',
                improvementOwnProperty: 'Improvement Own Property',
                purchaseExistingPropertyAsIs: 'Purchase Existing Property As Is',
                bondTakeover: 'Bond Takeover',
                purchaseAnExistingPropertyAndImprovement: 'Purchase An Existing Property and Improvement',
            }
        };

        if ( listId === 'documentTypes' ) {
            lists.documentTypes = U.valueFromList(lists);
        }

        return lists[listId] ?? {};
    },

    valueFromList: (list) => {
        let newList = {};
        for ( let i = 0; i < list.length; i++ ) {
            newList[list[i].value] = list[i].label;
        }
        return newList;
    },

    objectFromList: (list) => {
        let newList = {};
        for ( let i = 0; i < list.length; i++ ) {
            newList[list[i].value] = list[i];
        }
        return newList;
    }

};

export default U;