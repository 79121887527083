import A from './Arithmetic.js';

const Calculator = {

    init: (s) => {
        let fields = [
            'financials',
            'surplus',
            'qualifyingAmount',
            'totalQualifyingAmount',
            'propertyDetails',
            'totalCost',
            'monthlySplit',
            'splitDetails',
            'pageView'
        ];

        for( let i = 0; i < fields.length; i++){
            if (!s[fields[i]]) s[fields[i]] = {};
        }

        s = Calculator.initGeneral(s, false);
        s = Calculator.initPageView(s, false);
        s = Calculator.initFinancials(s, false);
        s = Calculator.initPropertyDetails(s, false);

        return s;
    },

    reset: (s) => {
        let fields = [
            'financials',
            'surplus',
            'qualifyingAmount',
            'totalQualifyingAmount',
            'propertyDetails',
            'totalCost',
            'monthlySplit',
            'splitDetails',
            'pageView'
        ];

        for( let i = 0; i < fields.length; i++){
            s[fields[i]] = {};
        }

        // s = Calculator.initGeneral(s, false);
        // s = Calculator.initPageView(s, false);
        // s = Calculator.initFinancials(s, false);
        s = Calculator.initPropertyDetails(s, true);

        console.log(s);

        return s;
    },

    update: (s) => {

        s = Calculator.calculateGeneral(s);
        s = Calculator.calculateFinancials(s, '');
        s = Calculator.calculateFinancials(s, 'Coapplicant');
        s = Calculator.calculateSurplus(s, '');
        s = Calculator.calculateSurplus(s, 'Coapplicant');
        s = Calculator.calculateQualifyingAmount(s);
        s = Calculator.calculateTotalQualifyingAmount(s);
        s = Calculator.calculatePropertyDetails(s);
        s = Calculator.calculateTotalCost(s);
        s = Calculator.calculateSplitDetails(s);
        s = Calculator.calculatePageView(s);

        return s;

    },

    // Helper functions

    _debtConsolidations: (s, args) => {
        let collection = args.collection;
        let totalField = args.totalField;
        let monthlyPaymentField = args.monthlyPaymentField;
        let debts = s.financials[args.collection] || [],
            totalDebt = 0,
            totalMonthlyDebt = 0;
        for (let i = debts.length - 1; i >= 0; i--) {
            totalDebt = A.add([totalDebt, debts[i].totalBalance]);
            totalMonthlyDebt = A.add([totalMonthlyDebt, debts[i].monthlyPayment]);
        }
        s.financials[totalField] = totalDebt;
        s.financials[monthlyPaymentField] = totalMonthlyDebt;
        return s;
    },

    _investmentsSavings: (s, suffix) => {
        let investments = s.surplus['investmentSavingsDetails' + suffix] || [],
            totalInvestment = 0;
        for (let i = investments.length - 1; i >= 0; i--) {
            totalInvestment = A.add([totalInvestment, investments[i].amount]);
        }
        s.surplus['investmentSavingsTotal' + suffix] = totalInvestment;
        return s;
    },

    _otherInflows: (s, suffix) => {
        let otherInflows = s.surplus['otherInflowsDetails' + suffix] || [],
            totalOtherInflows = 0;
        for (let i = otherInflows.length - 1; i >= 0; i--) {
            totalOtherInflows = A.add([totalOtherInflows, otherInflows[i].amount]);
        }
        s.surplus['otherInflowsTotal' + suffix] = totalOtherInflows;
        return s;
    },

    _bankCommitments: (s, suffix) => {
        let bankCommitments = s.surplus['bankCommitmentsDetails' + suffix] || [],
            totalBankCommitments = 0;
        for (let i = bankCommitments.length - 1; i >= 0; i--) {
            totalBankCommitments = A.add([totalBankCommitments, bankCommitments[i].amount]);
        }
        s.surplus['bankCommitmentsTotal' + suffix] = totalBankCommitments;
        return s;
    },

    // Initiation functions

    initPageView: (s, reset) => {
        let defaultPageView = {currentView: 'financials', completion: {}};
        s.pageView = reset ? defaultPageView : s.pageView || defaultPageView;
        s.pageView.completion = s.pageView.completion || {};
        s.pageView.progress = s.pageView.progress || 0;
        return s;
    },

    initGeneral: (s, reset) => {
        s.general = reset ? {} : s.general || {};
        return s;
    },

    initFinancials: (s, reset) => {
        s.financials.debtConsolidationDetails = reset ? {} : s.financials.debtConsolidationDetails || [];
        s.financials.debtConsolidationDetailsCoapplicant = reset ? {} : s.financials.debtConsolidationDetailsCoapplicant || [];
        return s;
    },

    initPropertyDetails: (s, reset) => {
        s.propertyDetails.showDeedOfSale = true;
        s.propertyDetails.showQuotation = true;
        s.propertyDetails.showOutstandingBalance = true;
        s.propertyDetails.showBuildingPlan = true;
        s.propertyDetails.showSellersId = true;
        s.propertyDetails.showProofOfOwnership = true;
        s.propertyDetails.showDosAllocationLetterPlot = true;
        s.propertyDetails.deedOfSaleDocuments = reset ? [] : s.propertyDetails.deedOfSaleDocuments || [];
        s.propertyDetails.quotationDocuments = reset ? [] : s.propertyDetails.quotationDocuments || [];
        s.propertyDetails.outstandingBalanceDocuments = reset ? [] : s.propertyDetails.outstandingBalanceDocuments || [];
        s.propertyDetails.buildingPlanDocuments = reset ? [] : s.propertyDetails.buildingPlanDocuments || [];
        s.propertyDetails.sellersIdDocuments = reset ? [] : s.propertyDetails.sellersIdDocuments || [];
        s.propertyDetails.proofOfOwnershipDocuments = reset ? [] : s.propertyDetails.proofOfOwnershipDocuments || [];
        s.propertyDetails.dosAllocationLetterPlotDocuments = reset ? [] : s.propertyDetails.dosAllocationLetterPlotDocuments || [];

        s.propertyDetails.contractors = reset ? [] : s.propertyDetails.contractors || [];
        return s;
    },

    // Main calculation functions

    calculateGeneral: (s) => {

        s.general.showPaidOff = s.general.loanPurpose === "improvementOwnProperty" || s.general.loanPurpose === "plotAndPlan";
        s.general.showHalfPaid = s.general.loanPurpose === "bondTakeOver" || s.general.loanPurpose === "plotAndPlan";
        s.general.showNotYetPaid = s.general.loanPurpose === "purchaseExistingPropertyAsIs" || s.general.loanPurpose === "purchaseAnExistingPropertyAndImprovement" || s.general.loanPurpose === "plotAndPlan";

        return s;

    },

    calculateFinancials: (s, suffix) => {

        suffix = suffix || '';

        s.financials['netSalary' + suffix] = A.sub(s.financials['grossSalary' + suffix], A.add([s.financials['currentSubsidy' + suffix], s.financials['payrollDeductions' + suffix]]));

        // Subsidy table calculations
        let subsidyTable = [
            {'salaryRange' : 0, 'maxAmount' : 220000, 'monthlySubsidy' : 0, 'subsidyAmount' : 0.70, 'band' : 'A'},
            {'salaryRange' : 91000, 'maxAmount' : 297000, 'monthlySubsidy' : 2253, 'subsidyAmount' : 0.68, 'band' : 'C'},
            {'salaryRange' : 121000, 'maxAmount' : 396000, 'monthlySubsidy' : 2918, 'subsidyAmount' : 0.68, 'band' : 'D'},
            {'salaryRange' : 151000, 'maxAmount' : 495000, 'monthlySubsidy' : 3648, 'subsidyAmount' : 0.68, 'band' : 'E'},
            {'salaryRange' : 181000, 'maxAmount' : 594000, 'monthlySubsidy' : 4377, 'subsidyAmount' : 0.60, 'band' : 'F'},
            {'salaryRange' : 211000, 'maxAmount' : 693000, 'monthlySubsidy' : 4957, 'subsidyAmount' : 0.60, 'band' : 'G'},
            {'salaryRange' : 240000, 'maxAmount' : 792000, 'monthlySubsidy' : 5665, 'subsidyAmount' : 0.60, 'band' : 'H'}
        ];
        let salary = s.financials['basicSalary' + suffix] * 12;
        let maxAmount = 220000;
        let subsidyAmount = 0;
        let subsidyBand = "A";
        let upperBoundSalary, upperBoundSubsidy, upperBoundMax, upperBoundBand,
            lowerBoundSalary, lowerBoundSubsidy;

        for ( let i = 0; i < subsidyTable.length; i++ ) {
            if ( salary >= subsidyTable[i].salaryRange ) {
                subsidyAmount = subsidyTable[i].monthlySubsidy;

                if ( i < ( subsidyTable.length - 1 ) ) {

                    upperBoundSalary = subsidyTable[i+1].salaryRange;
                    upperBoundSubsidy = subsidyTable[i+1].monthlySubsidy;
                    upperBoundMax = subsidyTable[i+1].maxAmount;
                    upperBoundBand = subsidyTable[i+1].band;

                    lowerBoundSalary = subsidyTable[i].salaryRange;
                    lowerBoundSubsidy = subsidyTable[i].monthlySubsidy;

                } else {

                    upperBoundSalary = subsidyTable[i].salaryRange;
                    upperBoundSubsidy = subsidyTable[i].monthlySubsidy;
                    upperBoundMax = subsidyTable[i].maxAmount;
                    upperBoundBand = subsidyTable[i].band;

                    lowerBoundSalary = subsidyTable[i].salaryRange;
                    lowerBoundSubsidy = subsidyTable[i].monthlySubsidy;

                }

                let diff = upperBoundSalary - lowerBoundSalary;
                let rate = ( salary - lowerBoundSalary ) / ( upperBoundSalary - lowerBoundSalary );

                subsidyAmount = upperBoundSubsidy;
                maxAmount = upperBoundMax;
                subsidyBand = upperBoundBand;

            }

        }

        s.financials['housingSubsidy' + suffix] = subsidyAmount;
        s.financials['housingSubsidyBand' + suffix] = subsidyBand;
        s.financials['housingSubsidyMaxAmount' + suffix] = maxAmount;

        s = Calculator._debtConsolidations(s,
            {
                collection: 'debtConsolidationDetails' + suffix,
                totalField: 'debtConsolidationTotal' + suffix,
                monthlyPaymentField: 'debtConsolidationMonthly' + suffix
            });

        s.financials['totalOtherIncome' + suffix] = A.add([s.financials['debtConsolidationMonthly' + suffix], s.financials['housingSubsidy' + suffix]]);
        s.financials['newMonthlyIncome' + suffix] = A.add([s.financials['netSalary' + suffix], s.financials['totalOtherIncome' + suffix]]);

        return s;

    },

    calculateSurplus: (s, suffix) => {

        s.surplus['bankBalance' + suffix] = A.sub(s.surplus['bankClosingBalance' + suffix], s.surplus['bankOpeningBalance' + suffix]);

        s = Calculator._investmentsSavings(s, suffix);
        s = Calculator._otherInflows(s, suffix);

        s.surplus['totalInflows' + suffix] = A.add([s.surplus['investmentSavingsTotal' + suffix],
            s.surplus['currentBondInstalment' + suffix],
            s.surplus['currentRent' + suffix],
            s.surplus['otherInflowsTotal' + suffix]]);

        s = Calculator._bankCommitments(s, suffix);

        s.surplus['totalOutflows' + suffix] = A.add([s.surplus['bankCharges' + suffix],
            s.surplus['bankCommitmentsTotal' + suffix],
            s.surplus['otherOutflows' + suffix]]);

        s.surplus['totalMonthlyAdjustment' + suffix] = A.sub(s.surplus['totalInflows' + suffix], s.surplus['totalOutflows' + suffix]);

        return s;
    },

    calculateQualifyingAmount: (s) => {

        s.qualifyingAmount.qualifyingMonthlyBasicInstalment = A.add([A.sub(s.financials.netSalary, s.surplus.totalOutflows),
            s.surplus.currentBondInstalment,
            s.surplus.otherInflows,
            s.surplus.currentRent,
            s.surplus.investmentSavingsTotal]);

        s.qualifyingAmount.individualContribution = A.sub(A.mul([s.financials.grossSalary, 0.65]), s.financials.payrollDeductions);

        s.qualifyingAmount.adjustedAvrilReservation = A.sub(s.financials.debtConsolidationMonthly, s.qualifyingAmount.avrilReservation);

        s.qualifyingAmount.netSurplus = A.sub(A.add([s.financials.totalOtherIncome, s.qualifyingAmount.individualContribution]), s.financials.currentSubsidy);

        // s.qualifyingAmount.avrilFees = A.mul([s.qualifyingAmount.netSurplus, 0.02, 1.15]);
        s.qualifyingAmount.avrilFees = 0;

        s.qualifyingAmount.provisionFee = A.mul([s.qualifyingAmount.netSurplus, 0.005]);

        s.qualifyingAmount.netSurplusAvailableForHomeLoan = A.sub(s.qualifyingAmount.netSurplus, A.add([s.qualifyingAmount.avrilFees, s.qualifyingAmount.provisionFee]));

        return s;

    },

    calculateTotalQualifyingAmount: (s) => {
        s.totalQualifyingAmount.interestRate = parseFloat((10.50).toFixed(2));

        s.totalQualifyingAmount.loanQualifyingAmount = A.pv(s.totalQualifyingAmount.interestRate, s.totalQualifyingAmount.loanTerm, A.mul([-1, s.qualifyingAmount.netSurplusAvailableForHomeLoan]));
        s.totalQualifyingAmount.totalLoan = Math.min( A.add([s.totalCost.totalCosts, s.totalCost.nonHousingFees]), s.totalQualifyingAmount.loanQualifyingAmount );
        s.totalQualifyingAmount.recommendedAmount = s.general.loanPurpose === 'generalPreapproval' ? A.mul([s.totalQualifyingAmount.loanQualifyingAmount, 0.9]) : s.totalCost.precalculatedLoanAmount;

        return s;
    },

    calculatePropertyDetails: (s) => {

        // Determine when to show which property document and amount
        if ( true ) {
            s.propertyDetails.showDeedOfSale =
                (s.general.loanPurpose === 'plotAndPlan' && s.general.typeOfOwnership === 'halfPaid') ||
                (s.general.loanPurpose === 'purchaseAnExistingPropertyAndImprovement' && s.general.typeOfOwnership === 'notYetPaid') ||
                (s.general.loanPurpose === 'purchaseExistingPropertyAsIs' && s.general.typeOfOwnership === 'notYetPaid') ||
                (s.general.typeOfOwnership === 'notYetPaid' && s.general.loanPurpose !== 'plotAndPlan');
            s.propertyDetails.showQuotation = (
                s.general.loanPurpose === 'plotAndPlan' ||
                (s.general.loanPurpose === 'improvementOwnProperty' && s.general.typeOfOwnership === 'paidOff') ||
                (s.general.loanPurpose === 'bondTakeover' && s.general.typeOfOwnership === 'halfPaid') ||
                (s.general.loanPurpose === 'purchaseAnExistingPropertyAndImprovement' && s.general.typeOfOwnership === 'notYetPaid') ||
                s.general.typeOfOwnership === 'notYetPaid');
            s.propertyDetails.showOutstandingBalance = (s.general.loanPurpose == 'plotAndPlan' && (s.general.typeOfOwnership === 'halfPaid' || s.general.typeOfOwnership === 'notYetPaid')) || (s.general.loanPurpose === 'bondTakeover' && s.general.typeOfOwnership === 'halfPaid');
            s.propertyDetails.showDosAllocationLetterPlot = s.general.loanPurpose === 'plotAndPlan' && s.general.typeOfOwnership === 'notYetPaid';
            s.propertyDetails.showProofOfOwnership = (s.general.loanPurpose === 'plotAndPlan' || (s.general.loanPurpose === 'improvementOwnProperty' && s.general.typeOfOwnership === 'paidOff') || (s.general.loanPurpose === 'purchaseExistingPropertyAsIs' && s.general.typeOfOwnership === 'notYetPaid') || (s.general.loanPurpose === 'bondTakeover' && s.general.typeOfOwnership === 'halfPaid') || (s.general.loanPurpose === 'purchaseAnExistingPropertyAndImprovement' && s.general.typeOfOwnership === 'notYetPaid'));
            s.propertyDetails.showBuildingPlan = (s.general.loanPurpose === 'plotAndPlan' || (s.general.loanPurpose === 'improvementOwnProperty' && s.general.typeOfOwnership === 'paidOff') || (s.general.loanPurpose == 'bondTakeover' && s.general.typeOfOwnership === 'halfPaid') || (s.general.loanPurpose == 'purchaseAnExistingPropertyAndImprovement' && s.general.typeOfOwnership === 'notYetPaid'));
            s.propertyDetails.showSellersId = ((s.general.loanPurpose === 'plotAndPlan' && s.general.typeOfOwnership !== 'paidOff') || (s.general.loanPurpose === 'purchaseExistingPropertyAsIs' && s.general.typeOfOwnership === 'notYetPaid') || (s.general.loanPurpose === 'purchaseAnExistingPropertyAndImprovement' && s.general.typeOfOwnership === 'notYetPaid'));
        }

        s.propertyDetails.valuationTravellingFees = A.mul([s.propertyDetails.numberOfValuations || 0, s.propertyDetails.valuationRate || 0, s.propertyDetails.valuationDistance || 0, 3]);
        s.propertyDetails.valuationTravellingServiceFees = A.mul([s.propertyDetails.numberOfValuations || 0, 1250]);

        return s;
    },

    calculateTotalCost: (s) => {
        s.totalCost.valuationTravellingFees = s.propertyDetails.valuationTravellingFees;
        s.totalCost.valuationTravellingServiceFees = s.propertyDetails.valuationTravellingServiceFees;
        // Pre-calculated loan amount
        let precalculatedLoanAmount = A.add([s.propertyDetails.quotationAmount, s.propertyDetails.outstandingBalanceAmount]);
        let loanPurpose = s.general.loanPurpose;
        if ( loanPurpose === 'purchaseAnExistingPropertyAndImprovement' || loanPurpose === 'purchaseExistingPropertyAsIs' ) {
            precalculatedLoanAmount = A.add([precalculatedLoanAmount, s.propertyDetails.deedOfSaleAmount]);
        } else if ( loanPurpose === 'generalPreapproval' ) {
            precalculatedLoanAmount = s.totalQualifyingAmount.loanQualifyingAmount;
        }

        s.totalCost.precalculatedLoanAmount = precalculatedLoanAmount;

        // Valuation fees
        if ( s.general.loanPurpose === 'purchaseExistingPropertyAsIs' ) {
            s.totalCost.valuationFees = 0; //1000;
        } else {
            s.totalCost.valuationFees = 0; //4000;
        }

        // Bond cancellation fees
        if ( s.general.loanPurpose === 'bondTakeover' ) {
            s.totalCost.bondCancellationFees = A.mul([0.05, s.propertyDetails.outstandingBalanceAmount]);
        } else {
            s.totalCost.bondCancellationFees = 0;
        }

        // Closing fees

        s.totalCost.provisionalFees = A.mul([A.add([s.propertyDetails.deedOfSaleAmount,
                                            s.propertyDetails.quotationAmount,
                                            s.propertyDetails.outstandingBalanceAmount]), 0.00625]);

        /*s.totalCost.avrilFees = A.mul([A.add([s.qualifyingAmount.qualifyingMonthlyBasicInstalment,
                                                s.totalCost.insuranceFees,
                                                s.totalCost.serviceFees]), 0.02, 1.17615]);*/
        s.totalCost.avrilFees = 0;

        s.totalCost.valuationTotalCost = A.add([
            s.totalCost.valuationTravellingFees,
            s.totalCost.valuationTravellingServiceFees
        ]);

        let totalAmount = A.add([s.propertyDetails.quotationAmount,
                                s.propertyDetails.outstandingBalanceAmount,
                                s.totalCost.valuationTotalCost,
                                s.totalCost.transferFees,
                                s.totalCost.bondRegistrationFee,
                                s.totalCost.insuranceFees,
                                s.totalCost.cancellationFees,
                                s.totalCost.provisionalFees,
                                s.totalCost.avrilFees,
                                s.totalCost.serviceFees]);

        let factor = s.financials.debtConsolidationTotal > 0 ? .015 : .01;

        s.totalCost.closingFees = A.mul([totalAmount, factor]);

        // Total costs

        s.totalCost.totalCosts = A.add([s.totalCost.valuationFees,
            s.totalCost.valuationTravellingFees,
            s.totalCost.valuationTravellingServiceFees,
            s.totalCost.transferFees,
            s.totalCost.bondRegistrationFee,
            s.totalCost.insuranceFees,
            s.totalCost.bondCancellationFees,
            s.totalCost.provisionalFees,
            s.totalCost.avrilFees,
            s.totalCost.avrilServiceFees,
            s.totalCost.closingFees,
            s.totalCost.precalculatedLoanAmount,]);

        s.totalCost.totalDebtConsolidationFees = A.mul([1.015, s.financials.debtConsolidationTotal]);
        s.totalCost.nonHousingFees = A.mul([1.015, s.financials.debtConsolidationTotal]);
        // s.totalCost.totalLoan = s.financials.debtConsolidationTotal > 0 ? A.add([s.totalCost.totalCosts, s.totalCost.precalculatedLoanAmount]) : s.totalCost.totalCosts;
        s.totalCost.housingFees = s.totalCost.totalCosts;
        // s.totalCost.subTotalLoan = A.add([s.totalCost.nonHousingFees, s.totalCost.housingFees]);
        s.totalCost.totalLoan = A.add([s.totalCost.nonHousingFees, s.totalCost.housingFees]);
        s.totalCost.topUp = Math.max(A.sub(s.totalCost.totalLoan, s.totalQualifyingAmount.loanQualifyingAmount), 0);

        return s;
    },

    calculateSplitDetails: (s) => {
        // Monthly subsidy

        s.splitDetails.excessBand = A.sub(s.totalCost.totalLoan, s.splitDetails.totalSubsidy);
        s.splitDetails.monthlySubsidy = s.totalCost.totalLoan > s.splitDetails.totalSubsidy ? s.financials.housingSubsidy : A.div(A.mul([s.financials.housingSubsidy, s.totalCost.totalLoan]) / s.splitDetails.totalSubsidy);

        s.splitDetails.monthlyInstalmentOnTotalLoan = A.pmt(s.totalQualifyingAmount.interestRate * 0.01/12, s.totalQualifyingAmount.loanTerm * 12, A.mul([-1, s.totalCost.totalLoan]));

        // $scope.setSplitAmount();
        // $scope.get_monthly_subsidy( 'subsidy_max_amount', 'monthly_subsidy_main_applicant', '', 'split_main_applicant' );
        // $scope.setMonthlyInstalmentMultiplier();
        //
        // $scope.data.monthly_amount_tl_main_applicant = pmt($scope.data.interest_rate*0.01/12, $scope.data.loan_term * 12, -$scope.data.split_main_applicant)
        //
        // $scope.data.monthly_instalment_on_total_loan = $scope.data.monthly_amount_tl_main_applicant;// = +($scope.data.total_excess_band) + +($scope.data.monthly_subsidy_main_applicant);
        //
        // if ( $scope.data.monthly_instalment_multiplier > 0 ) {
        //
        //     $scope.data.monthly_instalment_on_total_loan += $scope.data.monthly_instalment_multiplier * $scope.data.monthly_instalment_on_total_loan;
        //
        // }

        if ( s.general.loanPurpose === 'generalPreapproval' ) {
            s.totalQualifyingAmount.monthlyInstalmentOnTotalLoan = A.mul([s.totalQualifyingAmount.monthlyInstalmentOnTotalLoan, 1.1]);
            s.splitDetails.monthlyInstalmentOnTotalLoan = A.mul([s.splitDetails.monthlyInstalmentOnTotalLoan, 1.1]);
        }

        s.splitDetails.individualContribution = A.sub(s.splitDetails.monthlyInstalmentOnTotalLoan, s.splitDetails.monthlySubsidy);

        return s;
    },

    calculatePageView: (s) => {
        let completion = 0;
        let targets = ['general', 'financials', 'surplus', 'qualifyingAmount', 'totalQualifyingAmount', ];
        if ( s.financials.loanPurpose !== 'generalPreapproval' ) {
            targets.push('propertyDetails');
            targets.push('totalCost');
            targets.push('monthlySplit');
            targets.push('splitDetails');
        }
        let sectionCount = targets.length;
        let completed = 0;
        for (let t = 0; t < sectionCount; t++ ) {
            if ( s.pageView.completion[targets[t]] || false ) {
                completed++;
            }
        }
        let ratio = Math.round(100 * completed / sectionCount);
        s.pageView.progress = ratio;
        return s;
    }
}

export default Calculator;