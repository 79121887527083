import axios from "axios";
import {Button, Modal, ListGroup} from "react-bootstrap";
import React from "react";
import { updateView } from '../../../js/actions';
import {connect} from "react-redux";
import PropTypes from "prop-types";

class GlobalSelectClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSelectClient: false,
            clients: [],
            token: null,
            query: null,
            client: { personal: {}}
        }

        window.Messenger.receive("GlobalSelectClient", this.handleShowSelectClientMessage, this);
        this.handleSelectClient = this.handleSelectClient.bind(this);
        this.handleCloseSelectClient = this.handleCloseSelectClient.bind(this);

    }

    componentDidMount() {
    }

    handleShowSelectClientMessage(eventClass, token, data) {
        this.setState({...this.state, token: data.token, query: data.query}, function () {
            this.handleShowSelectClient();
        });
    }

    handleCloseSelectClient() {
        this.setState({showSelectClient: false});
    }

    handleShowSelectClient() {
        let query = this.state.query;
        this.setState({...this.state, showSelectClient: true});
        this.props.updateView({data: {setLoadingState: true, isLoading: true}, classification: 'pageView', masterPage: 'clients'});
        axios.post(window.endpoint('lms', '/get-clients'), {query: query})
            .then(result => {
                if (typeof result.data !== 'undefined') {
                    let clients = result.data.clients.records;
                    this.setState({clients: clients});
                }
                this.props.updateView({data: {setLoadingState: true, isLoading: false}, classification: 'pageView', masterPage: 'clients'});
            });
    }

    handleSelectClient() {
        let payload = {token: this.state.token, client: this.state.client};
        window.Messenger.broadcast('SelectedClientResult', payload);
        this.setState({showSelectClient: false});
    }

    selectClient(client) {
        this.setState({...this.state, client: client});
    }

    render(){
        return (
            <Modal show={this.state.showSelectClient} onHide={this.handleCloseSelectClient}>
                <Modal.Header closeButton>
                    <Modal.Title>Select a Client</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Selected client:&nbsp;
                        {this.state.client._id &&
                        <strong>
                            {this.state.client.personal.firstName}&nbsp;{this.state.client.personal.lastName}
                        </strong>
                        }
                        {!this.state.client._id &&
                        <>
                            None
                        </>
                        }
                    </span>
                    <hr />
                    <div style={{height: '200px', overflowY: 'auto'}}>
                        <ListGroup className={"global-client-list-"}>
                            {this.state.clients.map((client, key) => (
                                <ListGroup.Item key={key} onClick={() => this.selectClient(client)}>
                                {client.personal.firstName}&nbsp;{client.personal.lastName}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleSelectClient}>
                        Choose
                    </Button>
                    <Button variant="secondary" onClick={this.handleCloseSelectClient}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

GlobalSelectClient.propTypes = {
    updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, { updateView })(GlobalSelectClient);