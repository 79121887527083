import axios from "axios";
import F from '../js/utils/Functions';

let env = 'dev';

window.endpoint = (service, uri) => {
  let env = 'dev';
  if ( window.location.href.indexOf('3.86.187.36') !== -1 || window.location.href.indexOf('sandbox') !== -1 ) {
    env = 'test';
  } else if ( window.location.href.indexOf('jmmb.com') !== -1 ) {
    env = 'live';
  }

  let key = 'REACT_APP_API_HOST_' + service + '_' + env;
  return process.env[key.toUpperCase()] + '/api' + uri;
};


let path = window.location.href;
if ( env === 'live' ) {
  if (window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
  }
}

window.socketEndpoint = () => {
  let env = 'dev';
  if ( window.location.href.indexOf('3.86.187.36') !== -1 || window.location.href.indexOf('sandbox') !== -1 ) {
    env = 'test';
  } else if ( window.location.href.indexOf('jmmb.com') !== -1 ) {
    env = 'live';
  }

  let key = 'REACT_APP_API_HOST_SOCKET_' + env;
  return process.env[key.toUpperCase()] + '/';
};

Array.prototype.includesAll = function(values) {
  if ( values.length < 1 ) {
    return false;
  }
  if ( this.includes(values[0]) ) {
    if ( values.length === 1 ) return true;
    return this.includesAll(values.slice(1));
  } else {
    return false;
  }
};

Array.prototype.includesSome = function(values) {
  if ( values.length < 1 ) {
    return false;
  }
  if ( this.includes(values[0]) ) {
    return true;
  } else {
    return this.includesSome(values.slice(1));
  }
};

window.hasAccess = function(globalState, values) {
  // Override
  return true;
  if (!Array.isArray(values)) {
    values = values.split(',').filter(item => item.trim());
  }
  let permissions = F.reducePath(globalState, 'permissions', []);
  permissions = permissions.concat((localStorage.getItem('userPermissions') || '').csvToArray() || []);
  if ( permissions ) {
    if (!Array.isArray(permissions)) {
      permissions = permissions.csvToArray();
    }
  }
  if ( permissions.includes('fullPower') ) return true;
  return permissions.includesAll(values);
};

String.prototype.csvToArray = function () {
  return this.split(',').filter(item => item.trim());
};

Array.prototype.hasAnyAccess = function(values) {
  // Override
  return true;
  if ( !Array.isArray(values ) ) {
    values = values.split(',').filter(item => item.trim());
  }
  return this.includesSome(values);
};

Array.prototype.cherryPick = function(field) {
  let values = [];
  for ( let a = 0; a < this.length; a++ ) {
    values.push(this[a][field]);
  }
  return values;
};

export const LOGIN_URL = window.endpoint('demo', "/auth/login");
export const REGISTER_URL = window.endpoint('demo', "/auth/register");
export const REQUEST_PASSWORD_URL = window.endpoint('demo', "/auth/forgot-password");

export const ME_URL = window.endpoint('demo', "/auth/me");

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  let userData = axios.get(ME_URL);
  userData.then(result => {
    localStorage.setItem('userClientId', result.data.clientId);
  });
  return userData;
}
