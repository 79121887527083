import assetTypes from '../../../pages/home/includes/AssetTypes';
import A from '../../../js/math/Arithmetic';

const initialState = {
    bankAccounts: [],
};

export default function (state = initialState, action) {

    let initList = {};
    let arrayFields = [];

    for (let i = 0; i < assetTypes.length; i++) {
        arrayFields.push(assetTypes[i].category);
        initList[assetTypes[i].category] = [];
    }

    for ( let i = 0; i < arrayFields.length; i++ ) {
        initList[arrayFields[i]] = [];
    }

    if ( !action.payload) return state.financials || initList;
    if ( action.payload.masterPage !== 'clients' ) return state.financials;

    if ( action.payload.classification !== 'financials') return state.financials || initList;

    let s = {...state};

    if ( action.type === 'SET_CLIENT_FINANCIALS' ) {
        s.financials = {...s.financials, ...action.payload.data};
    }

    // Calculate income data

    if ( true ) {
        let netSalary = 0;
        netSalary = A.sub(A.add([s.financials.incomeBasicSalary,
            s.financials.incomeHousingAllowance,
            s.financials.incomeOtherAllowances,
        ]), s.financials.incomeDeductions);
        s.financials.netSalary = netSalary;
        let netSalaryCoapplicant = 0;
        netSalaryCoapplicant = A.sub(A.add([s.financials.incomeBasicSalaryCoapplicant,
            s.financials.incomeHousingAllowanceCoapplicant,
            s.financials.incomeOtherAllowancesCoapplicant,
        ]), s.financials.incomeDeductionsCoapplicant);
        s.financials.netSalaryCoapplicant = netSalaryCoapplicant;
        s.financials.totalIncome = A.add([netSalary, netSalaryCoapplicant]);
    }

    // Calculate expenditure

    if ( true ) {
        let expenditureFields= [
            'rent',
            'bond',
            'ratesAndTaxes',
            'waterAndLight',
            'maintenance',
            'vehicleInstalment',
            'vehicleUpkeep',
            'transport',
            'insurance',
            'otherInstalments',
            'groceries',
            'clothing',
            'domesticWages',
            'education',
            'entertainment',
            'otherHousekeeping',
        ];
        let netExpenditure = 0, netExpenditureCoapplicant = 0;
        for ( let f = 0; f <= expenditureFields.length; f++ ) {
            netExpenditure = A.add([netExpenditure, s.financials[expenditureFields[f]]]);
            netExpenditureCoapplicant = A.add([netExpenditureCoapplicant, s.financials[expenditureFields[f] + 'Coapplicant']]);
        }

        s.financials.netExpenditure = netExpenditure;
        s.financials.netExpenditureCoapplicant = netExpenditureCoapplicant;

        let totalNetExpenditure = A.add([netExpenditure, netExpenditureCoapplicant]);

        let netSurplus = A.sub(s.financials.totalIncome, totalNetExpenditure);
        s.financials.netSurplus = netSurplus;
    }

    // Calculate asset and liabilities totals and net worth value

    if ( true ) {

        let totalAssetValue = 0;
        let totalLiabilityValue = 0;

        for (let i = 0; i < assetTypes.length; i++) {
            let category = assetTypes[i].category,
                valueField = assetTypes[i].valueField,
                totalField = assetTypes[i].totalField,
                categorisation = assetTypes[i].categorisation,
                list = s.financials[category] || [],
                subTotal = 0;

            for (let j = 0; j < list.length; j++) {
                let value = A.add([0, list[j][valueField]]);
                if (categorisation === 'asset') {
                    totalAssetValue = A.add([totalAssetValue, value]);
                } else if (categorisation === 'liability') {
                    totalLiabilityValue = A.add([totalLiabilityValue, value]);
                }
                subTotal = A.add([subTotal, value]);
            }
            s.financials[totalField] = subTotal;
        }

        s.financials.totalAssets = totalAssetValue;
        s.financials.totalNetLiabilities = totalLiabilityValue;
        s.financials.netWorth = A.sub(totalAssetValue, totalLiabilityValue);

    }

    return s.financials;
};
