import React, {Fragment, useEffect} from 'react';
import F from "./Functions";
import moment from "moment";
import {setPermissions} from '../actions';
import {connect} from 'react-redux';

import { w3cwebsocket as W3CWebSocket } from "websocket";
import PropTypes from "prop-types";

const AuthSocket = props => {

    /*const socket = new W3CWebSocket('ws://' + window.socketEndpoint());

    useEffect( () => {
        socket.onopen = () => {
            let perms = F.reducePath(props.globalState, 'globalState.permissions', []);
            let authTimestamp = F.reducePath(props.globalState, 'globalState.authTimestamp', moment());
            let diff = moment().diff(authTimestamp, 'seconds');
            if ( !perms.length || diff > 30 ) {
                console.log('Socket connection established with JMMB Sockets.');
                let data = {
                    userId: props.auth.user.id,
                    accessToken: props.auth.user.accessToken,
                    type: "getPrivileges",
                };
                socket.send(JSON.stringify(data));
            }
        };
        socket.onmessage = (message) => {
            if ( message.data ) {
                let messageData = {};
                try {
                    messageData = JSON.parse(message.data);
                } catch(error){}
                if ( messageData.permissions ) {
                    localStorage.setItem('userPermissions', messageData.permissions);
                    props.setPermissions({data: {permissions: messageData.permissions, authTimestamp: moment()}});
                }
                if ( messageData.userName ) {
                    localStorage.setItem('userName', messageData.userName);
                }
                if ( messageData.userEmail ) {
                    localStorage.setItem('userEmail', messageData.userEmail);
                }
            }
        };
    });*/

    return (
        <></>
    );

};

AuthSocket.propTypes = {
    setPermissions: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    globalState: state.globalState
});

export default connect(mapStateToProps, {setPermissions})(AuthSocket);