import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setTotalQualifyingAmount, updateCalculations, updateView } from '../../../js/actions';
import { CurrencyText, PercentageText, DecimalInfoField, MoneyInfoField } from '../../../js/ui/FieldTypes';

class TotalQualifyingAmount extends Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.updateCalculations({data: {masterPage: 'origination'}});
  }

  render() {
    return (
	    <div>

			<div id="totalQualifyingAmount">

				<MoneyInfoField
					label="Net Surplus (Available for Home Loan)"
					value={this.props.qualifyingAmount.netSurplusAvailableForHomeLoan}
					className="strong-summary"
				/>

				<DecimalInfoField
					label="Loan Term (years):"
					value={this.props.totalQualifyingAmount.loanTerm}
				/>

				<DecimalInfoField
					label="Interest Rate:"
					value={this.props.totalQualifyingAmount.interestRate}
				/>

				<MoneyInfoField
					label="Loan Qualifying Amount"
					value={this.props.totalQualifyingAmount.loanQualifyingAmount}
					className="strong-summary"
				/>

				<MoneyInfoField
					label="Recommended Amount:"
					value={this.props.totalQualifyingAmount.recommendedAmount}
					className="strong-summary"
				/>

			</div>

		</div>
	)
  }
}

TotalQualifyingAmount.propTypes = {
	setTotalQualifyingAmount: PropTypes.func.isRequired,
	updateCalculations: PropTypes.func.isRequired,
	updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	totalQualifyingAmount: state.origination.totalQualifyingAmount,
	qualifyingAmount: state.origination.qualifyingAmount,
});

export default connect(mapStateToProps, { setTotalQualifyingAmount, updateCalculations, updateView })(TotalQualifyingAmount);