import React, {Fragment, useEffect} from 'react';
import F from "./Functions";
import Alert from '@material-ui/lab/Alert';
import moment from "moment";
import {setPermissions} from '../actions';
import {connect} from 'react-redux';
import translations from '../utils/translations';

import PropTypes from "prop-types";

const Translate = props => {

    useEffect( () => {

    });

    let language = 'es';
    let trans = translations[language] || {};
    let translatedText = trans[props.source];

    return (
        <>
            {localStorage.getItem('userCountryCode') === 'do' && <>{translatedText || props.source}</>}
            {localStorage.getItem('userCountryCode') !== 'do' && <>{props.source}</>}
        </>
    );

};

export default Translate;
