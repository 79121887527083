import A from '../../../js/math/Arithmetic';

const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload) return state.marital || {};
    if ( action.payload.masterPage !== 'clients' ) return state.marital;

    if ( action.payload.classification !== 'marital') return state.marital || {};

    let s = {...state};

    if ( action.type === 'SET_MARITAL' ) {
        s.marital = {...s.marital, ...action.payload.data};
        s.marital.dependentsTotal = A.add([
            s.marital.dependentsChildren,
            s.marital.dependentsAdoptedChildren,
            s.marital.dependentsOther,
        ]);
    }

    return s.marital;
};
