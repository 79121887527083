import axios from "axios";
import * as routerHelpers from "../../router/RouterHelpers";

const post = async args => {
    let result = await request('POST', args);
    return result;
};

const get = async args => {
    let result = await request('GET', args);
    return result;
};

const request = async (method, args) => {
    let {url, absoluteUrl, service, body, headers, mode} = args;
    headers = headers || [];
    service = service || 'demo';
    url = !absoluteUrl ? url : absoluteUrl;
    body = body || {};
    let userId = localStorage.getItem('userId');
    let accessToken = localStorage.getItem('accessToken');
    let timezoneOffset = new Date().getTimezoneOffset();
    headers = {...headers, userId, accessToken, timezoneOffset};
    if ( !method ) method = 'post';
    let postData = body;

    if ( mode === 'b' ) {
        body.append('method', method);
        body.append('service', service);
        body.append('url', url);
        body.append('mode', 'binary');
        postData = body;
    } else {
        body['key'] = 'value';
    }

    let result = {data: {}};
    await axios.post(window.endpoint(service, url), postData, {headers})
        .then(response => {
            if ( typeof response.data !== 'undefined') {
                result = response.data;
                if ( response.data.notification ) {
                    window.Messenger.broadcast('DisplayNotification', response.data.notification);
                } else if ( response.data.hasErrors ) {
                    window.Messenger.broadcast('DisplayNotification', {type: 'error', message: response.data.errors});
                } else {
                    result.success = true;
                }
                return result;
            }
        })
        .catch(error => {
            if ( ( error || {} ).response ) {
                if ((error.response || {}).status === 403 && !localStorage.getItem('sessionExpiring')) {
                    routerHelpers.forgotLastLocation();
                    localStorage.removeItem('userPermissions');
                    localStorage.removeItem('userName');
                    localStorage.removeItem('userId');
                    localStorage.removeItem('accessToken');
                    localStorage.setItem('sessionExpiring', true);
                    window.alert('Your login session ended. Please log in again.');
                    window.location.assign('/logout');
                }
                return {data: {query: {}}};
            }
        });
    return result;
};

const Http = {
    post,
    get
};

export default Http;
