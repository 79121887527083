import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload ) return Calculator.init(state).general;
    if ( action.payload.masterPage !== 'origination' ) return state.general;

    if ( action.type === 'UPDATE_CALCULATIONS' ) {
        return Calculator.update(state).general;
    }

    if ( action.payload.classification !== 'general') return Calculator.init(state).general;

    let s = {...state};
    s = Calculator.init(s);

    if ( action.type === 'SET_GENERAL' ) {
        s.general = {...s.general, ...action.payload.data};
        s = Calculator.update(s);
    }

    if (action.payload.data.resetState) {
        s = Calculator.reset(s);
    }

    return s.general;
};
