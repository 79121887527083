import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

	if ( !action.payload ) return Calculator.init(state).totalCost;
	if ( action.payload.masterPage !== 'origination' ) return state.totalCost;

	if ( action.type === 'UPDATE_CALCULATIONS' ) {
		return Calculator.update(state).totalCost;
	}

	if ( action.payload.classification !== 'totalCost') return Calculator.init(state).totalCost;

	let s = {...state};
	s = Calculator.init(s);

	if ( action.type === 'SET_TOTAL_COST' ) {
		s.totalCost = {...s.totalCost, ...action.payload.data};
		s = Calculator.update(s);
	}

	if (action.payload.data.resetState) {
		s = Calculator.reset(s);
	}

	return s.totalCost;
};
