const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload) return state.address || {};
    if ( action.payload.masterPage !== 'clients' ) return state.address;

    if ( action.payload.classification !== 'address') return state.address || {};

    let s = {...state};

    if ( action.type === 'SET_ADDRESS' ) {
        s.address = {...s.address, ...action.payload.data};
    }

    return s.address;
};
