import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload ) return Calculator.init(state).financials;
    if ( action.payload.masterPage !== 'origination' ) return state.financials;

    if ( action.type === 'UPDATE_CALCULATIONS' ) {
        return Calculator.update(state).financials;
    }
    if ( action.payload.classification !== 'financials') return Calculator.init(state).financials;

    let s = {...state};
    s = Calculator.init(s);

    if ( action.type === 'SET_FINANCIALS' ) {
        s.financials = {...s.financials, ...action.payload.data};
        s = Calculator.update(s);
    }

    if (action.payload.data.resetState) {
        s = Calculator.reset(s);
    }

    return s.financials;
};
