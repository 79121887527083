export function updateCalculations() {
    return { type: "UPDATE_CALCULATIONS" };
};

export function updateView(payload) {
    return { type: "SET_PAGE_VIEW", payload };
};

// Origination

export function setGeneral(payload) {
    return { type: "SET_GENERAL", payload };
};

export function setFinancials(payload) {
    return { type: "SET_FINANCIALS", payload };
};

export function setSurplus(payload) {
    return { type: "SET_SURPLUS", payload };
};

export function setQualifyingAmount(payload) {
    return { type: "SET_QUALIFYING_AMOUNT", payload };
};

export function setTotalQualifyingAmount(payload) {
    return { type: "SET_TOTAL_QUALIFYING_AMOUNT", payload };
};

export function setPropertyDetails(payload) {
    return { type: "SET_PROPERTY_DETAILS", payload };
};

export function setTotalCost(payload) {
    return { type: "SET_TOTAL_COST", payload };
};

export function setMonthlySplit(payload) {
    return { type: "SET_MONTHLY_SPLIT", payload };
};

export function setSplitDetails(payload) {
    return { type: "SET_SPLIT_DETAILS", payload };
};

export function setPreapprovalLetter(payload) {
    return { type: "SET_PREAPPROVAL_LETTER", payload };
};

export function uploadFiles(payload) {
    return { type: "UPLOAD_FILES", payload };
}

// Clients

export function setPersonal(payload) {
    return { type: "SET_PERSONAL", payload };
};

export function setAddress(payload) {
    return { type: "SET_ADDRESS", payload };
};

export function setClientFinancials(payload) {
    return { type: "SET_CLIENT_FINANCIALS", payload };
};

export function setClientPropertyDetails(payload) {
    return { type: "SET_CLIENT_PROPERTY_DETAILS", payload };
};

export function setInsurance(payload) {
    return { type: "SET_INSURANCE", payload };
};

export function setMarital(payload) {
    return { type: "SET_MARITAL", payload };
};

export function setEmployment(payload) {
    return { type: "SET_EMPLOYMENT", payload };
};

export function setClient(payload) {
    return { type: "SET_CLIENT", payload };
};

export function setNextOfKin(payload) {
    return { type: "SET_NEXT_OF_KIN", payload };
};

export function uploadClientFiles(payload) {
    return { type: "UPLOAD_CLIENT_FILES", payload };
};

export function setConsentForm(payload) {
    return { type: "SET_CONSENT_FORM", payload };
};

export function setPermissions(payload) {
    return { type: "SET_PERMISSIONS", payload };
};
