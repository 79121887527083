const assetTypes = [];

assetTypes.push({
    category: "fixedAssets",
    title: "Assets, Fixed Property﻿",
    totalField: "totalFixedAssets",
    descriptionField: "suburb",
    valueField: "price",
    categorisation: 'asset',
    fields: [
        {
            label: "Suburb",
            name: "suburb",
            type: "text",
        },
        {
            label: "Erf Number",
            name: "erfNumber",
            type: "text",
        },
        {
            label: "Type",
            name: "propertyType",
            type: "text",
        },
        {
            label: "Registered Owner",
            name: "registeredOwner",
            type: "text",
        },
        {
            label: "Date of Transfer",
            name: "dateOfTransfer",
            type: "date",
        },
        {
            label: "Price Paid (in N$)",
            name: "price",
            type: "text",
            format: "currency",
        },
        {
            label: "Market Value (in N$)",
            name: "marketValue",
            type: "text",
            format: "currency",
        }
    ]
});
assetTypes.push({
    category: "otherMovableAssets",
    title: "Other Movable Assets﻿",
    totalField: "totalMovableAssets",
    descriptionField: "name",
    valueField: "value",
    categorisation: 'asset',
    fields: [
        {
            label: "Name",
            name: "name",
            type: "text",
        },
        {
            label: "Value",
            name: "value",
            type: "text",
            format: "currency",
        }
    ]
});
assetTypes.push({
    category: "investmentsShares",
    title: "Investments/Shares, etc in Quoted/Private Institutions﻿",
    totalField: "totalInvestmentsShares",
    descriptionField: "type",
    valueField: "marketValue",
    categorisation: 'asset',
    fields: [
        {
            label: "Type of investment/share",
            name: "type",
            type: "text",
        },
        {
            label: "Where Held",
            name: "whereHeld",
            type: "text",
        },
        {
            label: "Market Value",
            name: "marketValue",
            type: "text",
            format: "currency",
        }
    ]
});
assetTypes.push({
    category: "bankBalances",
    title: "Bank Balances﻿",
    totalField: "totalBankBalances",
    descriptionField: "typeAndNumber",
    valueField: "value",
    categorisation: 'asset',
    fields: [
        {
            label: "Type & number (Savings, Fixed, Current, Deposit)",
            name: "typeAndNumber",
            type: "text",
        },
        {
            label: "Name of Bank",
            name: "nameOfBank",
            type: "text",
        },
        {
            label: "Value",
            name: "value",
            type: "text",
            format: "currency",
        }
    ]
});
assetTypes.push({
    category: "insurancePolicies",
    title: "Life/retirement policies﻿",
    totalField: "totalInsurancePolicies",
    descriptionField: "coverType",
    valueField: "surrenderValue",
    categorisation: 'asset',
    fields: [
        {
            label: "Type & number (Savings, Fixed, Current, Deposit)",
            name: "typeAndNumber",
            type: "text",
        },
        {
            label: "Name of assurance company",
            name: "nameOfCompany",
            type: "text",
        },
        {
            label: "Cover (Life, RA, Endowment)",
            name: "coverType",
            type: "text",
        },
        {
            label: "Policy Number",
            name: "policyNumber",
            type: "text",
        },
        {
            label: "Amount of Cover",
            name: "amountOfCover",
            type: "text",
            format: "currency",
        },
        {
            label: "Maturity Date",
            name: "maturityDate",
            type: "date",
        },
        {
            label: "Surrender Value",
            name: "surrenderValue",
            type: "text",
            format: "currency",
        }
    ]
});
assetTypes.push({
    category: "liabilities",
    title: "Liabilities",
    totalField: "totalLiabilities",
    descriptionField: "type",
    valueField: "outstandingBalance",
    categorisation: 'liability',
    fields: [
        {
            label: "Suburb",
            name: "suburb",
            type: "text",
        },
        {
            label: "Type (Bond, Owing on DoS)",
            name: "type",
            type: "text",
        },
        {
            label: "Erf No",
            name: "erfNumber",
            type: "text",
        },
        {
            label: "Bondholder/seller",
            name: "bondholderOrSeller",
            type: "text",
        },
        {
            label: "Monthly repayment",
            name: "monthlyRepayment",
            type: "text",
            format: "currency",
        },
        {
            label: "Outstanding Balance",
            name: "outstandingBalance",
            type: "text",
        }
    ]
});
assetTypes.push({
    category: "instalments",
    title: "Instalments",
    totalField: "totalInstalments",
    descriptionField: "type",
    valueField: "outstandingBalance",
    categorisation: 'liability',
    fields: [
        {
            label: "Name of Financial Institution",
            name: "nameOfInstitution",
            type: "text",
        },
        {
            label: "Type of Asset",
            name: "type",
            type: "text",
        },
        {
            label: "Monthly repayment",
            name: "monthlyRepayment",
            type: "text",
            format: "currency",
        },
        {
            label: "Final repayment",
            name: "finalRepayment",
            type: "text",
            format: "currency",
        },
        {
            label: "Date",
            name: "date",
            type: "date",
        },
        {
            label: "Outstanding Balance",
            name: "outstandingBalance",
            type: "text",
            format: "currency",
        }
    ]
});
assetTypes.push({
    category: "accountsPayable",
    title: "Accounts Payable",
    totalField: "totalAccountsPayable",
    descriptionField: "account",
    valueField: "outstandingBalance",
    categorisation: 'liability',
    fields: [
        {
            label: "Account",
            name: "account",
            type: "text",
        },
        {
            label: "Outstanding Balance",
            name: "outstandingBalance",
            type: "text",
            format: "currency",
        }
    ]
});
assetTypes.push({
    category: "contingentLiabilities",
    title: "Contingent liabilities (guarantees, suretyships, notarial bonds)",
    totalField: "totalContingentLiabilities",
    descriptionField: "liability",
    valueField: "outstandingBalance",
    categorisation: 'liability',
    fields: [
        {
            label: "Liability",
            name: "liability",
            type: "text",
        },
        {
            label: "Outstanding Balance",
            name: "outstandingBalance",
            type: "text",
            format: "currency",
        }
    ]
});

export default assetTypes;
