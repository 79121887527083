/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../utils/utils";
import * as builder from "../../ducks/builder";

class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();
    const { footerSelfLayoutIsExtended, footerClasses, footerContainerClasses } = this.props;
    return (
        <>
          <div style={{"height": "50px"}}>
            &nbsp;
          </div>
          <div
            className={`kt-footer ${footerClasses} kt-grid__item`}
            id="kt_footer"
          >
            <div className="kt-footer__bottom">
              <div className={`kt-container ${footerContainerClasses}`}>
                <div className="kt-footer__wrapper">
                  <div className="kt-footer__logo">
                    <Link to={"/"}>
                      {/*<img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-mini-md-atlred.png")} />*/}
                    </Link>
                    <div className="kt-footer__copyright">
                      <span style={{float: "right"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{process.env.REACT_APP_BUILD_VERSION}</span>
                    </div>
                  </div>
                  <div className="kt-footer__menu">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }
}

const mapStateToProps = store => ({
  footerSelfLayoutIsExtended: objectPath.get(store.builder.layoutConfig, "footer.self.layout") === "extended",
  footerClasses: builder.selectors.getClasses(store, { path: "footer", toString: true}),
  footerContainerClasses: builder.selectors.getClasses(store, { path: "footer_container", toString: true}),
});

export default connect(mapStateToProps)(Footer);
