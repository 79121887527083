import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setFinancials, updateCalculations, updateView } from '../../../js/actions';
import { CurrencyField, CurrencyText, TextInputField, MoneyInputField, MoneyInfoField } from '../../../js/ui/FieldTypes';
import A from '../../../js/math/Arithmetic';
import {Button, ButtonGroup} from "react-bootstrap";

class Financials extends Component {

    constructor(props) {
        super(props);
        let fields = [
            'grossSalary',
            'currentSubsidy',
            'payrollDeductions',
            'basicSalary',
            'housingSubsidy',
            'grossSalaryCoapplicant',
            'currentSubsidyCoapplicant',
            'payrollDeductionsCoapplicant',
            'basicSalaryCoapplicant',
            'housingSubsidyCoapplicant',
        ], arrayFields = [
            'debtConsolidationDetails',
            'debtConsolidationDetailsCoapplicant',
        ], state = { data: {whichApplicant: 'main', debtConsolidationDetails: [], debtConsolidationDetailsCoapplicant: []}};

        state.currencyFields = 'grossSalary, currentSubsidy, payrollDeductions, netSalary, basicSalary,' +
            ' housingSubsidy, monthlyPayment, totalBalance, ';
        state.currencyFields += 'grossSalaryCoapplicant, currentSubsidyCoapplicant, payrollDeductionsCoapplicant,' +
            ' netSalaryCoapplicant, basicSalaryCoapplicant,' +
            ' housingSubsidyCoapplicant, monthlyPaymentCoapplicant, totalBalanceCoapplicant';

        for(let f = 0; f < fields.length; f++) {
            state.data[fields[f]] = this.props.financials[fields[f]] || '';
        }

        for(let a = 0; a < arrayFields.length; a++) {
            state.data[arrayFields[a]] = this.props.financials[arrayFields[a]] || [];
        }

        state.showDebtConsolidation = false;
        state.showDebtConsolidationCoapplicant = false;

        this.state = state;
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.updateCalculations({data: {masterPage: 'origination'}});
    }

    onChange(e) {
        let format = this.state.currencyFields.indexOf(e.target.name) > -1;
        let value = format ? A.f(e.target.value) : e.target.value;
        let newValues = {[e.target.name]: value};
        this.setState({...this.state, data: {...this.state.data, ...newValues}});
        this.props.setFinancials({data: { [e.target.name]: value }, classification: 'financials', masterPage: 'origination'});
        this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
    }

    switchApplicant(subject) {
        let whichApplicant = this.state.whichApplicant === 'main' ? 'coapplicant' : 'main';
        this.setState({...this.state, whichApplicant: whichApplicant});
        this.props.setFinancials({data: { whichApplicant: whichApplicant }, classification: 'financials', masterPage: 'origination'});
    }

    toggleDebtConsolidationDetails(target) {
        let currentView = this.state[target];
        this.setState({...this.state, [target]: !currentView});
    }

    addDebtConsolidationEntry(collection) {
        let debtList =this.state.data[collection] || [];
        debtList.push({creditorName: '', totalBalance: 0, monthlyPayment: 0});
        this.setState({...this.state, data: {...this.state.data, [collection]: debtList}});
    }

    updateDebtConsolidation(key, e, collection) {
        let format = this.state.currencyFields.indexOf(e.target.name) > -1;
        let value = format ? A.f(e.target.value) : e.target.value;
        let debtInfo = this.state.data[collection] || [];
        debtInfo[key][e.target.name] = value;
        this.setState({...this.state, data: {...this.state.data, [collection]: debtInfo}});
        this.props.setFinancials({data: {[collection]: debtInfo}, classification: 'financials', masterPage: 'origination'});
        this.props.updateCalculations({data: {masterPage: 'origination'}});
        this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
    }

    toggleDebtConsolidationItem(key, collection) {
        let debtInfo = this.state.data[collection] || [];
        if ( debtInfo[key] ) {
            debtInfo[key].isCollapsed = !debtInfo[key].isCollapsed;
            this.setState({...this.state, data: {...this.state.data, [collection]: debtInfo}});
            this.props.setFinancials({data: {[collection]: debtInfo}, classification: 'financials', masterPage: 'origination'});
            this.props.updateCalculations({data: {masterPage: 'origination'}});
        }
    }

    deleteDebtConsolidationItem(key, collection) {
        let debtInfo = this.state.data[collection] || [];
        if ( debtInfo ) {
            debtInfo = debtInfo.splice(key, 1);
            this.setState({...this.state, data: {...this.state.data, [collection]: debtInfo}});
            this.props.setFinancials({data: {[collection]: debtInfo}, classification: 'financials', masterPage: 'origination'});
            this.props.updateCalculations({data: {masterPage: 'origination'}});
            this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
        }
    }

    render() {

        const whichApplicantMode = 'main';

        if ( this.props.client.marital.typeOfApplication === 'joint' && (this.props.financials.whichApplicant !== 'main' && this.props.financials.whichApplicant) ) whichApplicantMode = 'coapplicant';

        return (
            <div>

                <div id="financials">

                    {this.props.client.marital.typeOfApplication === 'joint' &&
                    <div>
                        <ButtonGroup aria-label="Which Applicant">
                            <Button
                                variant="default"
                                onClick={() => this.switchApplicant('main')}>
                                {(this.props.financials.whichApplicant === 'main' || !this.props.financials.whichApplicant) && <span><i className={"fas" +
                                " fa-asterisk" +
                                " text-green"}></i> &nbsp; <strong
                                    className={"text-black"}>Main Applicant</strong></span>
                                }
                                {!(this.props.financials.whichApplicant === 'main' || !this.props.financials.whichApplicant) &&
                                <span>Main Applicant</span>
                                }
                            </Button>
                            <Button
                                variant="default"
                                onClick={() => this.switchApplicant('coapplicant')}>
                                {this.props.financials.whichApplicant === 'coapplicant' && <span><i className={"fas" +
                                " fa-asterisk" +
                                " text-green"}></i> &nbsp; <strong className={"text-black"}>Co-applicant</strong></span>
                                }
                                {this.props.financials.whichApplicant !== 'coapplicant' &&
                                <span>Co-applicant</span>
                                }
                            </Button>
                        </ButtonGroup>
                        <hr/>
                    </div>
                    }

                    {whichApplicantMode !== 'coapplicant' &&
                    <>
                        {/* Main applicant*/}

                        <div className="field-group">

                            <MoneyInputField
                                label="Gross Salary"
                                fieldName="grossSalary"
                                onChange={this.onChange}
                                value={this.state.data.grossSalary}
                            />

                            <MoneyInputField
                                label="Less: Current Subsidy/Allowance"
                                fieldName="currentSubsidy"
                                onChange={this.onChange}
                                value={this.state.data.currentSubsidy}
                            />

                            <MoneyInputField
                                label="Payroll Deductions"
                                fieldName="payrollDeductions"
                                onChange={this.onChange}
                                value={this.state.data.payrollDeductions}
                            />

                            <MoneyInfoField
                                label="Net Salary:"
                                value={this.props.financials.netSalary}
                                className="strong-summary"
                            />

                        </div>

                        <div className="field-group">

                            <MoneyInputField
                                fieldName="basicSalary"
                                label="Basic Salary"
                                onChange={this.onChange}
                                value={this.state.data.basicSalary}
                            />

                            <MoneyInfoField
                                label="Housing Subsidy:"
                                value={this.props.financials.housingSubsidy}
                            />

                            <MoneyInfoField
                                label="Debt Consolidation Total:"
                                value={this.props.financials.debtConsolidationTotal}
                                detailButtonOnClick={() => this.toggleDebtConsolidationDetails('showDebtConsolidation')}
                            />

                            {this.state.showDebtConsolidation &&
                            <div>
                                <div>
                                    <button
                                        className="btn btn-success btn-sm"
                                        onClick={() => this.addDebtConsolidationEntry('debtConsolidationDetails')}
                                    >
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div>
                                    {this.state.data.debtConsolidationDetails.map((debt, key) => (
                                        <div key={key} className="list-item-input-form">
                                            {!debt.isCollapsed &&
                                            <>
                                                <TextInputField
                                                    fieldName="creditorName"
                                                    label="Creditor:"
                                                    onChange={(e) => this.updateDebtConsolidation(key, e, 'debtConsolidationDetails')}
                                                    value={debt.creditorName}
                                                />
                                                <MoneyInputField
                                                    fieldName="monthlyPayment"
                                                    label="Monthly Payment:"
                                                    onChange={(e) => this.updateDebtConsolidation(key, e, 'debtConsolidationDetails')}
                                                    value={debt.monthlyPayment}
                                                />
                                                <MoneyInputField
                                                    fieldName="totalBalance"
                                                    label="Total Balance:"
                                                    onChange={(e) => this.updateDebtConsolidation(key, e, 'debtConsolidationDetails')}
                                                    value={debt.totalBalance}
                                                />
                                                <p>
                                                    <button className="btn btn-sm btn-info"
                                                            onClick={() => this.toggleDebtConsolidationItem(key, 'debtConsolidationDetails')}>
                                                        <i className="fa fa-check"></i>
                                                    </button>
                                                    <>&nbsp;</>
                                                    <button className="btn btn-sm btn-danger"
                                                            onClick={() => this.deleteDebtConsolidationItem(key, 'debtConsolidationDetails')}>
                                                        <i className="fa fa-times"></i>
                                                    </button>
                                                </p>
                                            </>
                                            }
                                            {debt.isCollapsed &&
                                            <>
                                                <strong>{debt.creditorName}</strong> | <CurrencyText
                                                value={debt.monthlyPayment}/> /month
                                                <span className="pull-right">
                                                <button className="btn btn-sm btn-warning"
                                                        onClick={() => this.toggleDebtConsolidationItem(key, 'debtConsolidationDetails')}>
                                                    <i className="fa fa-edit"></i>
                                                </button> <>&nbsp;</>
                                                <button className="btn btn-sm btn-danger"
                                                        onClick={() => this.deleteDebtConsolidationItem(key, 'debtConsolidationDetails')}>
                                                    <i className="fa fa-times"></i>
                                                </button>
                                            </span>
                                            </>
                                            }
                                        </div>

                                    ))}
                                </div>
                            </div>
                            }

                            <MoneyInfoField
                                label="Debt Consolidation Monthly:"
                                value={this.props.financials.debtConsolidationMonthly}
                            />

                            <MoneyInfoField
                                label="Total Other Income:"
                                value={this.props.financials.totalOtherIncome}
                            />

                            <MoneyInfoField
                                label="New Monthly Income:"
                                value={this.props.financials.newMonthlyIncome}
                                className="strong-summary"
                            />

                        </div>

                    </>
                    }

                    {whichApplicantMode === 'coapplicant' &&
                    <>

                        {/* Co-applicant */}

                        <div className="field-group">

                            <MoneyInputField
                                label="Gross Salary"
                                fieldName="grossSalaryCoapplicant"
                                onChange={this.onChange}
                                value={this.state.data.grossSalaryCoapplicant}
                            />

                            <MoneyInputField
                                label="Less: Current Subsidy/Allowance"
                                fieldName="currentSubsidyCoapplicant"
                                onChange={this.onChange}
                                value={this.state.data.currentSubsidyCoapplicant}
                            />

                            <MoneyInputField
                                label="Payroll Deductions"
                                fieldName="payrollDeductionsCoapplicant"
                                onChange={this.onChange}
                                value={this.state.data.payrollDeductionsCoapplicant}
                            />

                            <MoneyInfoField
                                label="Net Salary:"
                                value={this.props.financials.netSalaryCoapplicant}
                                className="strong-summary"
                            />

                        </div>

                        <div className="field-group">

                            <MoneyInputField
                                fieldName="basicSalaryCoapplicant"
                                label="Basic Salary"
                                onChange={this.onChange}
                                value={this.state.data.basicSalaryCoapplicant}
                            />

                            <MoneyInfoField
                                label="Housing Subsidy:"
                                value={this.props.financials.housingSubsidyCoapplicant}
                            />

                            <MoneyInfoField
                                label="Debt Consolidation Total:"
                                value={this.props.financials.debtConsolidationTotalCoapplicant}
                                detailButtonOnClick={() => this.toggleDebtConsolidationDetails('showDebtConsolidationCoapplicant')}
                            />

                            {this.state.showDebtConsolidationCoapplicant &&
                            <div>
                                <div>
                                    <button
                                        className="btn btn-success btn-sm"
                                        onClick={() => this.addDebtConsolidationEntry('debtConsolidationDetailsCoapplicant')}
                                    >
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div>
                                    {this.state.data.debtConsolidationDetailsCoapplicant.map((debt, key) => (
                                        <div key={key} className="list-item-input-form">
                                            {!debt.isCollapsed &&
                                            <>
                                                <TextInputField
                                                    fieldName="creditorName"
                                                    label="Creditor:"
                                                    onChange={(e) => this.updateDebtConsolidation(key, e, 'debtConsolidationDetailsCoapplicant')}
                                                    value={debt.creditorName}
                                                />
                                                <MoneyInputField
                                                    fieldName="monthlyPayment"
                                                    label="Monthly Payment:"
                                                    onChange={(e) => this.updateDebtConsolidation(key, e, 'debtConsolidationDetailsCoapplicant')}
                                                    value={debt.monthlyPayment}
                                                />
                                                <MoneyInputField
                                                    fieldName="totalBalance"
                                                    label="Total Balance:"
                                                    onChange={(e) => this.updateDebtConsolidation(key, e, 'debtConsolidationDetailsCoapplicant')}
                                                    value={debt.totalBalance}
                                                />
                                                <p>
                                                    <button className="btn btn-sm btn-info" onClick={() => this.toggleDebtConsolidationItem(key, 'debtConsolidationDetailsCoapplicant')}>
                                                        <i className="fa fa-check"></i>
                                                    </button> <>&nbsp;</>
                                                    <button className="btn btn-sm btn-danger" onClick={() => this.deleteDebtConsolidationItem(key, 'debtConsolidationDetailsCoapplicant')}>
                                                        <i className="fa fa-times"></i>
                                                    </button>
                                                </p>
                                            </>
                                            }
                                            {debt.isCollapsed &&
                                            <>
                                                <strong>{debt.creditorName}</strong> | <CurrencyText value={debt.monthlyPayment} /> /month
                                                <span className="pull-right">
                                                <button className="btn btn-sm btn-warning" onClick={() => this.toggleDebtConsolidationItem(key, 'debtConsolidationDetailsCoapplicant')}>
                                                    <i className="fa fa-edit"></i>
                                                </button> <>&nbsp;</>
                                                <button className="btn btn-sm btn-danger" onClick={() => this.deleteDebtConsolidationItem(key, 'debtConsolidationDetailsCoapplicant')}>
                                                    <i className="fa fa-times"></i>
                                                </button>
                                            </span>
                                            </>
                                            }
                                        </div>

                                    ))}
                                </div>
                            </div>
                            }

                            <MoneyInfoField
                                label="Debt Consolidation Monthly:"
                                value={this.props.financials.debtConsolidationMonthlyCoapplicant}
                            />

                            <MoneyInfoField
                                label="Total Other Income:"
                                value={this.props.financials.totalOtherIncomeCoapplicant}
                            />

                            <MoneyInfoField
                                label="New Monthly Income:"
                                value={this.props.financials.newMonthlyIncomeCoapplicant}
                                className="strong-summary"
                            />

                        </div>
                    </>
                    }

                </div>

            </div>
        )
    }
}

Financials.propTypes = {
    setFinancials: PropTypes.func.isRequired,
    updateCalculations: PropTypes.func.isRequired,
    updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    financials: state.origination.financials,
});

export default connect(mapStateToProps, { setFinancials, updateCalculations, updateView })(Financials);