import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setSurplus, updateCalculations, updateView } from '../../../js/actions';
import { CurrencyField, TextInputField, MoneyInfoField, MoneyInputField, CurrencyText } from '../../../js/ui/FieldTypes';
import A from '../../../js/math/Arithmetic';
import {Button, ButtonGroup} from "react-bootstrap";

class Surplus extends Component {
  
  constructor(props) {
    super(props);
    let fields = [
    	'bankOpeningBalance',
    	'bankClosingBalance',
		'currentBondInstalment',
		'currentRent',
		'bankCharges',
		'otherOutflows',
		'bankOpeningBalanceCoapplicant',
		'bankClosingBalanceCoapplicant',
		'currentBondInstalmentCoapplicant',
		'currentRentCoapplicant',
		'bankChargesCoapplicant',
		'otherOutflowsCoapplicant',

    ], arrayFields = [
		'investmentSavingsDetails',
		'otherInflowsDetails',
		'bankCommitmentsDetails',
		'investmentSavingsDetailsCoapplicant',
		'otherInflowsDetailsCoapplicant',
		'bankCommitmentsDetailsCoapplicant'
	], state = { data: {whichApplicant: 'main', investmentSavingsDetails: [], otherInflowsDetails: [], bankCommitmentsDetails: [], investmentSavingsDetailsCoapplicant: [], otherInflowsDetailsCoapplicant: [], bankCommitmentsDetailsCoapplicant: []}};

    state.currencyFields = 'bankOpeningBalance, bankClosingBalance, currentBondInstalment, currentRent, bankCharges,' +
		' otherOutflows, amount, bankOpeningBalanceCoapplicant, bankClosingBalanceCoapplicant,' +
		' currentBondInstalmentCoapplicant,' +
		' currentRentCoapplicant,' +
		' bankChargesCoapplicant, otherOutflowsCoapplicant';

    for(let f = 0; f < fields.length; f++) {
    	state.data[fields[f]] = this.props.surplus[fields[f]] || '';
    }

	for(let a = 0; a < arrayFields.length; a++) {
	  state.data[arrayFields[a]] = this.props.surplus[arrayFields[a]] || [];
	}

    state.showInvestmentSavings = state.showInvestmentSavingsCoapplicant = false;
	  state.showOtherInflows = state.showOtherInflowsCoapplicant = false;
	  state.showBankCommitments = state.showBankCommitmentsCoapplicant = false;

    this.state = state;
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.updateCalculations({data: {masterPage: 'origination'}});
  }

  onChange(e) {
	let format = this.state.currencyFields.indexOf(e.target.name) > -1;
	let value = format ? A.f(e.target.value) : e.target.value;
  	let newValues = {[e.target.name]: value};
    this.setState({...this.state, data: {...this.state.data, ...newValues}});
    this.props.setSurplus({data: { [e.target.name]: value }, classification: 'surplus', masterPage: 'origination'});
	  this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
  }

  toggleDetails(target) {
  	let currentView = this.state[target];
  	let newState = {...this.state};
	newState[target] = !currentView;
  	this.setState(newState);
  }

  addListEntry(target) {
  	let placeholder = {description: '', amount: ''};
  	let currentList =this.state.data[target] || [];
  	currentList.push(placeholder);
  	let newData = this.state.data;
  	newData[target] = currentList;
  	this.setState({...this.state, data: newData});
  }

  updateList(target, key, e) {
  	let listInfo = this.state.data[target];
	let format = this.state.currencyFields.indexOf(e.target.name) > -1;
	let value = format ? A.f(e.target.value) : e.target.value;
  	listInfo[key][e.target.name] = value;
  	let newData = this.state.data;
  	newData[target] = listInfo;
	this.setState({...this.state, data: newData});
    let payload = {};
    payload[target] = listInfo;
	this.props.setSurplus({data: payload, classification: 'surplus', masterPage: 'origination'});
	  this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'clients'});
  }

	toggleListItem(target, key) {
		let listInfo = this.state.data[target] || [];
		if ( listInfo[key] ) {
			listInfo[key].isCollapsed = !listInfo[key].isCollapsed;
			this.setState({...this.state, data: {...this.state.data, [target]: listInfo}});
			this.props.setSurplus({data: {[target]: listInfo}, classification: 'surplus', masterPage: 'origination'});
			this.props.updateCalculations({data: {masterPage: 'origination'}});
			this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'clients'});
		}
	}

	deleteListItem(target, key) {
		let listInfo = this.state.data[target] || [];
		if ( listInfo ) {
			listInfo = listInfo.splice(key, 1);
			this.setState({...this.state, data: {...this.state.data, [target]: listInfo}});
			this.props.setSurplus({data: {[target]: listInfo}, classification: 'surplus', masterPage: 'origination'});
			this.props.updateCalculations({data: {masterPage: 'origination'}});
			this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'clients'});
		}
	}

	switchApplicant(subject) {
		let whichApplicant = this.state.whichApplicant === 'main' ? 'coapplicant' : 'main';
		this.setState({...this.state, whichApplicant: whichApplicant});
		this.props.setSurplus({data: { whichApplicant: whichApplicant }, classification: 'surplus', masterPage: 'origination'});
	}

	render() {

		const whichApplicantMode = 'main';

		if ( this.props.client.marital.typeOfApplication === 'joint' && (this.props.financials.whichApplicant !== 'main' && this.props.financials.whichApplicant) ) whichApplicantMode = 'coapplicant';

		return (
			<div>

				<div id="netSurplus">

					{this.props.client.marital.typeOfApplication === 'joint' &&
					<div>
						<ButtonGroup aria-label="Which Applicant">
							<Button
								variant="default"
								onClick={() => this.switchApplicant('main')}>
								{(this.props.surplus.whichApplicant === 'main' || !this.props.surplus.whichApplicant) && <span><i className={"fas" +
								" fa-asterisk" +
								" text-green"}></i> &nbsp; <strong
									className={"text-black"}>Main Applicant</strong></span>
								}
								{!(this.props.surplus.whichApplicant === 'main' || !this.props.surplus.whichApplicant) &&
								<span>Main Applicant</span>
								}
							</Button>
							<Button
								variant="default"
								onClick={() => this.switchApplicant('coapplicant')}>
								{this.props.surplus.whichApplicant === 'coapplicant' && <span><i className={"fas" +
								" fa-asterisk" +
								" text-green"}></i> &nbsp; <strong className={"text-black"}>Co-applicant</strong></span>
								}
								{this.props.surplus.whichApplicant !== 'coapplicant' &&
								<span>Co-applicant</span>
								}
							</Button>
						</ButtonGroup>
						<hr/>
					</div>
					}


					{whichApplicantMode !== 'coapplicant' &&
					<>

						<h3>Bank Accounts</h3>

						<div className="field-group">

							<MoneyInputField
								label="Opening Balance"
								fieldName="bankOpeningBalance"
								onChange={this.onChange}
								value={this.state.data.bankOpeningBalance}
							/>

							<MoneyInputField
								label="Closing Balance"
								fieldName="bankClosingBalance"
								onChange={this.onChange}
								value={this.state.data.bankClosingBalance}
							/>

							<MoneyInfoField
								label="Bank Balance:"
								value={this.props.surplus.bankBalance}
								className="strong-summary"
							/>

						</div>

						<h3>Inflows</h3>

						<div className="field-group">

							<MoneyInfoField
								label="Investment & Savings:"
								value={this.props.surplus.investmentSavingsTotal}
								detailButtonOnClick={() => this.toggleDetails('showInvestmentSavings')}
							/>

							{this.state.showInvestmentSavings && this.state.data.investmentSavingsDetails &&
							<div>
								<div>
									<button
										className="btn btn-success btn-sm"
										onClick={() => this.addListEntry('investmentSavingsDetails')}
									>
										<i className="fa fa-plus"></i>
									</button>
								</div>
								<div>
									{this.state.data.investmentSavingsDetails.map((listItem, key) => (
										<div key={key} className="list-item-input-form">
											{!listItem.isCollapsed &&
											<>
												<TextInputField
													fieldName="description"
													label="Description:"
													onChange={(e) => this.updateList('investmentSavingsDetails', key, e)}
													value={listItem.description}
												/>
												<MoneyInputField
													fieldName="amount"
													label="Amount:"
													onChange={(e) => this.updateList('investmentSavingsDetails', key, e)}
													value={listItem.amount}
												/>
												<p>
													<button className="btn btn-sm btn-info"
															onClick={() => this.toggleListItem('investmentSavingsDetails', key)}>
														<i className="fa fa-check"></i>
													</button>
													<>&nbsp;</>
													<button className="btn btn-sm btn-danger"
															onClick={() => this.deleteListItem('investmentSavingsDetails', key)}>
														<i className="fa fa-times"></i>
													</button>
												</p>
											</>
											}
											{listItem.isCollapsed &&
											<>
												<strong>{listItem.description}</strong> | <CurrencyText
												value={listItem.amount}/>
												<span className="pull-right">
														<button className="btn btn-sm btn-warning"
																onClick={() => this.toggleListItem('investmentSavingsDetails', key)}>
															<i className="fa fa-edit"></i>
														</button> <>&nbsp;</>
														<button className="btn btn-sm btn-danger"
																onClick={() => this.deleteListItem('investmentSavingsDetails', key)}>
															<i className="fa fa-times"></i>
														</button>
													</span>
											</>
											}
										</div>

									))}
								</div>
							</div>
							}

						</div>

						<div className="field-group">

							<MoneyInputField
								label="Current Bond Instalment"
								fieldName="currentBondInstalment"
								onChange={this.onChange}
								value={this.state.data.currentBondInstalment}
							/>

							<MoneyInputField
								label="Current Rent"
								fieldName="currentRent"
								onChange={this.onChange}
								value={this.state.data.currentRent}
							/>

						</div>

						<div className="field-group">

							<MoneyInfoField
								label="Other Inflows"
								value={this.props.surplus.otherInflowsTotal}
								detailButtonOnClick={() => this.toggleDetails('showOtherInflows')}
							/>

							{this.state.showOtherInflows && this.state.data.otherInflowsDetails &&
							<div>
								<div>
									<button
										className="btn btn-success btn-sm"
										onClick={() => this.addListEntry('otherInflowsDetails')}
									>
										<i className="fa fa-plus"></i>
									</button>
								</div>
								<div>
									{this.state.data.otherInflowsDetails.map((listItem, key) => (
										<div key={key} className="list-item-input-form">
											{!listItem.isCollapsed &&
											<>
												<TextInputField
													fieldName="description"
													label="Description:"
													onChange={(e) => this.updateList('otherInflowsDetails', key, e)}
													value={listItem.description}
												/>
												<MoneyInputField
													fieldName="amount"
													label="Amount:"
													onChange={(e) => this.updateList('otherInflowsDetails', key, e)}
													value={listItem.amount}
												/>
												<p>
													<button className="btn btn-sm btn-info"
															onClick={() => this.toggleListItem('otherInflowsDetails', key)}>
														<i className="fa fa-check"></i>
													</button>
													<>&nbsp;</>
													<button className="btn btn-sm btn-danger"
															onClick={() => this.deleteListItem('otherInflowsDetails', key)}>
														<i className="fa fa-times"></i>
													</button>
												</p>
											</>
											}
											{listItem.isCollapsed &&
											<>
												<strong>{listItem.description}</strong> | <CurrencyText
												value={listItem.amount}/>
												<span className="pull-right">
														<button className="btn btn-sm btn-warning"
																onClick={() => this.toggleListItem('otherInflowsDetails', key)}>
															<i className="fa fa-edit"></i>
														</button> <>&nbsp;</>
														<button className="btn btn-sm btn-danger"
																onClick={() => this.deleteListItem('otherInflowsDetails', key)}>
															<i className="fa fa-times"></i>
														</button>
													</span>
											</>
											}
										</div>

									))}
								</div>
							</div>
							}

						</div>

						<div className="field-group">

							<MoneyInfoField
								label="Total Inflows:"
								value={this.props.surplus.totalInflows}
								className="strong-summary"
							/>

						</div>

						<h3>Outflows</h3>

						<div className="field-group">

							<MoneyInputField
								label="Bank Charges"
								fieldName="bankCharges"
								onChange={this.onChange}
								value={this.state.data.bankCharges}
							/>

							<MoneyInfoField
								label="Bank Commitments/Debit/Stop Order"
								value={this.props.surplus.bankCommitmentsTotal}
								detailButtonOnClick={() => this.toggleDetails('showBankCommitments')}
							/>

							{this.state.showBankCommitments && this.state.data.bankCommitmentsDetails &&
							<div>
								<div>
									<button
										className="btn btn-success btn-sm"
										onClick={() => this.addListEntry('bankCommitmentsDetails')}
									>
										<i className="fa fa-plus"></i>
									</button>
								</div>
								<div>
									{this.state.data.bankCommitmentsDetails.map((listItem, key) => (
										<div key={key} className="list-item-input-form">
											{!listItem.isCollapsed &&
											<>
												<TextInputField
													fieldName="description"
													label="Description:"
													onChange={(e) => this.updateList('bankCommitmentsDetails', key, e)}
													value={listItem.description}
												/>
												<MoneyInputField
													fieldName="amount"
													label="Amount:"
													onChange={(e) => this.updateList('bankCommitmentsDetails', key, e)}
													value={listItem.amount}
												/>
												<p>
													<button className="btn btn-sm btn-info"
															onClick={() => this.toggleListItem('bankCommitmentsDetails', key)}>
														<i className="fa fa-check"></i>
													</button>
													<>&nbsp;</>
													<button className="btn btn-sm btn-danger"
															onClick={() => this.deleteListItem('bankCommitmentsDetails', key)}>
														<i className="fa fa-times"></i>
													</button>
												</p>
											</>
											}
											{listItem.isCollapsed &&
											<>
												<strong>{listItem.description}</strong> | <CurrencyText
												value={listItem.amount}/>
												<span className="pull-right">
														<button className="btn btn-sm btn-warning"
																onClick={() => this.toggleListItem('bankCommitmentsDetails', key)}>
															<i className="fa fa-edit"></i>
														</button> <>&nbsp;</>
														<button className="btn btn-sm btn-danger"
																onClick={() => this.deleteListItem('bankCommitmentsDetails', key)}>
															<i className="fa fa-times"></i>
														</button>
													</span>
											</>
											}
										</div>

									))}
								</div>
							</div>
							}

						</div>

						<div className="field-group">

							<MoneyInputField
								label="Other Outflows"
								fieldName="otherOutflows"
								onChange={this.onChange}
								value={this.state.data.otherOutflows}
							/>

						</div>

						<div className="field-group">

							<MoneyInfoField
								label="Total Outflows:"
								value={this.props.surplus.totalOutflows}
								className="strong-summary"
							/>

						</div>

						<div className="field-group">

							<MoneyInfoField
								label="Total Monthly Adjustment:"
								value={this.props.surplus.totalMonthlyAdjustment}
								className="strong-summary"
							/>

						</div>

					</>
					}

					{whichApplicantMode === 'coapplicant' &&
					<>

						<h3>Bank Accounts</h3>

						<div className="field-group">

							<MoneyInputField
								label="Opening Balance"
								fieldName="bankOpeningBalanceCoapplicant"
								onChange={this.onChange}
								value={this.state.data.bankOpeningBalanceCoapplicant}
							/>

							<MoneyInputField
								label="Closing Balance"
								fieldName="bankClosingBalanceCoapplicant"
								onChange={this.onChange}
								value={this.state.data.bankClosingBalanceCoapplicant}
							/>

							<MoneyInfoField
								label="Bank Balance:"
								value={this.props.surplus.bankBalanceCoapplicant}
								className="strong-summary"
							/>

						</div>

						<h3>Inflows</h3>

						<div className="field-group">

							<MoneyInfoField
								label="Investment & Savings:"
								value={this.props.surplus.investmentSavingsTotalCoapplicant}
								detailButtonOnClick={() => this.toggleDetails('showInvestmentSavingsCoapplicant')}
							/>

							{this.state.showInvestmentSavingsCoapplicant && this.state.data.investmentSavingsDetailsCoapplicant &&
							<div>
								<div>
									<button
										className="btn btn-success btn-sm"
										onClick={() => this.addListEntry('investmentSavingsDetailsCoapplicant')}
									>
										<i className="fa fa-plus"></i>
									</button>
								</div>
								<div>
									{this.state.data.investmentSavingsDetailsCoapplicant.map((listItem, key) => (
										<div key={key} className="list-item-input-form">
											{!listItem.isCollapsed &&
											<>
												<TextInputField
													fieldName="description"
													label="Description:"
													onChange={(e) => this.updateList('investmentSavingsDetailsCoapplicant', key, e)}
													value={listItem.description}
												/>
												<MoneyInputField
													fieldName="amount"
													label="Amount:"
													onChange={(e) => this.updateList('investmentSavingsDetailsCoapplicant', key, e)}
													value={listItem.amount}
												/>
												<p>
													<button className="btn btn-sm btn-info"
															onClick={() => this.toggleListItem('investmentSavingsDetailsCoapplicant', key)}>
														<i className="fa fa-check"></i>
													</button>
													<>&nbsp;</>
													<button className="btn btn-sm btn-danger"
															onClick={() => this.deleteListItem('investmentSavingsDetailsCoapplicant', key)}>
														<i className="fa fa-times"></i>
													</button>
												</p>
											</>
											}
											{listItem.isCollapsed &&
											<>
												<strong>{listItem.description}</strong> | <CurrencyText
												value={listItem.amount}/>
												<span className="pull-right">
														<button className="btn btn-sm btn-warning"
																onClick={() => this.toggleListItem('investmentSavingsDetailsCoapplicant', key)}>
															<i className="fa fa-edit"></i>
														</button> <>&nbsp;</>
														<button className="btn btn-sm btn-danger"
																onClick={() => this.deleteListItem('investmentSavingsDetailsCoapplicant', key)}>
															<i className="fa fa-times"></i>
														</button>
													</span>
											</>
											}
										</div>

									))}
								</div>
							</div>
							}

						</div>

						<div className="field-group">

							<MoneyInputField
								label="Current Bond Instalment"
								fieldName="currentBondInstalmentCoapplicant"
								onChange={this.onChange}
								value={this.state.data.currentBondInstalmentCoapplicant}
							/>

							<MoneyInputField
								label="Current Rent"
								fieldName="currentRentCoapplicant"
								onChange={this.onChange}
								value={this.state.data.currentRentCoapplicant}
							/>

						</div>

						<div className="field-group">

							<MoneyInfoField
								label="Other Inflows"
								value={this.props.surplus.otherInflowsTotalCoapplicant}
								detailButtonOnClick={() => this.toggleDetails('showOtherInflowsCoapplicant')}
							/>

							{this.state.showOtherInflowsCoapplicant && this.state.data.otherInflowsDetailsCoapplicant &&
							<div>
								<div>
									<button
										className="btn btn-success btn-sm"
										onClick={() => this.addListEntry('otherInflowsDetailsCoapplicant')}
									>
										<i className="fa fa-plus"></i>
									</button>
								</div>
								<div>
									{this.state.data.otherInflowsDetailsCoapplicant.map((listItem, key) => (
										<div key={key} className="list-item-input-form">
											{!listItem.isCollapsed &&
											<>
												<TextInputField
													fieldName="description"
													label="Description:"
													onChange={(e) => this.updateList('otherInflowsDetailsCoapplicant', key, e)}
													value={listItem.description}
												/>
												<MoneyInputField
													fieldName="amount"
													label="Amount:"
													onChange={(e) => this.updateList('otherInflowsDetailsCoapplicant', key, e)}
													value={listItem.amount}
												/>
												<p>
													<button className="btn btn-sm btn-info"
															onClick={() => this.toggleListItem('otherInflowsDetailsCoapplicant', key)}>
														<i className="fa fa-check"></i>
													</button>
													<>&nbsp;</>
													<button className="btn btn-sm btn-danger"
															onClick={() => this.deleteListItem('otherInflowsDetailsCoapplicant', key)}>
														<i className="fa fa-times"></i>
													</button>
												</p>
											</>
											}
											{listItem.isCollapsed &&
											<>
												<strong>{listItem.description}</strong> | <CurrencyText
												value={listItem.amount}/>
												<span className="pull-right">
														<button className="btn btn-sm btn-warning"
																onClick={() => this.toggleListItem('otherInflowsDetailsCoapplicant', key)}>
															<i className="fa fa-edit"></i>
														</button> <>&nbsp;</>
														<button className="btn btn-sm btn-danger"
																onClick={() => this.deleteListItem('otherInflowsDetailsCoapplicant', key)}>
															<i className="fa fa-times"></i>
														</button>
													</span>
											</>
											}
										</div>

									))}
								</div>
							</div>
							}

						</div>

						<div className="field-group">

							<MoneyInfoField
								label="Total Inflows:"
								value={this.props.surplus.totalInflowsCoapplicant}
								className="strong-summary"
							/>

						</div>

						<h3>Outflows</h3>

						<div className="field-group">

							<MoneyInputField
								label="Bank Charges"
								fieldName="bankChargesCoapplicant"
								onChange={this.onChange}
								value={this.state.data.bankChargesCoapplicant}
							/>

							<MoneyInfoField
								label="Bank Commitments/Debit/Stop Order"
								value={this.props.surplus.bankCommitmentsTotalCoapplicant}
								detailButtonOnClick={() => this.toggleDetails('showBankCommitmentsCoapplicant')}
							/>

							{this.state.showBankCommitmentsCoapplicant && this.state.data.bankCommitmentsDetailsCoapplicant &&
							<div>
								<div>
									<button
										className="btn btn-success btn-sm"
										onClick={() => this.addListEntry('bankCommitmentsDetailsCoapplicant')}
									>
										<i className="fa fa-plus"></i>
									</button>
								</div>
								<div>
									{this.state.data.bankCommitmentsDetailsCoapplicant.map((listItem, key) => (
										<div key={key} className="list-item-input-form">
											{!listItem.isCollapsed &&
											<>
												<TextInputField
													fieldName="description"
													label="Description:"
													onChange={(e) => this.updateList('bankCommitmentsDetailsCoapplicant', key, e)}
													value={listItem.description}
												/>
												<MoneyInputField
													fieldName="amount"
													label="Amount:"
													onChange={(e) => this.updateList('bankCommitmentsDetailsCoapplicant', key, e)}
													value={listItem.amount}
												/>
												<p>
													<button className="btn btn-sm btn-info"
															onClick={() => this.toggleListItem('bankCommitmentsDetailsCoapplicant', key)}>
														<i className="fa fa-check"></i>
													</button>
													<>&nbsp;</>
													<button className="btn btn-sm btn-danger"
															onClick={() => this.deleteListItem('bankCommitmentsDetailsCoapplicant', key)}>
														<i className="fa fa-times"></i>
													</button>
												</p>
											</>
											}
											{listItem.isCollapsed &&
											<>
												<strong>{listItem.description}</strong> | <CurrencyText
												value={listItem.amount}/>
												<span className="pull-right">
														<button className="btn btn-sm btn-warning"
																onClick={() => this.toggleListItem('bankCommitmentsDetailsCoapplicant', key)}>
															<i className="fa fa-edit"></i>
														</button> <>&nbsp;</>
														<button className="btn btn-sm btn-danger"
																onClick={() => this.deleteListItem('bankCommitmentsDetailsCoapplicant', key)}>
															<i className="fa fa-times"></i>
														</button>
													</span>
											</>
											}
										</div>

									))}
								</div>
							</div>
							}

						</div>

						<div className="field-group">

							<MoneyInputField
								label="Other Outflows"
								fieldName="otherOutflowsCoapplicant"
								onChange={this.onChange}
								value={this.state.data.otherOutflowsCoapplicant}
							/>

						</div>

						<div className="field-group">

							<MoneyInfoField
								label="Total Outflows:"
								value={this.props.surplus.totalOutflowsCoapplicant}
								className="strong-summary"
							/>

						</div>

						<div className="field-group">

							<MoneyInfoField
								label="Total Monthly Adjustment:"
								value={this.props.surplus.totalMonthlyAdjustmentCoapplicant}
								className="strong-summary"
							/>

						</div>

					</>
					}

				</div>

			</div>
		)
  	}
}

Surplus.propTypes = {
  setSurplus: PropTypes.func.isRequired,
  updateCalculations: PropTypes.func.isRequired,
	updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	surplus: state.origination.surplus
});

export default connect(mapStateToProps, { setSurplus, updateCalculations, updateView })(Surplus);