import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

	if ( !action.payload ) return Calculator.init(state).totalQualifyingAmount;
	if ( action.payload.masterPage !== 'origination' ) return state.totalQualifyingAmount;

	if ( action.type === 'UPDATE_CALCULATIONS' ) {
		return Calculator.update(state).totalQualifyingAmount;
	}

	if ( action.payload.classification !== 'totalQualifyingAmount') return Calculator.init(state).totalQualifyingAmount;

	let s = {...state};
	s = Calculator.init(s);

	if ( action.type === 'SET_TOTAL_QUALIFYING_AMOUNT' ) {
		s.totalQualifyingAmount = {...s.totalQualifyingAmount, ...action.payload.data};
		s = Calculator.update(s);
	}

	if (action.payload.data.resetState) {
		s = Calculator.reset(s);
	}

	return s.totalQualifyingAmount;
};
