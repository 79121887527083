import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setQualifyingAmount, updateCalculations, updateView } from '../../../js/actions';
import { CurrencyField, MoneyInputField, MoneyInfoField, CurrencyText } from '../../../js/ui/FieldTypes';
import A from '../../../js/math/Arithmetic';

class QualifyingAmount extends Component {
  
  constructor(props) {
    super(props);
    let fields = [
    	'avrilReservation',
    	'fireCoverInsurance',
		'serviceFees',
		'avrilFees',
		'provisionFee',

    ], state = { data: {}};

    state.currencyFields = 'avrilReservation, fireCoverInsurance, serviceFees, avrilFees, provisionFee';

    for(let f = 0; f < fields.length; f++) {
    	state.data[fields[f]] = this.props.qualifyingAmount[fields[f]] || '';
    }

    this.state = state;
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.updateCalculations({data: {masterPage: 'origination'}});
  }

  onChange(e) {
	let format = this.state.currencyFields.indexOf(e.target.name) > -1;
	let value = format ? A.f(e.target.value) : e.target.value;
	let newValues = {[e.target.name]: value};
    this.setState({...this.state, data: {...this.state.data, ...newValues}});
    this.props.setQualifyingAmount({data: { [e.target.name]: value }, classification: 'qualifyingAmount', masterPage: 'origination'});
	  this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
  }

  render() {
    return (
	    <div>

			<div id="qualifyingAmount">

				<div className="field-group">

					<MoneyInfoField
						label="Qualifying Monthly Basic Instalment:"
						value={this.props.qualifyingAmount.qualifyingMonthlyBasicInstalment}
						className="strong-summary"
					/>

				</div>

				<div className="field-group">

					<MoneyInfoField
						label="Adjusted Monthly Income:"
						value={this.props.surplus.totalMonthlyAdjustment}
					/>

					<MoneyInfoField
						label="Individual Contribution:"
						value={this.props.qualifyingAmount.individualContribution}
					/>

				</div>

				<div className="field-group">

					<MoneyInputField
						label="Avril Reservation"
						fieldName="avrilReservation"
						onChange={this.onChange}
						value={this.state.data.avrilReservation}
					/>

					<MoneyInfoField
						label="Adjusted Avril Amount"
						value={this.props.qualifyingAmount.adjustedAvrilReservation}
					/>

					<MoneyInfoField
						label="Net Surplus:"
						value={this.props.qualifyingAmount.netSurplus}
					/>

				</div>

				<div className="field-group">

					<MoneyInputField
						label="Fire Cover Insurance"
						fieldName="fireCoverInsurance"
						onChange={this.onChange}
						value={this.state.data.fireCoverInsurance}
					/>

					<MoneyInputField
						label="Service Fees"
						fieldName="serviceFees"
						onChange={this.onChange}
						value={this.state.data.serviceFees}
					/>

					<MoneyInfoField
						label="Avril Fees:"
						value={this.props.qualifyingAmount.avrilFees}
					/>

					<MoneyInfoField
						label="Provision Fee"
						value={this.props.qualifyingAmount.provisionFee}
					/>

					<MoneyInfoField
						label="Net Surplus Available for Home Loan"
						value={this.props.qualifyingAmount.netSurplusAvailableForHomeLoan}
						className="strong-summary"
					/>

				</div>

			</div>

		</div>
	)
  }
}

QualifyingAmount.propTypes = {
  setQualifyingAmount: PropTypes.func.isRequired,
  updateCalculations: PropTypes.func.isRequired,
	updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	qualifyingAmount: state.origination.qualifyingAmount,
	surplus: state.origination.surplus,
	financials: state.origination.financials,
});

export default connect(mapStateToProps, { setQualifyingAmount, updateCalculations, updateView })(QualifyingAmount);