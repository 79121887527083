import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

	if ( !action.payload ) return Calculator.init(state).surplus;
	if ( action.payload.masterPage !== 'origination' ) return state.surplus;

	if ( action.type === 'UPDATE_CALCULATIONS' ) {
		return Calculator.update(state).surplus;
	}

	if ( action.payload.classification !== 'surplus') return Calculator.init(state).surplus;

	let s = {...state};
	s = Calculator.init(s);

	if ( action.type === 'SET_SURPLUS' ) {
		s.surplus = {...s.surplus, ...action.payload.data};
		s = Calculator.update(s);
	}

	if (action.payload.data.resetState) {
		s = Calculator.reset(s);
	}

	return s.surplus;
};
