import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setSplitDetails, updateCalculations, updateView } from '../../../js/actions';
import { CurrencyField, MoneyInfoField, MoneyInputField, CurrencyText } from '../../../js/ui/FieldTypes';
import A from '../../../js/math/Arithmetic';

class SplitDetails extends Component {
  
  constructor(props) {
    super(props);
    let fields = [
		'totalSubsidy',
		'totalSubsidyCoapplicant',
    ], state = { data: {}, currencyFields: fields.join(', ')};

    for(let f = 0; f < fields.length; f++) {
    	state.data[fields[f]] = this.props.splitDetails[fields[f]] || '';
    }

    this.state = state;
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.updateCalculations({data: {masterPage: 'origination'}});
  }

  onChange(e) {
	let format = this.state.currencyFields.indexOf(e.target.name) > -1;
	let value = format ? A.f(e.target.value) : e.target.value;
	let newValues = {[e.target.name]: value};
    this.setState({...this.state, data: {...this.state.data, ...newValues}});
    this.props.setSplitDetails({data: { [e.target.name]: value }, classification: 'splitDetails', masterPage: 'origination'});
	  this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
  }

  render() {
    return (
	    <div>

			<div id="splitDetails">

				<h3>Main Applicant</h3>

				<div className="field-group">

					<MoneyInfoField
						label="Based on Q/A:"
						value={this.props.splitDetails.monthlyInstalmentQualifyingAmount}
						className="strong-summary"
					/>

					<MoneyInfoField
						label="Based on T/L:"
						value={this.props.totalCost.monthlyInstalmentOnTotalLoan}
						className="strong-summary"
					/>

				</div>

				<div className="field-group">

					<MoneyInputField
						label="Total Subsidy"
						fieldName="totalSubsidy"
						onChange={this.onChange}
						value={this.state.data.totalSubsidy}
					/>

					<MoneyInfoField
						label="Total Loan:"
						value={this.props.totalCost.totalLoan}
					/>

					<MoneyInfoField
						label="Excess Band:"
						value={this.props.splitDetails.excessBand}
					/>

					<MoneyInfoField
						label="Individual Contribution:"
						value={this.props.splitDetails.individualContribution}
					/>

					<MoneyInfoField
						label="Monthly Subsidy:"
						value={this.props.splitDetails.monthlySubsidy}
					/>

				</div>

				{this.props.client.marital.typeOfApplication === 'joint' &&
				<>
					<h3>Co-applicant</h3>

					<div className="field-group">

						<MoneyInfoField
							label="Based on Q/A:"
							value={this.props.splitDetails.monthlyInstalmentQualifyingAmountCoapplicant}
							className="strong-summary"
						/>

						<MoneyInfoField
							label="Based on T/L:"
							value={this.props.splitDetails.monthlyInstalmentTotalLoanCoapplicant}
							className="strong-summary"
						/>

					</div>

					<div className="field-group">

						<MoneyInputField
							label="Total Subsidy"
							fieldName="totalSubsidyCoapplicant"
							onChange={this.onChange}
							value={this.state.data.totalSubsidyCoapplicant}
						/>

						<MoneyInfoField
							label="Total Loan:"
							value={this.props.splitDetails.totalLoanCoapplicant}
						/>

						<MoneyInfoField
							label="Excess Band:"
							value={this.props.splitDetails.excessBandCoapplicant}
						/>

						<MoneyInfoField
							label="Individual Contribution:"
							value={this.props.splitDetails.individualContributionCoapplicant}
						/>

						<MoneyInfoField
							label="Monthly Subsidy:"
							value={this.props.splitDetails.monthlySubsidyCoapplicant}
						/>

					</div>

				</>
				}


			</div>

		</div>
	)
  }
}

SplitDetails.propTypes = {
  setSplitDetails: PropTypes.func.isRequired,
  updateCalculations: PropTypes.func.isRequired,
	updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	splitDetails: state.origination.splitDetails,
	surplus: state.origination.surplus,
	totalCost: state.origination.totalCost,
});

export default connect(mapStateToProps, { setSplitDetails, updateCalculations, updateView })(SplitDetails);