import React, {} from 'react';
import {FormControl, FormCheck, Row, Col, FormLabel} from "react-bootstrap";
import NumberFormat from "react-number-format";
import CurrencyInput from './CurrencyInput';
import MaskedInput from "react-text-mask";
import emailMask from 'text-mask-addons/dist/emailMask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField as MuiTextField} from '@material-ui/core';

const masks = {
    phoneNumber: ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    zipCode: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
};

export class CurrencyText extends React.Component {
    render() {
        let currency = '$', value = this.props.value;
        if ( value < 0 ) {
            currency = '-$';
            value = value * -1;
        }
        let negativeClass = '';

        if ( this.props.value < 0 ) {
            negativeClass = 'negative';
        }
        return (
            <span className={negativeClass}>
                <NumberFormat
                    value={value}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={currency}
                    autoComplete={"off"}
                />
            </span>
        )
    }
}

export class PercentageText extends React.Component {
    render() {
        return (
            <span>{this.props.value}%</span>
        )
    }
}

export class DecimalText extends React.Component {
    render() {
        return (
            <span>{this.props.value}</span>
        )
    }
}

export class IntegerText extends React.Component {
    render() {
        return (
            <span>{this.props.value}</span>
        )
    }
}

export class CurrencyField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <span>
            	<FormControl
                    type="number"
                    className="currency-field"
                    name={this.props.name}
                    onChange={this.props.onChange}
                    value={this.props.value}
                />
            </span>
        )

    }

}

export class RadioField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className="radio">
                <label>
                    <FormCheck
                        type="radio"
                        inline
                        name={this.props.name}
                        onChange={this.props.onChange}
                        checked={this.props.checked}
                    /> {this.props.label}
                </label>
            </div>
        )

    }

}

export class MoneyInputField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let type = this.props.type || "number";
        let label = this.props.label || "";
        let classes = this.props.className || "form-control field-value money-input-field no-border no-outline";
        const moneyField = <NumberFormat
            className={classes}
            name={this.props.fieldName}
            onChange={this.props.onChange}
            value={this.props.value}
            placeholder="$0.00"
            displayType={"input"}
            thousandSeparator={','}
            thousandsGroupStyle={"thousand"}
            isNumericString={true}
            type={"text"}
            decimalSeparator={'.'}
            autoComplete={"off"}
            prefix={"$"}
            autoComplete={"off"}
        />;

        if ( this.props.fieldOnly ) {
            return (
                <>{moneyField}</>
            );
        } else if ( label === '' ) {
            return (
                <Row className="currency-input-field-container">
                    <Col md={12} className="field-input-container">
                        {moneyField}
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="currency-input-field-container">
                    <Col md={6} className="field-label">
                        {this.props.label}
                    </Col>
                    <Col md={6} className="field-input-container">
                        {moneyField}
                    </Col>
                </Row>
            );
        }

    }

}

export class DecimalInputField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let type = this.props.type || "number";
        let label = this.props.label || "";
        const inputField = <NumberFormat
            className="form-control field-value money-input-field no-outline"
            name={this.props.fieldName}
            onChange={this.props.onChange}
            value={this.props.value}
            displayType={"input"}
            thousandSeparator={','}
            thousandsGroupStyle={"thousand"}
            type={"text"}
            decimalSeparator={'.'}
            autoComplete={"off"}
        />;

        return (
            <>
                {inputField}
            </>
        )

        /*if ( label === '' ) {
            return (
                <Row className="currency-input-field-container">
                    <Col md={12} className="field-input-container">
                        {moneyField}
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="currency-input-field-container">
                    <Col md={6} className="field-label">
                        {this.props.label}
                    </Col>
                    <Col md={6} className="field-input-container">
                        {moneyField}
                    </Col>
                </Row>
            );
        }*/

    }

}

export class TextInputField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let type = this.props.type || "text";

        if ( this.props.format === 'currency' ) {

            return (
                <MoneyInputField
                    fieldName={this.props.fieldName}
                    value={this.props.value}
                    label={this.props.label}
                    onChange={this.props.onChange}
                    autoComplete={"off"}
                />
            )

        } else {

            return (
                <Row className="currency-input-field-container">
                    <Col md={6} className="field-label">
                        {this.props.label}
                    </Col>
                    <Col md={6} className="field-input-container text-left">
                        <FormControl
                            type={type}
                            className="field-value"
                            name={this.props.fieldName || this.props.name}
                            onChange={this.props.onChange}
                            value={this.props.value}
                            autoComplete={"off"}
                        />
                    </Col>
                </Row>
            )

        }

    }

}

export class SideMenuItem extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {

        const active = this.props.target && (this.props.pageView || {}).currentView === this.props.target ? ' selectedMenu' : '';

        return (
            <>
                <li aria-haspopup="true" data-ktmenu-submenu-toggle="hover"
                    className={"kt-menu__item kt-menu__item--here aside-menu " + active}
                    data-placement="right">
                    <a href="#pageTop" className="kt-menu__link kt-menu__toggle" href="#!"
                       onClick={this.props.onClick}>
                        <i className={"kt-menu__link-icon " + this.props.icon}></i>
                        <span className="kt-menu__link-text" style={{"whiteSpace": "nowrap"}}>
                            {this.props.label}
                        </span>
                    </a>
                </li>
            </>
        )

    }

}

export class OriginationSummaryItem extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <div className="origination-summary-item">
                <h4 className="origination-summary-item-title">{this.props.label}</h4>
                {this.props.text &&
                <span className="origination-summary-item-text">
                    {this.props.text}
                </span>
                }
                {this.props.value &&
                <span className="origination-summary-item-text">
                    <CurrencyText
                        value={this.props.value}
                    />
                </span>
                }
            </div>
        )

    }

}

export class MoneyInfoField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {

        let detailButton = '';
        if ( this.props.detailButtonOnClick ) {
            detailButton = <button
                                className="btn btn-sm btn-secondary btn-circle"
                                onClick={this.props.detailButtonOnClick}>
                                <i className="fa fa-ellipsis-h"></i>
                            </button>;
        }

        return (
            <Row className={"currency-info-field-container " + this.props.className}>
                <Col md={6} className="field-label">
                    {this.props.label} {detailButton}
                </Col>
                <Col md={6} className="field-value">
                    <CurrencyText
                        value={this.props.value}
                    />
                </Col>
            </Row>
        )

    }

}

export class DecimalInfoField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {

        return (
            <Row className={"currency-info-field-container " + this.props.className}>
                <Col md={6} className="field-label">
                    {this.props.label}
                </Col>
                <Col md={6} className="field-value">
                    <DecimalText
                        value={this.props.value}
                    />
                </Col>
            </Row>
        )

    }

}

export class TextField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <span>
            	<FormControl
                    type="text"
                    className="text-field"
                    name={this.props.name}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    autoComplete={"off"}
                />
            </span>
        )

    }

}

export class Pluralise extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let description = this.props.value === 1 ? this.props.singular : this.props.plural;
        return (
            <>
            	{this.props.value} {description}
            </>
        )

    }

}

export class MaskField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <MaskedInput
                mask={this.props.mask !== 'email' ? ( this.props.mask !== 'decimal' ? masks[this.props.mask] : createNumberMask ) : emailMask}
                name={this.props.name || this.props.name}
                type={this.props.type}
                onChange={this.props.onChange}
                value={this.props.value || ''}
                className={"form-control"}
            />
        )

    }

}

export class TemplateMessage extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
        };
    }

    render() {
        let messages = {
            cantUpdate: <><i className={"fa fa-lock"}></i> &nbsp; You do not have permissions to update anything here.</>,
        };
        return (
            <>
                {messages[this.props.id]}
            </>
        )

    }

}

export class SelectField extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
            value: props.value
        };

        this.onChangeSelect = this.onChangeSelectHandler.bind(this);

    }

    static getDerivedStateFromProps(newProps, oldState) {
        let newState = {value: newProps.value || null};
        return oldState.value === newProps.value ? null : newState;
    }

    componentDidMount() {

    }

    onChangeSelectHandler(e, v) {
        if ( v ) {
            let value = v.value;
            return this.props.onChange({target: {name: this.props.name, value: value}});
        }
    };

    getDefaultValue (items, value) {
        let result = {label: 'Select an option'};
        for ( let l = 0; l < items.length; l++ ) {
            if ( items[l].value == value ) {
                result = items[l];
            }
        }
        return result;
    };

    render() {

        let optionsList = this.props.list || [];

        if ( Array.isArray(optionsList[0]) ) {
            let options = [];
            for ( let o = 0; o < optionsList.length; o++ ) {
                options.push({label: optionsList[o][0], value: optionsList[o][1]});
            }
            optionsList = options;
        }

        optionsList = optionsList.filter(item => !this.props.listFilter ? item : this.props.listFilter(item, this.props.name));
        optionsList.push({label: `Clear Selection`, value: ''});

        let whichLabel = !this.props.htmlLabel ? <label className={"form-label block"}>{this.props.label}</label> : <label className={"form-label block"} dangerouslySetInnerHTML={{__html: this.props.htmlLabel}}></label>;

        const optionItem = optionsList.filter(item => item.value === (this.props.value))[0];
        const displayValue = optionItem ? optionItem.label : '';

        const placeholder = this.props.placeholder || 'Select an option';

        return (
            <span>
                {this.props.showLabel && whichLabel}
                {/*<Select
                    value={this.props.value || ( this.state.value || "" )}
                    onChange={this.props.onChange}
                    name={this.props.name}
                    displayEmpty
                    className={"mt-3 w-100 " + this.props.className}
                    disableUnderline={this.props.disableUnderline}
                >
                  <MenuItem value="">
                      {this.props.placeholderStyle !== false &&
                      <em className={"text-danger"}>
                          {this.props.placeholder &&
                          <>{this.props.placeholder}</>
                          }
                          {!this.props.placeholder &&
                          <>&nbsp;
                          </>
                          }
                      </em>
                      }
                      {this.props.placeholderStyle === false &&
                      <span className={"text-danger"}>
                          {this.props.placeholder &&
                          <>{this.props.placeholder}</>
                          }
                          {!this.props.placeholder &&
                          <>&nbsp;
                          </>
                          }
                      </span>
                      }
                          </MenuItem>
                    {(optionsList || []).map((item, key) => (
                        <MenuItem value={item.value} key={key} disabled={item.disabled}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>*/}
                {!this.props.readOnly && <><Autocomplete
                    options={optionsList}
                    disableClearable={true}
                    getOptionLabel={(option) => option.label}
                    style={{width: '100%', background: '#FFFFFF', marginTop: '10px'}}
                    renderInput={(params) => <MuiTextField readOnly {...params} label="" variant="outlined"/>}
                    onChange={this.onChangeSelect}
                    value={[{label: placeholder, value: null}, ...optionsList.filter(item => this.props.value ? item.value == this.props.value : item.value == this.props.default), {label: placeholder, value: null}][1]}
                    className={this.props.className}
                />{/*I have NO IDEA why I have to wrap the array for values with empty values and cherry pick index
                 1. NO IDEA!!!!! That's the only way it works. I'm gonna stackoverflow it.*/}</>
                }
                {this.props.readOnly && <p className={"text-grey"}>{displayValue}</p>}
            </span>
        )

    }

}
