import axios from 'axios';
import Http from './Http';

const Lists = {

    employmentTypes: () => {
        return [
            {label: '', value: ''},
            {label: 'Permanent Full Time', value: 'Weekly'},
            {label: 'Permanent Part Time', value: 'Every 2 Weeks'},
            {label: 'Contract', value: 'Contract'},
            {label: 'Temporary', value: 'Temporary'},
        ];
    },

    paymentFrequencies: () => {
        return [
            {label: '', value: ''},
            {label: 'Weekly', value: 'Weekly'},
            {label: 'Every 2 weeks', value: 'Every 2 weeks'},
            {label: 'Twice a month', value: 'Twice A Month'},
            {label: 'Monthly', value: 'Monthly'},
        ];
    },

    occupations: () => {
        return [
            {label: '', value: ''},
            {label: 'Business Owner', value: 'Business Owner'},
            {label: 'Civil Servant', value: 'Civil Servant'},
            {label: 'Clerical', value: 'Clerical'},
            {label: 'Commission Agent', value: 'Commission Agent'},
            {label: 'Domestic/Janitor', value: 'Domestic/Janitor'},
            {label: 'Driver/Courier', value: 'Driver/Courier'},
            {label: 'Entertainment', value: 'Entertainment'},
            {label: 'Factory Worker', value: 'Factory Worker'},
            {label: 'Farmer', value: 'Farmer'},
            {label: 'Fisher Man', value: 'Fisher Man'},
            {label: 'Managerial', value: 'Managerial'},
            {label: 'Manual/Labourer', value: 'Manual/Labourer'},
            {label: 'Minibus/Taxi Driver', value: 'Minibus/Taxi Driver'},
            {label: 'Nurse', value: 'Nurse'},
            {label: 'Police/Army/Prison Officer/Fire', value: 'Police/Army/Prison Officer/Fire'},
            {label: 'Professional', value: 'Professional'},
            {label: 'Security', value: 'Security'},
            {label: 'Skilled Trader', value: 'Skilled Trader'},
            {label: 'Supervisor', value: 'Supervisor'},
            {label: 'Taxi Driver', value: 'Taxi Driver'},
            {label: 'Teacher', value: 'Teacher'},
            {label: 'Vendor', value: 'Vendor'},
            {label: 'Waiter/Waitress/Bar', value: 'Waiter/Waitress/Bar'},
            {label: 'Other', value: 'Other'},
        ];
    },

    residentialStatuses: () => {
        return [
            {label: '', value: ''},
            {label: 'Rent', value: 'Rent'},
            {label: 'Live With Parents', value: 'Live With Parents'},
            {label: 'Owner still paying mortgage', value: 'Owner still paying mortgage'},
            {label: 'Owner paid for', value: 'Owner paid for'},
        ];
    },

    yearsResided: () => {
        return [
            {label: '', value: ''},
            {label: 'less than 1', value: 'less than 1'},
            {label: '1', value: '1'},
            {label: '2', value: '2'},
            {label: '3', value: '3'},
            {label: '4', value: '4'},
            {label: '5', value: '5'},
            {label: '6', value: '6'},
            {label: '7', value: '7'},
            {label: '8', value: '8'},
            {label: '9', value: '9'},
            {label: '10 years or more', value: '10 years or more'}
        ];
    },

    loanPayments: () => {
        return [
            {label: '', value: ''},
            {label: 'Cash', value: 'Cash'},
            {label: 'Standing Order', value: 'Standing Order'},
            {label: 'Payroll Deduction', value: 'Payroll Deduction'},
        ];
    },

    citizenship: () => {
        return [
            {label: '', value: ''},
            {label: 'Jamaica', value: 'Jamaica'},
            {label: 'Jamaican work permit', value: 'Jamaican work permit'},
            {label: 'Non Jamaican citizen', value: 'Non Jamaican citizen'},
        ];
    },

    parishes: () => {
        return [
            {label: '', value: ''},
            {label: 'Hanover', value: 'Hanover'},
            {label: 'Saint James', value: 'Saint James'},
            {label: 'Trelawny', value: 'Trelawny'},
            {label: 'Westmoreland', value: 'Westmoreland'},
            {label: 'Saint Elizabeth', value: 'Saint Elizabeth'},
            {label: 'Saint Ann', value: 'Saint Ann'},
            {label: 'Saint Mary', value: 'Saint Mary'},
            {label: 'Manchester', value: 'Manchester'},
            {label: 'Clarendon', value: 'Clarendon'},
            {label: 'Saint Catherine', value: 'Saint Catherine'},
            {label: 'Saint Ann', value: 'Saint Ann'},
            {label: 'Portland', value: 'Portland'},
            {label: 'Saint Andrew', value: 'Saint Andrew'},
            {label: 'Kingston', value: 'Kingston'},
            {label: 'Saint Thomas', value: 'Saint Thomas'},
        ];
    },

    countries: () => {
        return [
            {label: '', value: ''},
            {label: 'Jamaica', value: 'Jamaica'},
            {label: 'Trinidad', value: 'Trinidad'},
            {label: 'Dominican Republic', value: 'Dominican Republic'},
        ];
    },

    regions: () => {
        return [
            {label: '', value: ''},
            {label: 'Erongo', value: 'Erongo'},
            {label: 'Hardap', value: 'Hardap'},
            {label: 'Karas', value: 'Karas'},
            {label: 'Kavango East', value: 'Kavango East'},
            {label: 'Kavango West', value: 'Kavango West'},
            {label: 'Khomas', value: 'Khomas'},
            {label: 'Kunene', value: 'Kunene'},
            {label: 'Ohangwena', value: 'Ohangwena'},
            {label: 'Omaheke', value: 'Omaheke'},
            {label: 'Omusati', value: 'Omusati'},
            {label: 'Oshana', value: 'Oshana'},
            {label: 'Oshikoto', value: 'Oshikoto'},
            {label: 'Otjozondjupa', value: 'Otjozondjupa'},
            {label: 'Zambezi', value: 'Zambezi'}
        ];
    },

    typesOfPostalAddresses: () => {
        return [
            {label: 'P.O. Box', value: 'box'},
            {label: 'P/Bag No.', value: 'bag'},
        ];
    },

    educationLevels: () => {
        return [
            {label: '', value: ''},
            {label: 'Primary', value: 'Primary' },
            {label: 'Secondary', value: 'Secondary' },
            {label: 'University Graduate', value: 'University Graduate' },
            // {label: 'Grade 6 or below', value: 'grade6OrBelow' },
            // {label: 'Grade 9 or below', value: 'grade9OrBelow' },
            // {label: 'Grade 10 ', value: 'grade10' },
            // {label: 'Grade 12', value: 'grade12' },
            // {label: 'Certificate', value: 'certificate' },
            // {label: 'Diploma', value: 'diploma' },
            // {label: 'Degree', value: 'degree' },
            // {label: 'Master', value: 'master' },
            // {label: 'PhD ', value: 'phd' },
        ];
    },

    towns: () => {
        return [
            {
                "label": "Windhoek Office of Exchange",
                "value": "Windhoek Office of Exchange",
                "region": "Khomas"
            },
            {
                "label": "Parcel Depot",
                "value": "Parcel Depot",
                "region": "Khomas"
            },
            {
                "label": "Omeya Kiosk",
                "value": "Omeya Kiosk",
                "region": "Khomas"
            },
            {
                "label": "GAME Kiosk",
                "value": "GAME Kiosk",
                "region": "Khomas"
            },
            {
                "label": "Hybrid Mail",
                "value": "Hybrid Mail",
                "region": "Khomas"
            },
            {
                "label": "Windhoek",
                "value": "Windhoek",
                "region": "Khomas"
            },
            {
                "label": "Wika Service Station Kiosk",
                "value": "Wika Service Station Kiosk",
                "region": "Khomas"
            },
            {
                "label": "Bachbrecht",
                "value": "Bachbrecht",
                "region": "Khomas"
            },
            {
                "label": "Dordabis",
                "value": "Dordabis",
                "region": "Khomas"
            },
            {
                "label": "Eros",
                "value": "Eros",
                "region": "Khomas"
            },
            {
                "label": "Government Office Park Kiosk",
                "value": "Government Office Park Kiosk",
                "region": "Khomas"
            },
            {
                "label": "Khomasdal",
                "value": "Khomasdal",
                "region": "Khomas"
            },
            {
                "label": "Klein Windhoek",
                "value": "Klein Windhoek",
                "region": "Khomas"
            },
            {
                "label": "Wernhill Pick n Pay",
                "value": "Wernhill Pick n Pay",
                "region": "Khomas"
            },
            {
                "label": "Rocky Crest",
                "value": "Rocky Crest",
                "region": "Khomas"
            },
            {
                "label": "Windhoek Central Prison",
                "value": "Windhoek Central Prison",
                "region": "Khomas"
            },
            {
                "label": "Hosea Kutako Airport",
                "value": "Hosea Kutako Airport",
                "region": "Khomas"
            },
            {
                "label": "Ausspannplatz",
                "value": "Ausspannplatz",
                "region": "Khomas"
            },
            {
                "label": "Auas Valley",
                "value": "Auas Valley",
                "region": "Khomas"
            },
            {
                "label": "Kleine Kuppe",
                "value": "Kleine Kuppe",
                "region": "Khomas"
            },
            {
                "label": "Maerua Mall",
                "value": "Maerua Mall",
                "region": "Khomas"
            },
            {
                "label": "Olympia",
                "value": "Olympia",
                "region": "Khomas"
            },
            {
                "label": "Pelican Square",
                "value": "Pelican Square",
                "region": "Khomas"
            },
            {
                "label": "Pionierspark",
                "value": "Pionierspark",
                "region": "Khomas"
            },
            {
                "label": "Pionierspark Motors Kiosk",
                "value": "Pionierspark Motors Kiosk",
                "region": "Khomas"
            },
            {
                "label": "University of Namibia",
                "value": "University of Namibia",
                "region": "Khomas"
            },
            {
                "label": "Craft Center",
                "value": "Craft Center",
                "region": "Khomas"
            },
            {
                "label": "Katutura",
                "value": "Katutura",
                "region": "Khomas"
            },
            {
                "label": "China Town",
                "value": "China Town",
                "region": "Khomas"
            },
            {
                "label": "Goreangab",
                "value": "Goreangab",
                "region": "Khomas"
            },
            {
                "label": "Okuryangava",
                "value": "Okuryangava",
                "region": "Khomas"
            },
            {
                "label": "Otjomuise",
                "value": "Otjomuise",
                "region": "Khomas"
            },
            {
                "label": "Soweto",
                "value": "Soweto",
                "region": "Khomas"
            },
            {
                "label": "Wanaheda",
                "value": "Wanaheda",
                "region": "Khomas"
            },
            {
                "label": "Prosperita",
                "value": "Prosperita",
                "region": "Khomas"
            },
            {
                "label": "Gobabis",
                "value": "Gobabis",
                "region": "Omaheke"
            },
            {
                "label": "Aminius",
                "value": "Aminius",
                "region": "Omaheke"
            },
            {
                "label": "Epukiro",
                "value": "Epukiro",
                "region": "Omaheke"
            },
            {
                "label": "Leonardville",
                "value": "Leonardville",
                "region": "Omaheke"
            },
            {
                "label": "Tallismanus",
                "value": "Tallismanus",
                "region": "Omaheke"
            },
            {
                "label": "Witvlei",
                "value": "Witvlei",
                "region": "Omaheke"
            },
            {
                "label": "Omitara",
                "value": "Omitara",
                "region": "Omaheke"
            },
            {
                "label": "Otjinene",
                "value": "Otjinene",
                "region": "Omaheke"
            },
            {
                "label": "Otjiwarongo",
                "value": "Otjiwarongo",
                "region": "Otjozondjupa"
            },
            {
                "label": "Hochfeld",
                "value": "Hochfeld",
                "region": "Otjozondjupa"
            },
            {
                "label": "Okahandja West",
                "value": "Okahandja West",
                "region": "Otjozondjupa"
            },
            {
                "label": "Okahandja",
                "value": "Okahandja",
                "region": "Otjozondjupa"
            },
            {
                "label": "Okakarara",
                "value": "Okakarara",
                "region": "Otjozondjupa"
            },
            {
                "label": "Okamatapati",
                "value": "Okamatapati",
                "region": "Otjozondjupa"
            },
            {
                "label": "Okondjatu",
                "value": "Okondjatu",
                "region": "Otjozondjupa"
            },
            {
                "label": "Osire",
                "value": "Osire",
                "region": "Otjozondjupa"
            },
            {
                "label": "Kalkfeld",
                "value": "Kalkfeld",
                "region": "Otjozondjupa"
            },
            {
                "label": "Grootfontein",
                "value": "Grootfontein",
                "region": "Otjozondjupa"
            },
            {
                "label": "Tsumkwe",
                "value": "Tsumkwe",
                "region": "Otjozondjupa"
            },
            {
                "label": "Kombat",
                "value": "Kombat",
                "region": "Otjozondjupa"
            },
            {
                "label": "Otavi",
                "value": "Otavi",
                "region": "Otjozondjupa"
            },
            {
                "label": "Swakopmund",
                "value": "Swakopmund",
                "region": "Erongo"
            },
            {
                "label": "Woermann & Brock Centre Kiosk",
                "value": "Woermann & Brock Centre Kiosk",
                "region": "Erongo"
            },
            {
                "label": "Vineta",
                "value": "Vineta",
                "region": "Erongo"
            },
            {
                "label": "Mondesa",
                "value": "Mondesa",
                "region": "Erongo"
            },
            {
                "label": "Henties Bay",
                "value": "Henties Bay",
                "region": "Erongo"
            },
            {
                "label": "Arandis",
                "value": "Arandis",
                "region": "Erongo"
            },
            {
                "label": "Usakos",
                "value": "Usakos",
                "region": "Erongo"
            },
            {
                "label": "Karibib",
                "value": "Karibib",
                "region": "Erongo"
            },
            {
                "label": "Omaruru",
                "value": "Omaruru",
                "region": "Erongo"
            },
            {
                "label": "Uis",
                "value": "Uis",
                "region": "Erongo"
            },
            {
                "label": "Omatjete",
                "value": "Omatjete",
                "region": "Erongo"
            },
            {
                "label": "Okombahe",
                "value": "Okombahe",
                "region": "Erongo"
            },
            {
                "label": "Walvis Bay",
                "value": "Walvis Bay",
                "region": "Erongo"
            },
            {
                "label": "Narraville",
                "value": "Narraville",
                "region": "Erongo"
            },
            {
                "label": "Kuisebmund",
                "value": "Kuisebmund",
                "region": "Erongo"
            },
            {
                "label": "Omuthiya",
                "value": "Omuthiya",
                "region": "Oshikoto"
            },
            {
                "label": "Tsumeb",
                "value": "Tsumeb",
                "region": "Oshikoto"
            },
            {
                "label": "Oshivelo",
                "value": "Oshivelo",
                "region": "Oshikoto"
            },
            {
                "label": "Onandjokwe",
                "value": "Onandjokwe",
                "region": "Oshikoto"
            },
            {
                "label": "Oshigambo",
                "value": "Oshigambo",
                "region": "Oshikoto"
            },
            {
                "label": "Onayena",
                "value": "Onayena",
                "region": "Oshikoto"
            },
            {
                "label": "Okankolo",
                "value": "Okankolo",
                "region": "Oshikoto"
            },
            {
                "label": "Onankali",
                "value": "Onankali",
                "region": "Oshikoto"
            },
            {
                "label": "Omuntele",
                "value": "Omuntele",
                "region": "Oshikoto"
            },
            {
                "label": "Onyaanya",
                "value": "Onyaanya",
                "region": "Oshikoto"
            },
            {
                "label": "Okaukuejo",
                "value": "Okaukuejo",
                "region": "Oshikoto"
            },
            {
                "label": "Oshakati",
                "value": "Oshakati",
                "region": "Oshana"
            },
            {
                "label": "Ondangwa",
                "value": "Ondangwa",
                "region": "Oshana"
            },
            {
                "label": "Oluno",
                "value": "Oluno",
                "region": "Oshana"
            },
            {
                "label": "Oshakati Pick n Pay",
                "value": "Oshakati Pick n Pay",
                "region": "Oshana"
            },
            {
                "label": "Oshakati Spar",
                "value": "Oshakati Spar",
                "region": "Oshana"
            },
            {
                "label": "Ongwediva",
                "value": "Ongwediva",
                "region": "Oshana"
            },
            {
                "label": "Outapi",
                "value": "Outapi",
                "region": "Omusati"
            },
            {
                "label": "Onandjaba",
                "value": "Onandjaba",
                "region": "Omusati"
            },
            {
                "label": "Oshikuku",
                "value": "Oshikuku",
                "region": "Omusati"
            },
            {
                "label": "Onesi",
                "value": "Onesi",
                "region": "Omusati"
            },
            {
                "label": "Onawa",
                "value": "Onawa",
                "region": "Omusati"
            },
            {
                "label": "Ruacana",
                "value": "Ruacana",
                "region": "Omusati"
            },
            {
                "label": "Onaanda",
                "value": "Onaanda",
                "region": "Omusati"
            },
            {
                "label": "Okahao",
                "value": "Okahao",
                "region": "Omusati"
            },
            {
                "label": "Tsandi",
                "value": "Tsandi",
                "region": "Omusati"
            },
            {
                "label": "Ogongo",
                "value": "Ogongo",
                "region": "Omusati"
            },
            {
                "label": "Eenhana",
                "value": "Eenhana",
                "region": "Ohangwena"
            },
            {
                "label": "Ondobe",
                "value": "Ondobe",
                "region": "Ohangwena"
            },
            {
                "label": "Okongo",
                "value": "Okongo",
                "region": "Ohangwena"
            },
            {
                "label": "Ongha",
                "value": "Ongha",
                "region": "Ohangwena"
            },
            {
                "label": "Ohangwena",
                "value": "Ohangwena",
                "region": "Ohangwena"
            },
            {
                "label": "Omungwelume",
                "value": "Omungwelume",
                "region": "Ohangwena"
            },
            {
                "label": "Ongenga",
                "value": "Ongenga",
                "region": "Ohangwena"
            },
            {
                "label": "Oshikango",
                "value": "Oshikango",
                "region": "Ohangwena"
            },
            {
                "label": "Oshikango Savings Bank",
                "value": "Oshikango Savings Bank",
                "region": "Ohangwena"
            },
            {
                "label": "Endola",
                "value": "Endola",
                "region": "Ohangwena"
            },
            {
                "label": "Nkurenkuru",
                "value": "Nkurenkuru",
                "region": "Kavango West"
            },
            {
                "label": "Mpungu",
                "value": "Mpungu",
                "region": "Kavango West"
            },
            {
                "label": "Rundu",
                "value": "Rundu",
                "region": "Kavango East"
            },
            {
                "label": "Divundu",
                "value": "Divundu",
                "region": "Kavango East"
            },
            {
                "label": "Ngweze",
                "value": "Ngweze",
                "region": "Zambezi"
            },
            {
                "label": "Katima Mulilo Kiosk",
                "value": "Katima Mulilo Kiosk",
                "region": "Zambezi"
            },
            {
                "label": "Mayuni",
                "value": "Mayuni",
                "region": "Zambezi"
            },
            {
                "label": "Bukalo",
                "value": "Bukalo",
                "region": "Zambezi"
            },
            {
                "label": "Chincimane",
                "value": "Chincimane",
                "region": "Zambezi"
            },
            {
                "label": "Opuwo",
                "value": "Opuwo",
                "region": "Kunene"
            },
            {
                "label": "Khorixas",
                "value": "Khorixas",
                "region": "Kunene"
            },
            {
                "label": "Fransfontein",
                "value": "Fransfontein",
                "region": "Kunene"
            },
            {
                "label": "Kamanjab",
                "value": "Kamanjab",
                "region": "Kunene"
            },
            {
                "label": "Outjo",
                "value": "Outjo",
                "region": "Kunene"
            },
            {
                "label": "Sesfontein",
                "value": "Sesfontein",
                "region": "Kunene"
            },
            {
                "label": "Mariental",
                "value": "Mariental",
                "region": "Hardap"
            },
            {
                "label": "Kalkrand",
                "value": "Kalkrand",
                "region": "Hardap"
            },
            {
                "label": "Stampriet",
                "value": "Stampriet",
                "region": "Hardap"
            },
            {
                "label": "Aranos",
                "value": "Aranos",
                "region": "Hardap"
            },
            {
                "label": "Gochas",
                "value": "Gochas",
                "region": "Hardap"
            },
            {
                "label": "Gibeon",
                "value": "Gibeon",
                "region": "Hardap"
            },
            {
                "label": "Maltahöhe",
                "value": "Maltahöhe",
                "region": "Hardap"
            },
            {
                "label": "Rehoboth",
                "value": "Rehoboth",
                "region": "Hardap"
            },
            {
                "label": "Klein Aub",
                "value": "Klein Aub",
                "region": "Hardap"
            },
            {
                "label": "Keetmanshoop",
                "value": "Keetmanshoop",
                "region": "Karas"
            },
            {
                "label": "Tses",
                "value": "Tses",
                "region": "Karas"
            },
            {
                "label": "Berseba",
                "value": "Berseba",
                "region": "Karas"
            },
            {
                "label": "Koës",
                "value": "Koës",
                "region": "Karas"
            },
            {
                "label": "Aroab",
                "value": "Aroab",
                "region": "Karas"
            },
            {
                "label": "Bethanie",
                "value": "Bethanie",
                "region": "Karas"
            },
            {
                "label": "Helmeringhausen",
                "value": "Helmeringhausen",
                "region": "Karas"
            },
            {
                "label": "Karasburg",
                "value": "Karasburg",
                "region": "Karas"
            },
            {
                "label": "Grünau",
                "value": "Grünau",
                "region": "Karas"
            },
            {
                "label": "Ariamsvlei",
                "value": "Ariamsvlei",
                "region": "Karas"
            },
            {
                "label": "Warmbad",
                "value": "Warmbad",
                "region": "Karas"
            },
            {
                "label": "Noordoewer",
                "value": "Noordoewer",
                "region": "Karas"
            },
            {
                "label": "Aussenkehr",
                "value": "Aussenkehr",
                "region": "Karas"
            },
            {
                "label": "Rosh Pinah",
                "value": "Rosh Pinah",
                "region": "Karas"
            },
            {
                "label": "Oranjemund",
                "value": "Oranjemund",
                "region": "Karas"
            },
            {
                "label": "Lüderitz",
                "value": "Lüderitz",
                "region": "Karas"
            },
            {
                "label": "Aus",
                "value": "Aus",
                "region": "Karas"
            }
        ];
    },
    documentTypes: async () => {
        let docTypes = [];
        let result = await Http.post({url: '/misc/get-document-types', body: {}});
        if (typeof result.documentTypes !== 'undefined') {
            docTypes = result.documentTypes;
        } else {
            console.log('Nothing found')
        }
        return docTypes;
    },

    branches: () => {
        return [
            {label: 'Windhoek HQ', value: 'WHKHQ', code: 10},
            {label: 'Windhoek No 100', value: 'WHKHQ', code: 11},
            {label: 'Windhoek InformationCenter', value: 'WHKIC', code: 12},
            {label: 'Ondangwa', value: 'OND', code: 20},
            {label: 'Rundu', value: 'RND', code: 30},
            {label: 'Swakopmund', value: 'SWK', code: 40},
            {label: 'Katima Mulilo', value: 'KM', code: 50},
            {label: 'Keetmanshoop', value: 'KT', code: 60},
        ];
    },

    typesOfValuation: () => {
        return [
            {label: 'Valuation On Completion', value: 'onCompletion'},
            {label: 'Valuation As It Is', value: 'asItIs'},
        ];
    },

    typesOfOrganisations: () => {
        return [
            {label: 'Government', value: 'government'},
            {label: 'Parastatal', value: 'parastatal'},
            {label: 'Others', value: 'others'},
        ];
    },

    loanPurposes: () => {
        return [
            {value: "4", label: "Appliance/Furniture Purchase"},
            {value: "2", label: "Education/School Expenses"},
            {value: "11", label: "Equipment Purchase/Repair"},
            {value: "3", label: "Home Improvement"},
            {value: "5", label: "Home Purchase"},
            {value: "12", label: "Investments"},
            {value: "6", label: "Land Purchase"},
            {value: "9", label: "Medical Expenses"},
            {value: "7", label: "Motor Vehicle Purchase"},
            {value: "8", label: "Motor Vehicle Repair"},
            {value: "13", label: "Other"},
            {value: "10", label: "Refinance Existing Debt"},
            {value: "1", label: "Travel/Entertainment"},
        ]
    },

    securityTypes: () => {
        return [
            {value: "006", label: "Bonds"},
            {value: "004", label: "Cash"},
            {value: "007", label: "Commercial Property"},
            {value: "008", label: "Land"},
            {value: "003", label: "Motor Vehicle"},
            {value: "009", label: "Other"},
            {value: "002", label: "Residential Property - Non-Owner Occupied"},
            {value: "001", label: "Residential Property - Owner Occupied"},
            {value: "005", label: "Stocks and Shares"},
            {value: "UNS", label: "Unsecured"},
        ]
    },

    payDates: () => {
        return [
            {label: "14th", value: "14"},
            {label: "25th", value: "25"},
            {label: "28th", value: "28"},
        ]
    },

};

export default Lists;