import React, { Component } from 'react';
import {connect, Provider} from 'react-redux';
import Translate from "../../../js/utils/Translate";

import {
    Form,
    Col,
    Row,
    Container,
    ListGroup,
    ListGroupItem,
    ProgressBar,
    Modal,
    Button,
    FormControl
} from 'react-bootstrap';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import { SideMenuItem } from "../../../js/ui/FieldTypes";
import { updateView } from '../../../js/actions';

import store from '../../../store/store';
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import axios from "axios";
require('../../../js/utils/Messenger');

class ClientAside extends Component {
    constructor(props) {
        super(props);

        this.handleShowCreateClient = this.handleShowCreateClient.bind(this);
        this.handleCloseCreateClient = this.handleCloseCreateClient.bind(this);
        this.handleSaveClient = this.handleSaveClient.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            viewMode: 'personal',
            showCreateClient: false,
            data: {personal: {}, employment: {}}
        };

        if ( window.location.href.indexOf('apply-for-loan') > -1 ) {
            this.state.pages = [
                //'marital', //loan details
                'personal',
                'address',
                'employment',
                //'nextOfKin',
                //'financials',
                'documents',
                'propertyDetails',
                //'insurance', // indemnity
                //'documents',
                //'coapplicant', //verfication
                // 'consentForm',
                // 'origination',
            ];
        } else {
            this.state.pages = [
                // 'marital', //loan details
                'personal',
                'address',
                'employment',
                // 'nextOfKin',
                // 'financials',
                'documents',
                'propertyDetails',
                // 'insurance',
                // 'documents',
                // 'coapplicant', //verification
                // 'consentForm',
                // 'origination',
            ];
        }
        this.state.pageProps = {
            marital:
                {
                    value: "marital",
                    icon: "fas fa-book",
                    label: <Translate source={"Loan Details"} />
                },
            personal:
                {
                    value: "personal",
                    icon: "fas fa-user-edit",
                    label: <Translate source={"Personal Information"} />
                },
            address:
                {
                    value: 'address',
                    icon: "fas fa-address-card",
                    label: <Translate source={"Address Information"} />
                },
            employment:
                {
                    value: 'employment',
                    icon: "fas fa-briefcase",
                    label: <Translate source={"Employment"} />
                },
            nextOfKin:
                {
                    value: 'nextOfKin',
                    icon: "fas fa-users",
                    label: <Translate source={"Contact Person"} />
                },
            financials:
                {
                    value: 'financials',
                    icon: "fas fa-money-bill-alt",
                    label: <Translate source={"Income"} />
                },
            propertyDetails:
                {
                    value: 'propertyDetails',
                    icon: "fas fa-user-friends",
                    label: <Translate source={"References"} />
                },
            insurance:
                {
                    value: 'insurance',
                    icon: "fas fa-gavel",
                    label: <Translate source={"Electronic Indemnity"} />
                },
            documents:
                {
                    value: 'documents',
                    icon: "fas fa-file",
                    label: <Translate source={"Documents"} />
                },
            coapplicant:
                {
                    value: 'coapplicant',
                    icon: "fas fa-lock",
                    label: <Translate source={"Verification"} />
                },
            consentForm:
                {
                    value: 'consentForm',
                    icon: "fas fa-key",
                    label: <Translate source={"Consent Form"} />
                },
            origination:
                {
                    value: "origination",
                    icon: "fas fa-chevron-right",
                    label: "Origination"
                }
        };

    }

    componentDidMount(){
        if( window.location.href.indexOf('/clients/new') > -1 ) {
            this.handleShowCreateClient();
        }
    }

    selectView(target, e){
        // e.preventDefault();
        this.setState({...this.state, viewMode: target});
        this.props.updateView({data: {currentView: target}, classification: 'pageView', masterPage: 'clients'});
        if ( target === 'origination' ) {
            this.goToOrigination();
        }
    }

    handleCloseCreateClient() {
        this.setState({ showCreateClient: false });
    }

    handleShowCreateClient() {
        this.setState({ showCreateClient: true });
    }

    handleSaveClient() {
        let data = {client: this.state.data};
        this.props.updateView({data: {setLoadingState: true, isLoading: true}, classification: 'pageView', masterPage: 'clients'});
        axios.post(window.endpoint('lms', '/create-client'), data)
        .then(result => {
            if (typeof result.data !== 'undefined') {
                this.props.updateView({data: {setDirtyState: true, isPageDirty: false}, classification: 'pageView', masterPage: 'clients'});
            }
            let id = result.data.id;
            window.location.assign('/clients/' + id + '/edit');
            this.setState({ showCreateClient: false });
        });
    }

    goToOrigination() {
        window.Messenger.broadcast('LoadOrigination', {});
    }

    onChange(e) {
        let newState = this.state;
        newState.data.personal[e.target.name] = e.target.value;
        this.setState({...newState});
    }

    onChangeSpecial(e, category) {
        category = category || 'personal';
        let newState = this.state;
        newState.data[category][e.target.name] = e.target.value;
        this.setState({...newState});
    }

    render() {

        const perfectScrollbarOptions = {
            wheelPropagation: false,
            wheelSpeed: 2
        };

        return (
            <Provider store={store}>


                {!this.props.pageView.isLoading &&
                <div className="App">

                    {!this.props.hideMenu &&
                    <ul className="kt-menu__nav ">

                        {this.state.pages.map((page, key) => (
                            <SideMenuItem
                                key={key}
                                onClick={(e) => this.selectView(this.state.pageProps[page].value, e)}
                                target={this.state.pageProps[page].value}
                                pageView={this.props.pageView}
                                icon={this.state.pageProps[page].icon}
                                label={this.state.pageProps[page].label}
                            />
                        ))}

                    </ul>
                    }
                </div>
                }
                {this.props.pageView.isLoading &&
                    <img src={"/media/misc/loading-menu.png"} style={{width: "100%"}} />
                }
            </Provider>
        );
    }
}

ClientAside.propTypes = {
    updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    pageView: state.clients.pageView,
    personal: state.clients.personal,
});

export default connect(mapStateToProps, { updateView })(ClientAside);