module.exports.es = {
  'OPEN ACCOUNT': "ABRIR UNA CUENTA",
  'APPLY FOR A LOAN': 'SOLICITAR UN PRÉSTAMO',
  "Address": "Dirección",
  "Parish": "",
  "City": "Cuidad",
  "State": "Estato",
  "Zip Code": "Codigo Postal",
  "Sector": "El Sector",
  "Country": "Pais",
  "How long have you been living at this address?": "Cuanto tiempo ha vivido en este direccion?",
  "Mailing address if different from residential address": "Direccion postal, si diferente de la anterior",
  "Telephone Numbers": "Numero de telefono",
  "Home": "Hogar",
  "Work": "Trabajo",
  "Mobile": "Movil",
  "Email Address": "Correo electronico",
  "Preferred Method of Contact": "Metedo de contact preferido",
  "Residential": "Residencial",
  "Permanent Address": "La direccion permanente",
  "Loan Details": "Los detalles de prestamo",
  "Personal Information": "Informacion personal",
  "Contact Information": "Informacion de contacto",
  "Employment": "Emplejo",
  "Contact Person": "Persona de contacto",
  "Income": "Ingreso",
  "References": "Referencias",
  "Electronic Indemnity": "",
  "Consent Form": "",
  "Documents": "Los Documentos",
  "Document Type": "Tipo de documento",
  "New Document Type": "Tipo de documento nuevo",
  "Please select document type.": "Por favor seleccione el tipo de documento",
  "Save": "Guarda",
  "Close": "Cierra",
  "Upload a Document": "Sube el documento",
  "File Type": "Tipo de archivo",
  "Size": "Tamano",
  "Edit": "Edita",
  "Delete": "Elimina",
  "Personal Information__1": "Informacion personal",
  "Contact Information__1": "Informacion de contacto",
  "Co-applicant": "Cosolicitante",
  "Marital Info": "Estado civil",
  "Employmen Information": "Estado de emplejo",
  "Contact Person__1": "Persona de contacto",
  "Documents__1": "Los Documentos",
  "References__1": "Las Referencias",
  "Income Details": "Los detalles de ingresos",
  "Electronic Indemnity__1": "",
  "Consent Form__1": "La formulario de consentimiento",
  "Save Changes": "Guarda los cambios",
  "Saved": "Guardo",
  "Back": "Regresa",
  "Next": "Siguiente",
  "One moment please": "Un momento por favor",
  "View": "Vea",
  "Primary Employment Status": "Estado de emplejo primario",
  "Full Time": "Tiempo completo",
  "Part Time": "Medio tiempo",
  "Retired": "Se Retira",
  "Self-employed": "Trabaja por cuenta propria",
  "Student": "El estudiante",
  "Unemployed": "Desempleada",
  "Minor": "El menor",
  "Industry": "La industria",
  "Sector__1": "El sector",
  "Occupation": "La ocupacion",
  "Profession": "El profesion",
  "Job Title": "El titulo profesional",
  "Start Date": "La fecha de inicio",
  "Employer's Name": "Nombre del emplejado",
  "Business name if self-employed": "El Nombre de negocio si trabaja por cuenta propria",
  "Employer's Address": "La direccion del emplejado",
  "Income Range": "La rango del ingreso",
  "e.g.": "por ejemplo",
  "between": "Entre",
  "gross per annum": "Ingreso anual",
  "What is the maximum projected level of activity within a year?": "Cual es el nivel de activida maxima proyectado dentre un ano?",
  "Expected transaction amounts and frequency": "Montos esperados de transacciones y frecuencia",
  "per month": "por mes",
  "etc.": "",
  "Main source(s) of funds": "Principal fuente de fondo",
  "Income__1": "Ingreso",
  "How did you get this money?": "Como obtuvo este dinero?",
  "Source(s) of wealth": "Frente de riqueza",
  "How did you get this money/wealth?": "Como obtuvo este dinero/ riqueza?",
  "Telephone Number of Principal Bankers": "Numero de telefono de los principales banqueras",
  "Branch": "Sucursal",
  "Address of Principal Bankers": "La direccion de las principales banqueras",
  "TO": "",
  "Yes": "Si",
  "No": "No",
  "JMMB GROUP LIMITED and its subsidiaries and affiliated companies": "JMMB GROUP LIMITED y sus subsidiarias y companias afilidias",
  "Authority and Indemnity re Facsimile, Electronic Mail and Verbal Instructions": "Autoridad y indemnizacion de fascimil, correo electronico y instruciones verbales",
  "The term \"JMMB Group\" when used in this document means, collectively, JMMB Group Limited and all of its subsidiaries and affiliated companies (as such terms are defined in theCompanies Act), whether such subsidiaries and affiliated companies are now in existence or are subsequently formed or become affiliated with JMMB Group Limited.": "La terma \"JMMP Group\" cuando usado en este documento, significa, colectivamente, JMMB Group Limited y todos de sus subsidiarias  y companias afilidias (segun los terminos se definen en La Ley de Companias),",
  "I (your client) hereby authorize JMMB Group to act on non-signature bearing instructions which it receives by any of the methods approved by me below (hereinafter referred to as “the Instructions”). In consideration of JMMB Group, any of its officers or agents or otherwise accepting the Instructions, including regarding any of my accounts with you, whether such accounts are individual or joint, or they currently exist or are subsequently opened or operated in my name, I agree:": "",
  "that JMMB Group may accept and act on the Instructions sent by me from time to time and I voluntarily and with full knowledge take and assume any and all risks associated with the Instructions;": "",
  "that whenever the Instructions are received by JMMB Group and purportedly sent by the authorized signer(s) on an account (subject to specific signing instructions on the account, e.g. “any one to sign” or “all to sign”), JMMB Group shall have no obligation to check or verify the authenticity or accuracy of the Instructions or the identity of the sender or confirmer of the Instructions and may act on the Instructions as if they had been duly provided in writing under my signature or given by me in person at one of your offices;": "",
  "to irrevocably authorize JMMB Group to execute the Instructions immediately upon first receipt by any of the methods approved by me below or from time to time (e.g. fax or email), and to debit my/our account(s) with all sums of money whatsoever, interest on money, costs, charges and expenses which JMMB Group may incur or charge as a result of its complying with the Instructions. Where the Instructions are later duplicated or contradicted by instructions bearing signatures, JMMB Group may act on those signature-bearing instructions as well as the previous Instructions sent in accordance with this authorization. If this should occur I acknowledge that JMMB Group shall not bear any loss or damage resulting from acting on both sets of instructions and I hereby agree to indemnify JMMB Group against all losses, liabilities, claims or damages which may arise. JMMB Group in line with good customer service may endeavor to assist me to rectify, however JMMB Group is not obligated to do so. Transactions involving a third party are at the sole discretion of that party to assist in rectifying;": "",
  "that in acting on the Instructions, JMMB Group will at all times be deemed to have acted properly in executing the Instructions and to have completely performed all obligations owed to me, notwithstanding that the Instructions received by JMMB Group purportedly from me may have been initiated or communicated in error or fraudulently, or without or in excess of any required authority and notwithstanding any error or misunderstanding or lack of clarity in the terms of such Instructions. I agree that JMMB Group may execute any Instructions by reference to the account number only, even if the name on the account is also provided (including where there may be a discrepancy between the two). I shall at all times be bound by the Instructions received by JMMB Group and purportedly sent by me from time to time on which JMMB Group may act;": "",
  "that JMMB Group reserves the right, in its absolute discretion, not to act on the Instructions received in whole or in part. Without limiting that absolute power, JMMB Group may refuse to act on the Instructions if JMMB Group believes that the Instructions were fraudulently provided. In its sole and absolute discretion, JMMB Group may put the Instructions on hold pending further enquiry or further confirmation (whether written or otherwise) by me. JMMB Group shall in no event or circumstances be held liable in any respect for any costs or losses suffered from declining to accept or act on the Instructions or putting the Instructions on hold;": "",
  "that notwithstanding the above, JMMB Group is not obligated to carry out such further enquiry or seek or obtain further confirmation, and it may proceed to act in accordance with the Instructions and shall in no event or circumstances be held liable in any respect for any costs or losses suffered from so proceeding;": "",
  "JMMB Group may from time to time change its policies in relation this authorization without notice to me. These policy changes include the types of instructions to which this authorization may or may not apply. Unless otherwise changed by JMMB Group, the policy is that this authorization will not apply to:": "",
  "(i) changing signing instructions on accounts;": "",
  "(ii) adding persons to existing accounts;": "",
  "(iii) applications for cards (including debit and credit cards);": "",
  "(iv) applications for loans, hypothecations and guarantees;": "",
  "to indemnify JMMB Group, its officers, agents and assigns and to keep each of them free and harmless from and against all actions, proceedings, liabilities, claims, damages, diminution in value of property, costs, losses and expenses of any nature whatsoever and howsoever arising out of or in connection with JMMB Group acting on the Instructions in whole or in part sent or having exercised (or failed to exercise) the discretion conferred upon JMMB Group in paragraph 5 above. I irrevocably agree to pay such amounts to JMMB Group that arise pursuant to this paragraph on demand. This paragraph shall survive the termination or invalidity of this authorization or any portion of it;": "",
  "to be jointly and individually liable under this authority and indemnity in respect of any and all joint accounts;": "",
  "that JMMB Group is providing me with an additional contractual service via this authorization, and will hold and enforce its terms on me and any account holder(s) that are currently on or that may be added to my account for which this service will apply. I agree that notice of the terms of this authorization to one joint account holder is deemed to be notice to any/all other joint account holder(s), and all joint account holders will be bound by the terms herein. I undertake to inform all joint account holder(s) of the execution of this authorization and its terms;": "",
  "that I acknowledge that providing the Instructions is for my convenience and in my interest only and at my sole discretion and is not a feature of my account(s) with JMMB Group;": "",
  "that this authorization will operate to terminate and supersede all previous authorities that I gave to JMMB Group to accept non-signature bearing instructions;": "",
  "that this authorization may be terminated by me or by JMMB Group upon ten (10) days’ prior written notice to the other, provided, however, that this authorization may be terminated by JMMB Group immediately without notice in the event JMMB Group deems the security of the procedures hereby established to have been materially compromised or breached; and provided further that termination by JMMB Group or me shall not affect JMMB Group’s rights or release me from my obligations, including without limitation, to indemnify JMMB Group with respect to any Instructions received by JMMB Group prior to termination;": "",
  "JMMB Group may from time to time in writing vary, add to or replace the terms and conditions in this indemnity and unless I terminate this authorization, I will be deemed to consent and agree to each such variation, addition and replacement which shall be binding on me;": "",
  "that this authorization in all respects shall be construed in accordance with and governed by the laws of country where the member company of the JMMB Group is located.": "",
  "Please indicate the method(s) for the transmission of Instructions to JMMB Group which are approved or not approved by you by checking the appropriate box. For each method of transmission you must indicate either Yes or No.": "",
  "Method of Transmission of Instruction": "",
  "1. Facsimile (by way of fax machine using telephone network, hereinafter referred to as “Fax”)": "",
  "2. Electronic mail (by the means of transmitting information electronically between computers via the worldwide web hereinafter referred to as “E-mail”) at the e-mail addresses indicated below:": "",
  "3. Verbally (oral communication by telephone or other means)": "",
  "It being acknowledged and understood by me that verbal instructions may only be provided to the Call Centre Unit or unless otherwise permitted by JMMB Group.": "",
  "Loan Product": "",
  "Personal Loan": "",
  "Business Loan": "",
  "Other Loan": "",
  "Loan Amount": "",
  "Repayment Period": "",
  "Years": "",
  "Months": "",
  "Loan Purpose": "",
  "Security": "",
  "Security Value": "",
  "Name": "",
  "First": "",
  "Middle": "",
  "Last": "",
  "Relationship": "",
  "Contact Number": "",
  "Mr": "",
  "Mrs": "",
  "Miss": "",
  "Dr": "",
  "Name__1": "",
  "Mr__1": "",
  "Mrs__1": "",
  "Miss__1": "",
  "Dr__1": "",
  "First__1": "",
  "Middle__1": "",
  "Last__1": "",
  "Alias": "",
  "if applicable": "",
  "Date of Birth": "",
  "Gender": "",
  "Male": "",
  "Female": "",
  "Marital Status": "",
  "Single": "",
  "Married": "",
  "Separated": "",
  "Common Law": "",
  "Divorced": "",
  "Widowed": "",
  "Nationality": "",
  "Jamaican": "",
  "Trinidadian": "",
  "Dominican": "",
  "Country of Birth": "",
  "Country of Citizenship": "",
  "Are you a citizen or permanent resident of any other country?": "",
  "Are you a National of any other Country?": "",
  "Is Tax paid in any other country?": "",
  "Which country?": "",
  "Name of Referee": "",
  "Contact Number__1": "",
  "Reference Capacity": "",
  "Years known to Referee": "",
  "Are you known to the referee personally?": "",
  "Verification": ""
};