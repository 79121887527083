import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

    if ( !action.payload ) return Calculator.init(state).monthlySplit;
    if ( action.payload.masterPage !== 'origination' ) return state.monthlySplit;

    if ( action.type === 'UPDATE_CALCULATIONS' ) {
        return Calculator.update(state).monthlySplit;
    }

    if ( action.payload.classification !== 'monthlySplit') return Calculator.init(state).monthlySplit;

    let s = {...state};
    s = Calculator.init(s);

    if ( action.type === 'SET_MONTHLY_SPLIT' ) {
        s.monthlySplit = {...s.monthlySplit, ...action.payload.data};
         s = Calculator.update(s);
    }

    if (action.payload.data.resetState) {
        s = Calculator.reset(s);
    }

    return s.monthlySplit;
};
