import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setPropertyDetails, updateCalculations, uploadFiles, updateView } from '../../../js/actions';
import {TextInputField, CurrencyField, MoneyInfoField, MoneyInputField, IntegerText} from '../../../js/ui/FieldTypes';
import { Dropdown } from "react-bootstrap";
import A from '../../../js/math/Arithmetic';
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Button from "reactstrap/lib/Button";

class PropertyDetails extends Component {
  
	constructor(props) {
		super(props);
		let textFields = [
			'erfNumber',
			'extNumber',
			'street',
			'suburb',
			'location',
			'town',
			'region',
			'valuationDistance',
			'numberOfValuations',
		],
		currencyFields = [
			'deedOfSaleAmount',
			'quotationAmount',
			'outstandingBalanceAmount',
			'dosAllocationLetterPlotAmount',
			'valuationRate',
			'valuationTravellingFees',
			'valuationServiceFees',
		], arrayFields = [
			'contractors',
			'deedOfSaleDocuments',
			'quotationDocuments',
			'dosAllocationLetterPlotDocuments',
			'sellersIdDocuments',
			'buildingPlanDocuments',
			'proofOfOwnershipDocuments',
		],
		state = { showDocuments:{}, showUpload: {}, data: {contractors: [], deedOfSaleDocuments: [], quotationDocuments: [], dosAllocationLetterPlotDocuments: [], sellersIdDocuments: [], buildingPlanDocuments: [], proofOfOwnershipDocuments: []}, currencyFields: currencyFields.join(', '), showRenameWindow: false, renameDocument: {}};

		for(let f = 0; f < currencyFields.length; f++) {
			state.data[currencyFields[f]] = this.props.propertyDetails[currencyFields[f]] || '';
		}

		for(let a = 0; a < arrayFields.length; a++) {
		  state.data[arrayFields[a]] = this.props.propertyDetails[arrayFields[a]] || [];
		}

		for(let f = 0; f < textFields.length; f++) {
		  state.data[textFields[f]] = this.props.propertyDetails[textFields[f]] || '';
		}

		this.state = state;
		this.onChange = this.onChange.bind(this);
	};

	componentDidMount() {
	// this.deletePropertyDetails();
	this.props.updateCalculations({data: {masterPage: 'origination'}});
	};

	onChange(e) {
		let format = e.target.name !== 'location' && this.state.currencyFields.indexOf(e.target.name) > -1;
		let value = format ? A.f(e.target.value) : e.target.value;
		let newValues = {[e.target.name]: value};
		this.setState({...this.state, data: {...this.state.data, ...newValues}});
		this.props.setPropertyDetails({data: { [e.target.name]: value }, classification: 'propertyDetails', masterPage: 'origination'});
		this.props.updateCalculations({data: {masterPage: 'origination'}});
		this.dirty();
	};

	dirty = () => {
		this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
	};

	toggleDocuments(category) {
		let currentView = this.state.showDocuments[category];
		let newState = {...this.state};
		newState.showDocuments[category] = !currentView;
		this.setState(newState);
	};

	showDocuments(category) {
		let newState = {...this.state};
		newState.showDocuments[category] = true;
		this.setState(newState);
	};

	toggleUploadDocument(category) {
	  let currentView = this.state.showUpload[category];
	  let newState = {...this.state};
	  newState.showUpload[category] = !currentView;
	  this.setState(newState);
	};

	viewDocument(document, category, key) {
		window.location.assign(window.endpoint('lms', '/public/document/' + document.documentId + '/get'));
	};

	renameDocument = (document, category, key) => {
		document = {...document, ...{key, category}};
		this.setState({...this.state, renameDocument: document, showRenameWindow: true});
	};

	setDocumentRename = e => {
		this.setState({...this.state, renameDocument: {...this.state.renameDocument, [e.target.name]: e.target.value}});
	};

	setRenameWindow = show => {
		this.setState({...this.state, showRenameWindow: show});
	};

	cancelRenameDocument = () => {
		this.setState({...this.state, renameDocument: {}, showRenameWindow: false});
	};

	updateRenameDocument = () => {
		let document = this.state.renameDocument;
		let {category, key} = document;
		let entryList = this.state.data[category + 'Documents'];
		entryList[key] = document;
		delete document['key'];
		delete document['category'];
		this.setState({...this.state, renameDocument: {}, showRenameWindow: false});
		this.props.setPropertyDetails({data: {[category + 'Documents']: entryList}, classification: 'propertyDetails', masterPage: 'clients'});
		this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'clients'});
		this.dirty();
	};

	downloadDocument(document, category, key) {
	  	window.location.assign(window.endpoint('lms', '/public/document/' + document.documentId + '/get'));
	};

	deleteDocument(document, category, key) {
		let entryList = this.state.data[category + 'Documents'] || [];
		entryList.splice(key, 1);
		this.setState({...this.state, [category + 'Documents']: entryList});
		this.props.setPropertyDetails({data: {[category + 'Documents']: entryList}, classification: 'propertyDetails', masterPage: 'clients'});
	};

	async uploadDocument(e, category) {
		await this.props.uploadFiles({data: {files: e.target.files},
								section: 'propertyDetails',
								category: category,
								type: 'list',
								classification: 'uploadFiles', masterPage: 'origination'});
		this.props.updateCalculations({data: {masterPage: 'origination'}});
		this.toggleUploadDocument(category);
	};

  	addContractor() {
	let contractors =this.props.propertyDetails.contractors || [];
	contractors.push({companyProfileDocument:{}, identificationDocument:{}, bankStatementDocument: {}});
	this.setState({...this.state, data: {...this.state.data, contractors: contractors}});
	this.props.setPropertyDetails({contractors: contractors});
  };

  	updateContractors(key, e) {
	let contractors = this.props.propertyDetails.contractors;
	contractors[key][e.target.name] = e.target.value;
	this.setState({...this.state, data: {...this.state.data, contractors: contractors}});
	this.props.setPropertyDetails({data: {contractors: contractors}, classification: 'propertyDetails', masterPage: 'origination'});
	  this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
  };

	toggleContractorViewMode(key) {
		let contractors = this.props.propertyDetails.contractors;
		contractors[key].viewMode = contractors[key].viewMode === 'collapsed' ? 'expanded' : 'collapsed';
		this.setState({...this.state, data: {...this.state.data, contractors: contractors}});
		this.props.setPropertyDetails({data: {contractors: contractors}, classification: 'propertyDetails', masterPage: 'origination'});
	};

	deleteContractor(key) {
		let contractors = [],
			oldList = this.props.propertyDetails.contractors;
		for ( let c = 0; c < oldList.length; c++ ) {
			if (key !== c) {
				contractors.push(oldList[key]);
			}
		}
		this.setState({...this.state, data: {...this.state.data, contractors: contractors}});
		this.props.setPropertyDetails({data: {contractors: contractors}, classification: 'propertyDetails', masterPage: 'origination'});
		this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
	};

  	async uploadSingleDocument(e, args) {
		await this.props.uploadFiles({data: {files: e.target.files},
			section: 'propertyDetails',
			args: args,
			type: 'insert',
			classification: 'propertyDetails', masterPage: 'origination'});
		this.props.updateCalculations({data: {masterPage: 'origination'}});
	};

  	deletePropertyDetails() {

		let newValues = {};
		newValues.deedOfSaleDocuments = [];
		newValues.quotationDocuments = [];
		newValues.outstandingBalanceDocuments = [];
		newValues.dosAllocationLetterPlotDocuments = [];
		newValues.proofOfOwnershipDocuments = [];
		newValues.buildingPlanDocuments = [];
		newValues.sellersIdDocuments = [];

		newValues.deedOfSaleAmount = 0;
		newValues.quotationAmount = 0;
		newValues.outstandingBalanceAmount = 0;

		newValues.erfNumber = '0';
		newValues.extNumber = '0';
		newValues.street = '';
		newValues.suburb = '';
		newValues.location = '';
		newValues.town = '';
		newValues.region = '';

	  newValues.valuationRate = 0;
	  newValues.valuationDistance = 0;

	  this.setState({...this.state, data: {...this.state.data, ...newValues}});
		this.props.setPropertyDetails({data: {...newValues}, classification: 'propertyDetails', masterPage: 'origination'});

		this.props.updateCalculations({data: {masterPage: 'origination'}});

	  this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
	};

	render() {

		const documentRenameWindow = <>
			<Modal
				size={"sm"}
				isOpen={this.state.showRenameWindow}
				toggle={() => this.setRenameWindow(false)}>
				<ModalHeader toggle={() => this.setRenameWindow(false)}>
					Rename Document
				</ModalHeader>
				<ModalBody>
					<div className={"p-20"}>
						<p><strong>Please choose a name for this document.</strong></p>
						<FormGroup>
							<Input
								name={"displayName"}
								onChange={(e) => this.setDocumentRename(e)}
								value={this.state.renameDocument.displayName}
							/>
						</FormGroup>
						<br />
						<FormGroup>
							<Button variant={"primary"} onClick={() => this.updateRenameDocument()}>
								Done
							</Button> &nbsp;
							<Button variant={"danger"} onClick={() => this.cancelRenameDocument()}>
								Cancel
							</Button>
						</FormGroup>
					</div>
				</ModalBody>
			</Modal>
		</>;

		return (
			<div>
				{documentRenameWindow}
				<div id="propertyDetails">

					<h3>Property Details</h3>

					<div className="field-group">

						<TextInputField
							label="Erf Number"
							fieldName="erfNumber"
							onChange={this.onChange}
							value={this.state.data.erfNumber}
						/>

						<TextInputField
							label="Ext Number"
							fieldName="extNumber"
							onChange={this.onChange}
							value={this.state.data.extNumber}
						/>

						<TextInputField
							label="Street"
							fieldName="street"
							onChange={this.onChange}
							value={this.state.data.street}
						/>

						<TextInputField
							label="Suburb"
							fieldName="suburb"
							onChange={this.onChange}
							value={this.state.data.suburb}
						/>

						<TextInputField
							label="Location"
							fieldName="location"
							onChange={this.onChange}
							value={this.state.data.location}
						/>

						<TextInputField
							label="Region"
							fieldName="region"
							onChange={this.onChange}
							value={this.state.data.region}
						/>

						<TextInputField
							label="Town"
							fieldName="town"
							onChange={this.onChange}
							value={this.state.data.town}
						/>

					</div>

					<div className="field-group">

						<h3>Valuation Fees</h3>

						<TextInputField
							type={"number"}
							label="Distance in KM"
							fieldName="valuationDistance"
							onChange={this.onChange}
							value={this.state.data.valuationDistance}
						/>

						<MoneyInputField
							label="Rate"
							fieldName="valuationRate"
							onChange={this.onChange}
							value={this.state.data.valuationRate}
						/>

						<TextInputField
							type={"number"}
							label="Number of Valuation"
							fieldName="numberOfValuations"
							onChange={this.onChange}
							value={this.state.data.numberOfValuations}
						/>

						<MoneyInfoField
							label="Valuation Cost:"
							value={this.props.propertyDetails.valuationTravellingFees}
							className="strong-summary"
						/>

						<MoneyInfoField
							label="Valuation Service Fees:"
							value={this.props.propertyDetails.valuationTravellingServiceFees}
							className="strong-summary"
						/>

					</div>

					<div className="field-group">

						<h3>Documentation</h3>

						{this.props.propertyDetails.showDeedOfSale &&
						<>
							<MoneyInputField
								label="Deed of Sale"
								fieldName="deedOfSaleAmount"
								onChange={this.onChange}
								value={this.state.data.deedOfSaleAmount}
							/>

							<div className="field-details-summary">
								<strong>Deed of Sale</strong> Documents (<IntegerText value={this.props.propertyDetails.deedOfSaleDocuments.length} />) <button className="btn btn-default btn-sm" onClick={() => this.toggleDocuments('deedOfSaleDocuments')} ><i className="fa fa-ellipsis-h"></i></button> <button className="btn btn-secondary btn-sm" onClick={() => this.toggleUploadDocument('deedOfSaleDocuments')} ><i className="fa fa-plus"></i></button>
							</div>
							{this.state.showUpload.deedOfSaleDocuments &&
							<div>
								<input type="file" onChange={(e) => this.uploadDocument(e, 'deedOfSaleDocuments')} />
							</div>}
							{this.state.showDocuments.deedOfSaleDocuments &&
							<div>
								<ul>
									{this.props.propertyDetails.deedOfSaleDocuments.map((document, key) => (
									<li key={key}>
										{document.displayName} &nbsp; <a href={window.endpoint('lms', '/public/document/' + document.documentId + '/get')}><i className={"fa fa-external-link-alt"}></i> </a>
										<Dropdown>
											<Dropdown.Toggle variant="success" id="dropdown-basic">

											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item onClick={() => this.viewDocument(document, 'deedOfSale', key)}>View</Dropdown.Item>
												<Dropdown.Item onClick={() => this.renameDocument(document, 'deedOfSale', key)}>Rename</Dropdown.Item>
												<Dropdown.Item onClick={() => this.downloadDocument(document, 'deedOfSale', key)}>Download</Dropdown.Item>
												<Dropdown.Item onClick={() => this.downloadDocument(document, 'deedOfSale', key)} onClick={() => this.deleteDocument(document, 'deedOfSale', key)}>Delete</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</li>
									))}
								</ul>
							</div>
							}
						</>
						}

					</div>
					<div className="field-group">

						{this.props.propertyDetails.showQuotation &&
						<>
							<MoneyInputField
								label="Quotation"
								fieldName="quotationAmount"
								onChange={this.onChange}
								value={this.state.data.quotationAmount}
							/>

							<div className="field-details-summary">
								<strong>Quotation</strong> Documents (<IntegerText value={this.props.propertyDetails.quotationDocuments.length} />) <button className="btn btn-default btn-sm" onClick={() => this.toggleDocuments('quotationDocuments')} ><i className="fa fa-ellipsis-h"></i></button> <button className="btn btn-secondary btn-sm" onClick={() => this.toggleUploadDocument('quotationDocuments')} ><i className="fa fa-plus"></i></button>
							</div>
							{this.state.showUpload.quotationDocuments &&
							<div>
								<input type="file" onChange={(e) => this.uploadDocument(e, 'quotationDocuments')} />
							</div>}
							{this.state.showDocuments.quotationDocuments &&
							<div>
								<ul>
									{this.props.propertyDetails.quotationDocuments.map((document, key) => (
										<li key={key}>
											{document.displayName}&nbsp; <a href={window.endpoint('lms', '/public/document/' + document.documentId + '/get')}><i className={"fa fa-external-link-alt"}></i> </a> &nbsp;
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdown-basic">
													<i className="fa fa-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item onClick={() => this.viewDocument(document, 'quotation', key)}>View</Dropdown.Item>
													<Dropdown.Item onClick={() => this.renameDocument(document, 'quotation', key)}>Rename</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'quotation', key)}>Download</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'quotation', key)} onClick={() => this.deleteDocument(document, 'quotation', key)}>Delete</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</li>
									))}
								</ul>
							</div>
							}
						</>
						}

					</div>
					<div className="field-group">

						{this.props.propertyDetails.showOutstandingBalance &&
						<>
							<MoneyInputField
								label="Outstanding Balance"
								fieldName="outstandingBalanceAmount"
								onChange={this.onChange}
								value={this.state.data.outstandingBalanceAmount}
							/>

							<div className="field-details-summary">
								<strong>Outstanding Balance</strong> Documents (<IntegerText value={this.props.propertyDetails.outstandingBalanceDocuments.length} />) <button className="btn btn-default btn-sm" onClick={() => this.toggleDocuments('outstandingBalanceDocuments')} ><i className="fa fa-ellipsis-h"></i></button> <button className="btn btn-secondary btn-sm" onClick={() => this.toggleUploadDocument('outstandingBalanceDocuments')} ><i className="fa fa-plus"></i></button>
							</div>
							{this.state.showUpload.outstandingBalanceDocuments &&
							<div>
								<input type="file" onChange={(e) => this.uploadDocument(e, 'outstandingBalanceDocuments')} />
							</div>}
							{this.state.showDocuments.outstandingBalanceDocuments &&
							<div>
								<ul>
									{this.props.propertyDetails.outstandingBalanceDocuments.map((document, key) => (
										<li key={key}>
											{document.displayName}&nbsp; <a href={window.endpoint('lms', '/public/document/' + document.documentId + '/get')}><i className={"fa fa-external-link-alt"}></i> </a> &nbsp;
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdown-basic">
													<i className="fa fa-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item onClick={() => this.viewDocument(document, 'outstandingBalance', key)}>View</Dropdown.Item>
													<Dropdown.Item onClick={() => this.renameDocument(document, 'outstandingBalance', key)}>Rename</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'outstandingBalance', key)}>Download</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'outstandingBalance', key)} onClick={() => this.deleteDocument(document, 'outstandingBalance', key)}>Delete</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</li>
									))}
								</ul>
							</div>
							}
						</>
						}

					</div>
					<div className="field-group">

						{this.props.propertyDetails.showBuildingPlan &&
						<>
							<div className="field-details-summary">
								<strong>Building Plan</strong> Documents (<IntegerText value={this.props.propertyDetails.buildingPlanDocuments.length} />) <button className="btn btn-default btn-sm" onClick={() => this.toggleDocuments('buildingPlanDocuments')} ><i className="fa fa-ellipsis-h"></i></button> <button className="btn btn-secondary btn-sm" onClick={() => this.toggleUploadDocument('buildingPlanDocuments')} ><i className="fa fa-plus"></i></button>
							</div>
							{this.state.showUpload.buildingPlanDocuments &&
							<div>
								<input type="file" onChange={(e) => this.uploadDocument(e, 'buildingPlanDocuments')} />
							</div>}
							{this.state.showDocuments.buildingPlanDocuments &&
							<div>
								<ul>
									{this.props.propertyDetails.buildingPlanDocuments.map((document, key) => (
										<li key={key}>
											{document.displayName}&nbsp; <a href={window.endpoint('lms', '/public/document/' + document.documentId + '/get')}><i className={"fa fa-external-link-alt"}></i> </a> &nbsp;
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdown-basic">
													<i className="fa fa-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item onClick={() => this.viewDocument(document, 'buildingPlan', key)}>View</Dropdown.Item>
													<Dropdown.Item onClick={() => this.renameDocument(document, 'buildingPlan', key)}>Rename</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'buildingPlan', key)}>Download</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'buildingPlan', key)} onClick={() => this.deleteDocument(document, 'buildingPlan', key)}>Delete</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</li>
									))}
								</ul>
							</div>
							}
						</>
						}

					</div>
					<div className="field-group">

						{this.props.propertyDetails.showSellersId &&
						<>
							<div className="field-details-summary">
								<strong>Seller's ID</strong> Documents (<IntegerText value={this.props.propertyDetails.sellersIdDocuments.length} />) <button className="btn btn-default btn-sm" onClick={() => this.toggleDocuments('sellersIdDocuments')} ><i className="fa fa-ellipsis-h"></i></button> <button className="btn btn-secondary btn-sm" onClick={() => this.toggleUploadDocument('sellersIdDocuments')} ><i className="fa fa-plus"></i></button>
							</div>
							{this.state.showUpload.sellersIdDocuments &&
							<div>
								<input type="file" onChange={(e) => this.uploadDocument(e, 'sellersIdDocuments')} />
							</div>}
							{this.state.showDocuments.sellersIdDocuments &&
							<div>
								<ul>
									{this.props.propertyDetails.sellersIdDocuments.map((document, key) => (
										<li key={key}>
											{document.displayName}&nbsp; <a href={window.endpoint('lms', '/public/document/' + document.documentId + '/get')}><i className={"fa fa-external-link-alt"}></i> </a> &nbsp;
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdown-basic">
													<i className="fa fa-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item onClick={() => this.viewDocument(document, 'sellersId', key)}>View</Dropdown.Item>
													<Dropdown.Item onClick={() => this.renameDocument(document, 'sellersId', key)}>Rename</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'sellersId', key)}>Download</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'sellersId', key)} onClick={() => this.deleteDocument(document, 'sellersId', key)}>Delete</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</li>
									))}
								</ul>
							</div>
							}
						</>
						}

					</div>
					<div className="field-group">

						{this.props.propertyDetails.showProofOfOwnership &&
						<>
							<div className="field-details-summary">
								<strong>Proof of Ownership</strong> Documents (<IntegerText value={this.props.propertyDetails.proofOfOwnershipDocuments.length} />) <button className="btn btn-default btn-sm" onClick={() => this.toggleDocuments('proofOfOwnershipDocuments')} ><i className="fa fa-ellipsis-h"></i></button> <button className="btn btn-secondary btn-sm" onClick={() => this.toggleUploadDocument('proofOfOwnershipDocuments')} ><i className="fa fa-plus"></i></button>
							</div>
							{this.state.showUpload.proofOfOwnershipDocuments &&
							<div>
								<input type="file" onChange={(e) => this.uploadDocument(e, 'proofOfOwnershipDocuments')} />
							</div>}
							{this.state.showDocuments.proofOfOwnershipDocuments &&
							<div>
								<ul>
									{this.props.propertyDetails.proofOfOwnershipDocuments.map((document, key) => (
										<li key={key}>
											{document.displayName}&nbsp; <a href={window.endpoint('lms', '/public/document/' + document.documentId + '/get')}><i className={"fa fa-external-link-alt"}></i> </a> &nbsp;
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdown-basic">
													<i className="fa fa-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item onClick={() => this.viewDocument(document, 'proofOfOwnership', key)}>View</Dropdown.Item>
													<Dropdown.Item onClick={() => this.renameDocument(document, 'proofOfOwnership', key)}>Rename</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'proofOfOwnership', key)}>Download</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'proofOfOwnership', key)} onClick={() => this.deleteDocument(document, 'proofOfOwnership', key)}>Delete</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</li>
									))}
								</ul>
							</div>
							}
						</>
						}

					</div>
					<div className="field-group">

						{this.props.propertyDetails.showDosAllocationLetterPlot &&
						<>
							<div className="field-details-summary">
								<strong>DoS Allocation Letter Plot</strong> Documents (<IntegerText value={this.props.propertyDetails.dosAllocationLetterPlotDocuments.length} />) <button className="btn btn-default btn-sm" onClick={() => this.toggleDocuments('dosAllocationLetterPlotDocuments')} ><i className="fa fa-ellipsis-h"></i></button> <button className="btn btn-secondary btn-sm" onClick={() => this.toggleUploadDocument('dosAllocationLetterPlotDocuments')} ><i className="fa fa-plus"></i></button>
							</div>
							{this.state.showUpload.dosAllocationLetterPlotDocuments &&
							<div>
								<input type="file" onChange={(e) => this.uploadDocument(e, 'dosAllocationLetterPlotDocuments')} />
							</div>}
							{this.state.showDocuments.dosAllocationLetterPlotDocuments &&
							<div>
								<ul>
									{this.props.propertyDetails.dosAllocationLetterPlotDocuments.map((document, key) => (
										<li key={key}>
											{document.displayName}&nbsp; <a href={window.endpoint('lms', '/public/document/' + document.documentId + '/get')}><i className={"fa fa-external-link-alt"}></i> </a> &nbsp;
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdown-basic">
													<i className="fa fa-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item onClick={() => this.viewDocument(document, 'dosAllocationLetterPlot', key)}>View</Dropdown.Item>
													<Dropdown.Item onClick={() => this.renameDocument(document, 'dosAllocationLetterPlot', key)}>Rename</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'dosAllocationLetterPlot', key)}>Download</Dropdown.Item>
													<Dropdown.Item onClick={() => this.downloadDocument(document, 'dosAllocationLetterPlot', key)} onClick={() => this.deleteDocument(document, 'dosAllocationLetterPlot', key)}>Delete</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</li>
									))}
								</ul>
							</div>
							}
						</>
						}

					</div>

					{this.props.propertyDetails.contractors &&
					<div className="field-group">

						<h3>Contractors</h3>

						<button className="btn btn-success btn-sm" onClick={() => this.addContractor()}><i className="fa fa-plus"></i></button>

						<div className="contractor-list">
							{this.props.propertyDetails.contractors.map((contractor, key) => (
								<div key={key} className="contractor-list-item">
									<div>
										<strong>{contractor.companyName}</strong> / {contractor.contractorName}
										<span>&nbsp;</span>
										<button className={"btn btn-sm btn-default"}
											onClick={() => this.toggleContractorViewMode(key)}>
											<i className={"fa fa-ellipsis-h"}></i>
										</button>
										<span>&nbsp;</span>
										<button className={"btn btn-sm btn-warning"}
												onClick={() => this.deleteContractor(key)}>
											<i className={"fa fa-times"}></i>
										</button>
									</div>
									<hr />
									{contractor.viewMode !== 'collapsed' &&
									<div>
										<div className="field-group">

											<TextInputField
												label="Company Name"
												name={"companyName"}
												onChange={(e) => this.updateContractors(key, e)}
												value={contractor.companyName}
											/>

										</div>

										<div className="field-group">

											<div className="form-group">
												<label className="field-label"><strong>Company Profile</strong></label>
												<span className="text-info">
													&nbsp;({(contractor.companyProfileDocument || {}).displayName || "Nothing" +
												" uploaded" +
													" yet."})
												</span> <>&nbsp;&nbsp;</>
												<input type="file" onChange={(e) => this.uploadSingleDocument(e,
													{
														category: 'contractors',
														key: key,
														field: 'companyProfileDocument'
													})}
													className="btn btn-default btn-sm"
												/>
											</div>

										</div>

										<div className="field-group">

											<TextInputField
												label="Contractor Name"
												name={"contractorName"}
												onChange={(e) => this.updateContractors(key, e)}
												value={contractor.contractorName}
											/>

										</div>

										<div className="field-group">

											<div className="form-group">
												<label className="field-label"><strong>ID Document</strong></label>
												<span className="text-info">
													&nbsp;({(contractor.identificationDocument || {}).displayName || "Nothing" +
												" uploaded" +
													" yet."})
												</span> <>&nbsp;&nbsp;</>
												<input type="file" onChange={(e) => this.uploadSingleDocument(e,
													{
														category: 'contractors',
														key: key,
														field: 'identificationDocument'
													})}
													className="btn btn-default btn-sm"
												/>
											</div>

										</div>

										<div className="field-group">

											<TextInputField
												label="Contact Number"
												name={"contactNumber"}
												onChange={(e) => this.updateContractors(key, e)}
												value={contractor.contactNumber}
											/>

											<TextInputField
												name={"emailAddress"}
												label="Email Address"
												onChange={(e) => this.updateContractors(key, e)}
												value={contractor.emailAddress}
											/>

											<TextInputField
												label="Bank Name"
												name={"bankName"}
												onChange={(e) => this.updateContractors(key, e)}
												value={contractor.bankName}
											/>

											<TextInputField
												label="Branch Code"
												name={"branchCode"}
												onChange={(e) => this.updateContractors(key, e)}
												value={contractor.bankBranchCode}
											/>

											<TextInputField
												label="Account Number"
												name={"accountNumber"}
												onChange={(e) => this.updateContractors(key, e)}
												value={contractor.bankAccountNumber}
											/>

										</div>

										<div className="field-group">

											<div className="form-group">
												<label className="field-label"><strong>Bank Statement</strong></label>
												<span className="text-info">
													&nbsp;({(contractor.bankStatementDocument || {}).displayName || "Nothing" +
												" uploaded" +
												" yet."})
												</span> <>&nbsp;&nbsp;</>
												<input type="file" onChange={(e) => this.uploadSingleDocument(e,
													{
														category: 'contractors',
														key: key,
														field: 'bankStatementDocument'
													})}
													className="btn btn-default btn-sm"
												/>
											</div>

										</div>

									</div>
									}
								</div>

							))}
						</div>

					</div>
					}

				</div>

			</div>
		)
	};

}

PropertyDetails.propTypes = {
  setPropertyDetails: PropTypes.func.isRequired,
  updateCalculations: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
	updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	propertyDetails: state.origination.propertyDetails,
	general: state.origination.general,
});

export default connect(mapStateToProps, { setPropertyDetails, updateCalculations, uploadFiles, updateView})(PropertyDetails);