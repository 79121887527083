import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DocsPage from "./docs/DocsPage";
import GlobalSelectClient from "./includes/GlobalSelectClient";
import { LayoutSplashScreen } from "../../../_metronic";
import {Button, Col, Form, FormControl, Modal, Row} from "react-bootstrap";
import axios from "axios";
import AuthSocket from "../../js/utils/AuthSocket";

const ClientsPage = lazy(() =>
    import("./clients/ClientsPage")
);

const SalesDashboard = lazy(() =>
    import("./clients/SalesDashboard")
);

const RolesPage = lazy(() =>
    import("./admin/RolesPage")
);

const UsersPage = lazy(() =>
    import("./admin/UsersPage")
);

const ClientsEdit = lazy(() =>
    import("./clients/ClientsEdit")
);
const ReactBootstrapPage = lazy(() =>
    import("./react-bootstrap/ReactBootstrapPage")
);
const OriginationPage = lazy(() =>
    import("./origination/OriginationPage")
);
const CreditAssessmentReportsPage = lazy(() =>
    import("./reports/credit-assessment/CreditAssessmentReportsPage")
);
const ValuationQueuePage = lazy(() =>
    import("./valuation/ValuationQueuePage")
);
const ValuationStatusPage = lazy(() =>
    import("./valuation/ValuationStatusPage")
);
const ValuationInvoicesPage = lazy(() =>
    import("./valuation/ValuationInvoicesPage")
);
const ValuationValuersPage = lazy(() =>
    import("./valuation/ValuationValuersPage")
);
const UserPage = lazy(() =>
    import("./users/UserPage")
);
export default function HomePage() {

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
            <Redirect exact from="/" to="/dashboard" />
        }
        {
            <Redirect exact from="/valuation" to="/valuation/queue" />
        }
        <Route exact path="/dashboard" component={Dashboard} />
          <Route path="/dashboard/applications" component={ClientsPage} />
          <Route path="/dashboard/sales" component={SalesDashboard} />
        <Route path="/admin/users" component={UsersPage} />
        <Route path="/admin/roles" component={RolesPage} />
        <Route path="/success" render={(props) => <Dashboard {...props} success={true} /> } />
        <Route path="/clients/!:mode/edit" render={(props) => <ClientsEdit {...props} clientId={localStorage.userClientId} mode={props.match.mode} /> } />
        <Route path="/clients/:clientId/apply-for-loan" render={(props) => <ClientsEdit {...props} clientId={localStorage.userClientId} /> } mode={'loan'} />
        <Route path="/clients/:clientId/edit" render={(props) => <ClientsEdit {...props} clientId={props.match.params.clientId} /> } />
          <Route path="/clients/new" render={(props) => <ClientsPage {...props} newClient={"true"} /> } />
          <Route path="/user/password" render={(props) => <UserPage /> } />
        <Redirect to="/error/error-v1" />
      </Switch>
      <GlobalSelectClient />
      <AuthSocket />
    </Suspense>
  );
}
