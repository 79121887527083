import Calculator from '../../../js/math/Calculator.js';

const initialState = {};

export default function (state = initialState, action) {

	if ( !action.payload ) return state.pageView;

	let s = {...state};

	if ( action.payload.masterPage === 'origination' ) {

		if (action.type === 'UPDATE_CALCULATIONS') {
			return Calculator.update(state).pageView;
		}

		if (action.payload.classification !== 'pageView') return Calculator.init(state).pageView;

		if (action.type === 'SET_PAGE_VIEW') {
			if (action.payload.data.currentView) {
				s.pageView = {...s.pageView, ...action.payload.data};
			}
			if (action.payload.data.statusUpdate) {
				s.pageView.completion[action.payload.data.section] = action.payload.data.statusUpdate;
			}
			if (action.payload.data.setDirtyState) {
				s.pageView = {...s.pageView, isPageDirty: action.payload.data.isPageDirty};
			}
			if (action.payload.data.setLoadingState) {
				s.pageView = {...s.pageView, isLoading: action.payload.data.isLoading};
			}
		}
		if (action.payload.data.resetState) {
			s = Calculator.reset(s);
		}
	}
	else if ( action.payload.masterPage === 'clients' ) {

		if (action.payload.classification !== 'pageView') return state.pageView || {};

		if (action.type === 'SET_PAGE_VIEW') {
			if (action.payload.data.currentView) {
				s.pageView = {...s.pageView, ...action.payload.data};
			}
			if (action.payload.data.setDirtyState) {
				s.pageView = {...s.pageView, isPageDirty: action.payload.data.isPageDirty};
			}
			if (action.payload.data.setLoadingState) {
				s.pageView = {...s.pageView, isLoading: action.payload.data.isLoading};
			}
		}

	}

	return s.pageView;
};
