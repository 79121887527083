const initialState = {
    otherIncomeSources: [],
};

export default function (state = initialState, action) {

    if ( !action.payload ) return state.employment || initialState;
    if ( action.payload.masterPage !== 'clients' ) return state.employment;

    if ( action.payload.classification !== 'employment') return state.employment || initialState;

    let s = {...state};

    if ( action.type === 'SET_EMPLOYMENT' ) {
        s.employment = {...s.employment, ...action.payload.data};
    }

    return s.employment;
};
