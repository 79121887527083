import React, { useMemo, Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Portlet,
    PortletBody,
    PortletHeader,
    PortletHeaderToolbar
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
import OrdersWidget from "../../widgets/OrdersWidget";
import SalesBarChart from "../../widgets/SalesBarChart";
import DownloadFiles from "../../widgets/DownloadFiles";
import NewUsers from "../../widgets/NewUsers";
import LatestUpdates from "../../widgets/LatestUpdates";
import BestSellers from "../../widgets/BestSellers";
import RecentActivities from "../../widgets/RecentActivities";
import PortletHeaderDropdown from "../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import AccessControl from "../../js/utils/AccessControl";
import {Container, Row, Col, FormControl, Button} from "react-bootstrap";
import Translate from "../../js/utils/Translate";
import axios from 'axios';
import {Modal, ModalBody, ModalHeader} from "reactstrap";

export default function Dashboard(props) {
    const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
        state => ({
            brandColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.brand"
            ),
            dangerColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.danger"
            ),
            successColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.success"
            ),
            primaryColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.primary"
            )
        })
    );

    const chartOptions = useMemo(
        () => ({
            chart1: {
                data: [10, 14, 18, 11, 9, 12, 14, 17, 18, 14],
                color: brandColor,
                border: 3
            },

            chart2: {
                data: [11, 12, 18, 13, 11, 12, 15, 13, 19, 15],
                color: dangerColor,
                border: 3
            },

            chart3: {
                data: [12, 12, 18, 11, 15, 12, 13, 16, 11, 18],
                color: successColor,
                border: 3
            },

            chart4: {
                data: [11, 9, 13, 18, 13, 15, 14, 13, 18, 15],
                color: primaryColor,
                border: 3
            }
        }),
        [brandColor, dangerColor, primaryColor, successColor]
    );

    const countries = {jm: 'Jamaica', tt: 'Trinidad', do: 'Dominican Republic'};

    const userCountry = localStorage.getItem('userCountry');
    const userCountryCode = localStorage.getItem('userCountryCode');

    const selectCountry = country => {
        localStorage.setItem('userCountry', countries[country]);
        localStorage.setItem('userCountryCode', country);
    };

    const clearCountry = e => {
        localStorage.removeItem('userCountry');
        localStorage.removeItem('userCountryCode');
    };
    
  


  return (
    <Container>
      <div
        style={{
          margin: "auto",
          width: "100%",
          minWidth: "500px",
          maxWidth: "1000px",
        }}
      >
        {props.success && (
          <Fragment>
            <div className="alert alert-success">
              Application successfully submitted!
            </div>
          </Fragment>
        )}
        <br />
        <Row>
          <Col md={12}>
            <ul className={"lead-tiles"}>
              <AccessControl required={'viewSalesDashboard'} fallback={<></>}>
                  <li className={"sales-menu"}>
                    <a href={"/dashboard/sales"}>
                      <i className={"fas fa-briefcase tile-icon"}></i>
                      <br />
                      <br />
                      <Translate source={'Sales Dashboard'} />
                    </a>
                  </li>
              </AccessControl>
                <AccessControl required={'viewAdminDashboard'} fallback={<></>}>
                    <li className={"account-menu"}>
                        <a href={"/dashboard/applications"}>
                            <i className={"fas fa-scroll tile-icon"}></i>
                            <br />
                            <br />
                            <Translate source={'Admin Dashboard'} />
                        </a>
                    </li>
                </AccessControl>
              <AccessControl required={'createApplication'} fallback={
                  <li className={"disabled-menu"}>
                      <a>
                          <i className={"fas fa-lock tile-icon"}></i>
                          <br />
                          <br />
                          <Translate source={'Start Application'} />
                      </a>
                  </li>
              }>
                  <li className={"loan-menu"}>
                    <a href={"/clients/!apply-for-loan/edit"}>
                      <i className={"fas fa-plus tile-icon"}></i>
                      <br />
                      <br />
                      <Translate source={'Start Application'} />
                    </a>
                  </li>
              </AccessControl>
                <AccessControl required={'createUser,updateUser,viewUsers,createRole,updateRole,viewRoles'} match={'any'} fallback={<></>}>
                    <li className={"user-menu"}>
                        <a href={"/admin/users"}>
                            <i className={"fas fa-users tile-icon"}></i>
                            <br />
                            <br />
                            <Translate source={'Users & Permissions'} />
                        </a>
                    </li>
                </AccessControl>
            </ul>
          </Col>
        </Row>       
        
      </div>
    </Container>
  );
}

const Applications = () => {
  const [query, setQuery] = useState({keywords: '', totalRecords: 0})
  const [sorting, setSorting] = useState({field: 'createdAt', order: -1})
  const [clients, setClients] = useState({records: [], columns: []})
  const [showDetailWindow, setShowDetailWindow] = useState([])
  const [statuses, setStatuses] = useState([])
  const [status, setStatus] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect( () => { 
    axios.post(window.endpoint('demo', '/get-clients'), {query: query, sorting: sorting})
    .then(result => {
      if (typeof result.data !== 'undefined') {
        setClients(result.data.clients)
        setQuery(result.data.query)
      }
    }).catch(err => console.error("Failed to get clients: ",err));  
    
    axios.get(window.endpoint('demo', '/get-statuses'))
    .then(result => {
      if (typeof result.data !== 'undefined') {
        setStatuses(result.data)
      }
    }).catch(err => console.error("Failed to get statuses: ",err));

  }, [])

  const setDetailWindow = id => {
    let isOpen = showDetailWindow.slice()
    isOpen[id] = true
    setShowDetailWindow(isOpen)
  }  

  const getListItem = (client, id) => (
    <>
      <strong>
        <a href={"#"} onClick={() => setDetailWindow(id)}>#{client.applicationNumber}</a> - {client.personal.firstName} {client.personal.lastName}
      </strong>

      <Modal
        size={"md"}
        isOpen={showDetailWindow[id] || false}
        toggle={() => setShowDetailWindow([false])}
      >
          <ModalBody>
            <Row>
              <Col md={6}>
                <h3 className={"m-t-6"}>Application #{client.applicationNumber}</h3>
              </Col>
            </Row>
            <div className="form-group">
              <Row>
                <Col md={7}>
                  <label className={"strong-label"}><Translate source={"Application Status"} /></label>
                  <FormControl
                    as="select"
                    name="status"
                    onChange={onChange}
                    value={status}
                    required={true}
                  >
                     {statuses && statuses.map((status, key) => (
                        <option key={key} value={status}>{status}</option>
                    ))}
                  </FormControl>
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Row>
                <Col md={12}>
                  <div className={""}>
                    <Button
                          variant={"success"}
                          className=""
                          onClick={() => submitChange(id)}
                    ><i className={'fa fa-check'} />&nbsp;Save</Button>
                  </div>
                </Col>
              </Row>
            </div>
            
          </ModalBody>
      </Modal>
    </>
  )

  const onChange = (e) => {
    let value = e.target.value;
    let newValues = {[e.target.name]: value};
    setStatus(value)
    // this.setState({...this.state, data: {...this.state.data, ...newValues}});
  };

  const submitChange = async (id) => {//TODO: refactor
    if (!status) {
      setErrorMsg("Please select an application status")
    } else {
      await axios.post(window.endpoint("demo", "/save-application-status"), {status: status, id: id})
      .then(result => {
          if (typeof result.data !== "undefined") {
              if (result.data) {
                  this.setState({...this.state, cleanRecord: {...this.state.record}, successMsg: "Application updated successfully."}, () => {
                      setTimeout(() => { this.setState({...this.state, successMsg: ""}) }, 2000)
                  });
                  return true
              } else {
                  this.setState({errMsg: "We couldn't save your changes. Please try again or contact technical support."}, () => {
                      setTimeout(() => { this.setState({...this.state, errMsg: ""}) }, 2000)
                  });
              }

              return false
          }

      }).catch(err => console.error("Failed to save status: ",err))
    }
    
  }

  return (
    <>
      <ul className="list-group list-group-flush" style={{
        width: "300px", 
        height: "350px", 
        overflow: "hidden", 
        overflowY: "scroll"}}
      >
        {clients.records.map((client, r) => (
            <li key={r} className="list-group-item">
              {getListItem(client,r)}            
            </li>              
          ))
        }
      </ul>
    </>
  )
}
