import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setTotalCost, updateCalculations, updateView } from '../../../js/actions';
import { CurrencyField, MoneyInputField, MoneyInfoField, CurrencyText } from '../../../js/ui/FieldTypes';
import A from '../../../js/math/Arithmetic';

class TotalCost extends Component {
  
  constructor(props) {
    super(props);
    let fields = [
    	'valuationFees',
    	'transferFees',
		'bondRegistrationFees',
		'insuranceFees',
		'bondCancellationFees',
		'provisionalFees',
		'avrilFees',
		'avrilServiceFees'

    ], state = { data: {}};

    for(let f = 0; f < fields.length; f++) {
    	state.data[fields[f]] = this.props.totalCost[fields[f]] || '';
    }

    this.state = state;
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.updateCalculations({data: {masterPage: 'origination'}});
  }

  componentWillReceiveProps(nextProps) {
  }

  onChange(e) {
  	let value = A.f(e.target.value);
    let newValues = {[e.target.name]: value};
    this.setState({...this.state, data: {...this.state.data, ...newValues}});
    this.props.setTotalCost({data: { [e.target.name]: value }, classification: 'totalCost', masterPage: 'origination'});
	  this.props.updateView({data: {setDirtyState: true, isPageDirty: true}, classification: 'pageView', masterPage: 'origination'});
  }

  render() {
    return (
	    <div>

			<div id="totalCost">

				<div className="field-group">

					<MoneyInfoField
						label="Pre-calculated Loan Amount:"
						value={this.props.totalCost.precalculatedLoanAmount}
						className="strong-summary"
					/>

				</div>

				<div className="field-group">

					{/*<MoneyInfoField
						label="Valuation Fees:"
						value={this.props.totalCost.valuationFees}
					/>*/}

					{/*<MoneyInfoField
						label="Valuation Cost:"
						value={this.props.totalCost.valuationTravellingFees}
					/>*/}

					{/*<MoneyInfoField
						label="Valuation Services Fees:"
						value={this.props.totalCost.valuationTravellingServiceFees}
					/>*/}

					<MoneyInfoField
						label="Valuation Cost:"
						value={this.props.totalCost.valuationTotalCost}
					/>

					<MoneyInputField
						label="Transfer Fees"
						fieldName="transferFees"
						onChange={this.onChange}
						value={this.state.data.transferFees}
					/>

					<MoneyInputField
						label="Bond Registration Fees"
						fieldName="bondRegistrationFees"
						onChange={this.onChange}
						value={this.state.data.bondRegistrationFees}
					/>

					<MoneyInputField
						label="Insurance Fees"
						fieldName="insuranceFees"
						onChange={this.onChange}
						value={this.state.data.insuranceFees}
					/>

					<MoneyInfoField
						label="Bond Cancellation Fees:"
						value={this.props.totalCost.bondCancellationFees}
					/>

					<MoneyInfoField
						label="Provisional Fees:"
						value={this.props.totalCost.provisionalFees}
					/>

					<MoneyInfoField
						label="Avril Fees:"
						value={this.props.totalCost.avrilFees}
					/>

					<MoneyInputField
						label="Service Fees (Avril)"
						fieldName="avrilServiceFees"
						onChange={this.onChange}
						value={this.state.data.avrilServiceFees}
					/>

				</div>

				<div className="field-group">

					<MoneyInfoField
						label="Total Debt Consolidation Fees:"
						value={this.props.totalCost.totalDebtConsolidationFees}
					/>

					<MoneyInfoField
						label="Closing Fees:"
						value={this.props.totalCost.closingFees}
					/>

				</div>

				<div className="field-group">

					<MoneyInfoField
						label="Total Costs:"
						value={this.props.totalCost.totalCosts}
						className="strong-summary"
					/>

					{/*<MoneyInfoField
						label="Sub-total Loan:"
						value={this.props.totalCost.subTotalLoan}
					/>*/}

					<MoneyInfoField
						label="Non-housing Related Fees:"
						value={this.props.totalCost.nonHousingFees}
					/>

					<MoneyInfoField
						label="Housing Related Fees:"
						value={this.props.totalCost.housingFees}
					/>

				</div>

				<div className="field-group">

					<MoneyInfoField
						label="Total Loan:"
						value={this.props.totalCost.totalLoan}
						className="strong-summary"
					/>

				</div>

			</div>

		</div>
	)
  }
}

TotalCost.propTypes = {
  setTotalCost: PropTypes.func.isRequired,
  updateCalculations: PropTypes.func.isRequired,
	updateView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	totalCost: state.origination.totalCost,
	surplus: state.origination.surplus,
});

export default connect(mapStateToProps, { setTotalCost, updateCalculations, updateView })(TotalCost);