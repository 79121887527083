import Calculator from '../../../js/math/Calculator.js';
import axios from 'axios';

require('../../../js/utils/Messenger');

const initialState = {};

export default function (state = initialState, action) {

	if ( !action.payload ) return Calculator.init(state);
	if ( action.payload.masterPage !== 'origination' && action.payload.masterPage !== 'clients' ) return state;

	if ( action.type === 'UPDATE_CALCULATIONS' ) {
		return Calculator.update(state);
	}

	if ( action.payload.classification !== 'uploadFiles') return Calculator.init(state);

	let s = {...state};
	s = Calculator.init(s);

	if ( action.type === 'UPLOAD_FILES' ) {

		let file = action.payload.data.files[0];
		let documentInfo = action.payload.data.documentInfo;
		const formData = new FormData();
		formData.append('file', file);
		formData.append('payload', JSON.stringify(documentInfo));

		let userId = localStorage.getItem('userId');
		let accessToken = localStorage.getItem('accessToken');
		let sessionToken = localStorage.getItem('sessionToken');
		let timezoneOffset = new Date().getTimezoneOffset();
		let headers = {userId, accessToken, timezoneOffset, sessionToken};

		/*Todo: filter file browser on common doc types*/

		axios.post(window.endpoint('demo', "/upload-document"), formData, {headers})
			.then(result => {
				if(typeof result.data !== 'undefined') {
					let documentId = result.data._id;
					let newFile = {
						name:  file.name,
						displayName: file.name,
						documentId: documentId,
						link: result.data.link
					};

					if (action.payload.type === 'list') {
						let section = action.payload.section;
						let category = action.payload.category;
						s[section][category].push(newFile);
					} else if (action.payload.type === 'insert') {
						let section = action.payload.section;
						let args = action.payload.args;
						s[section][args.category][args.key][args.field] = newFile;
					}

					window.Messenger.broadcast('AsyncFileUploadResponse', {documentId});

					s = Calculator.update(s);
				}
			});
	}

	if (action.payload.data.resetState) {
		s = Calculator.reset(s);
	}

	return s.files || {};
};
